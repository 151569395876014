import React from 'react';
import Typography from "@material-ui/core/Typography";
import Navigation from '../../components/ConnectedNavigation';
import Grid from "@material-ui/core/Grid";
import ReportItem from "./ReportItem";

export class ReportsContent extends React.Component {
    state = {
        isOpen: false,
        dialogProps: {}
    };

    componentDidMount() {
        const {loadReports, token} = this.props;
            loadReports({token})
    }

    render() {
        const {reports} = this.props;

        return (
            <Navigation title={'Account'}>
                <Typography variant={"h4"} gutterBottom>
                    Reports
                </Typography>
                <Grid container
                      direction="column"
                      justify="flex-start"
                      alignItems="stretch"
                      spacing={4}
                >
                    <Grid item>
                            {!!reports && reports.map((report, i) => (<ReportItem key={i} {...report} />))}
                    </Grid>
                </Grid>
            </Navigation>
        )
    }
}

export default ReportsContent;