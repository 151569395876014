import {getExists} from "../../organization";
import {getIsConfirmed, getIsSysAdmin, getAuth} from "../../authorize";
import {createSelector} from "reselect";

export const mapNavigationToProps = createSelector(
    getAuth,
    (isAuthenticated) => ({
        isAuthenticated
    })
);

export const mapListItemsToProps = createSelector(
    getExists,
    getIsConfirmed,
    getIsSysAdmin,
    (orgExists, isConfirmed, isSysAdmin) => ({
        orgExists,
        isConfirmed,
        isSysAdmin
    })
);