import React from 'react';
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core";
import Navigation from '../../components/ConnectedNavigation';
import CredentialsListContainer from '../containers/CredentialsListContainer';
import AlertDialog from '../../components/AlertDialog';


const styles = (theme) => ({
    grid: {
        flexGrow: 1,
        alignItems: 'center'
    },
    gridItem: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    nonConfirmed: {
        color: theme.palette.error.dark
    }
});

class CredentialsContent extends React.Component {
    state = {
        isOpen: false,
        selectedCredential: {}
    };

    onOpenDialog = (event, credential) => {
        this.setState({isOpen: !this.state.isOpen, selectedCredential: credential})
    };

    onHandleClose = (event, credentialId) => {
        this.setState({isOpen: !this.state.isOpen, selectedCredential: {}});

        if(!!credentialId) {
            const {token, deleteCredential} = this.props;
            return deleteCredential({token, credentialId})
        }
    };

    getDialogProps = ({memberName, title, _id = ''}) => ({
        title: `Delete ${memberName}'s '${title}' Credential`,
        contentText: `This means ${memberName}'s ${title} Credential and the associated image will be removed and no longer available.`,
        _id
    });

    render() {

        const{isOpen, selectedCredential} = this.state;

        const dialogProps = this.getDialogProps(selectedCredential);

        return (
            <Navigation title={'Credentials'}>
                <Typography variant={"h4"} gutterBottom>
                    Credentials
                </Typography>
                <CredentialsListContainer openDialog={this.onOpenDialog}/>
                <AlertDialog isOpen={isOpen} {...dialogProps} handleClose={this.onHandleClose}/>
            </Navigation>

        )
    }
}

export default withStyles(styles)(CredentialsContent)