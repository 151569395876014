import React from 'react';
import { reduxForm, Field, Form } from '../../utils/redux-form';
import Button from '../../components/Button';
import * as formValidators from '../../utils/formValidation';
import { RESET_PASSWORD_FORM } from '../constants';
import ValidatorPassword from "./ValidatorPassword";
import Link from "../../components/RouterLink";

export const ResetPasswordForm = props => {
    const {handleSubmit, isDisabled, isFormVisible} = props;
    if (isFormVisible) {
        return (
            <Form onSubmit={handleSubmit}>
                <Field
                    name="token"
                    component="input"
                    type="hidden"
                />
                <Field
                    name="password"
                    component={ValidatorPassword}
                    label="New Password"
                />
                <Button
                    color='primary'
                    disabled={isDisabled}
                    type="submit">
                    Confirm Password Change
                </Button>
            </Form>
        )
    }
    else {
        return (<div>is done... <Link to={`/`}>Go Home</Link>.</div>)
    }
};

export default reduxForm({
    form: RESET_PASSWORD_FORM ,
    enableReinitialize: true,
    validate: formValidators.createValidator({
        password: [ formValidators.required, formValidators.minLengthAndStrength(8, 3)],
        confirm: [formValidators.required]
    })
})(ResetPasswordForm);