import SignIn  from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import Confirm from './ConfirmEmail';
import VerifierConfirmation from "./VerifierConfirmation";

const components = {
    SignIn,
    SignUp,
    ForgotPassword,
    ResetPassword,
    Confirm,
    VerifierConfirmation
}

export default components;