import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        width: '90%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
});

export const StaticControl = (props) => {

    const { classes, input, label} = props;

    return (
        <TextField
            multiline
            className={classes.formControl}
            disabled
            {...input}
            label={label}
        />
    );
};

StaticControl.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StaticControl);
