import React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core";
import Navigation from '../../components/ConnectedNavigation';
import Button from '@material-ui/core/Button';
import {Link as RouterLink} from 'react-router-dom'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import {MemberListIcon, CredentialListIcon, CodeListIcon} from '../../common/icons';
import {ViewImage, IMAGE_TYPE} from '../../components/ImageField';

const styles = (theme) => ({
    root: {
        minWidth: 250,
        [theme.breakpoints.up('sm')]: {
            minWidth: 400,
        }
    },
    image: {
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    button: {
        margin: theme.spacing(1),
    },
    chip: {
        margin: theme.spacing(1),
    },
});

export const DashboardContent = (props) => {
    const {classes, isNew, isTrialing, available, activated, trialEnd, name, logoImage, credentials} = props;
    return (
        <Navigation title={'Dashboard'}>
            <Typography variant={"h4"} gutterBottom>
                {!isNew ? name : 'Organization Name'}
                {isTrialing && <Chip variant="outlined" color="secondary"
                                     label={`Free Trial Ends on ${trialEnd}`} className={classes.chip}/>}
            </Typography>
            <Grid container
                  direction="column"
                  justify="flex-start"
                  alignItems="center"
                  className={classes.root}
                  spacing={4}
            >
                <Grid item className={classes.image}>
                    <ViewImage value={logoImage} name={IMAGE_TYPE.LOGO}/>
                </Grid>
                <Grid item container
                      direction="row"
                      justify="center"
                      alignItems="stretch"
                >
                    <Grid item>
                        <Button variant="outlined" color={!isNew ? undefined : "secondary"} to={'/app/profile'}
                                component={RouterLink}
                                className={classes.button}>
                            Profile
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" color={!isNew ? undefined : "secondary"} to={'/app/organization'}
                                component={RouterLink}
                                className={classes.button}>
                            Organization
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" disabled={isNew} to={'/app/account'} component={RouterLink}
                                className={classes.button}>
                            Account
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    <List component="nav">
                        <ListItem button component={RouterLink} to={'/app/members'} disabled={isNew}>
                            <ListItemIcon>
                                <MemberListIcon/>
                            </ListItemIcon>
                            <ListItemText primary={`Members Available (${available.members-activated.members})`}
                                          secondary={`Activated (${activated.members})`}/>
                        </ListItem>
                        <ListItem button component={RouterLink} to={'/app/credentials'} disabled={isNew}>
                            <ListItemIcon>
                                <CredentialListIcon/>
                            </ListItemIcon>
                            <ListItemText primary={`Credentials Added (${credentials.added})`}
                                          secondary={`Expired (${credentials.expired})`}/>
                        </ListItem>
                        <ListItem button component={RouterLink} to={'/app/qrCodes'} disabled={isNew}>
                            <ListItemIcon>
                                <CodeListIcon />
                            </ListItemIcon>
                            <ListItemText primary={`Dynamic QR Codes Available (${available.qrCodes-activated.qrCodes})`}
                                          secondary={`Activated (${activated.qrCodes})`}/>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Navigation>
    )
};

export default withStyles(styles)(DashboardContent)