export const IMAGE_TYPE = {
    PROFILE: 'profileImage',
    CREDENTIAL: 'credentialImage',
    LOGO: 'logoImage'

};

export const defaultImage = {
    [IMAGE_TYPE.CREDENTIAL]: '',
    [IMAGE_TYPE.LOGO]: 'your_logo_here',
    [IMAGE_TYPE.PROFILE]: 'profile_image_placeholder'
};