import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import UserItem from "./UserItem";
import AlertDialog from "../../components/AlertDialog";
import {Redirect} from "react-router-dom";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
    },
});

const getSysAdminState = ({roles}) => !!roles ? roles.includes('sysadmin') : false;


class ConfirmedUserList extends React.Component {
    state = {
        isOpen: false,
        selectedUser: {},
        selectedId: ''
    };

    componentDidMount() {
        const {loadUsers, token, exists} = this.props;
        if(!exists) {
            loadUsers({token})
        }
    }

    getDialogProps = (props) => {
        const {firstName, lastName, _id} = props;
        const isSysAdmin = getSysAdminState(props);
        const title = isSysAdmin ? `Remove System Administrator permissions` : `Make '${firstName} ${lastName}' a System Administrator`;
        const text = isSysAdmin ? `will no longer have access to` : `will be able to access`;
        return {
            title,
            contentText: `'${firstName} ${lastName}' ${text} System Administration functionality.`,
            _id: !!_id ? _id : ''
        }
    };

    handleSysadminClick = (e, selectedUser) => {
        this.setState({isOpen: !this.state.isOpen, selectedUser})
    };

    handleSelectedUser = (e, selectedId) => {

        this.props.selectUser(e, {_id: selectedId});

        this.setState({selectedId})
    };

    handleClose = async (e, id) => {
        if(!!id) {
            const {selectedUser} = this.state;
            const {roles, _id} = selectedUser;
            const isSysAdmin = getSysAdminState(selectedUser);

            const newRoles = isSysAdmin ? roles.filter((r)=> r !== 'sysadmin') : [...roles, 'sysadmin'];

            const {token, patchUser} = this.props;

            await patchUser({token, _id, roles: newRoles})

        }
        this.setState({isOpen: !this.state.isOpen, selectedUser: {}})
    };

    render() {
        const {classes, users} = this.props;

        const {isOpen, selectedUser, selectedId} = this.state;
        const dialogProps = this.getDialogProps(selectedUser);

        if(!!selectedId){
            return <Redirect to={`/app/userAdmin/${selectedId}`} />
        }


        return (
            <div className={classes.root}>
                <div>
                    <List dense>
                        {!!users && users.map((u, i) => <UserItem key={i} {...u} handleIconClick={this.handleSysadminClick} handleSelected={this.handleSelectedUser}/>)}
                    </List>
                </div>
                <AlertDialog {...{isOpen}} {...dialogProps} handleClose={this.handleClose}/>
            </div>
        );
    }
}

ConfirmedUserList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConfirmedUserList);
