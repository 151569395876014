import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    primary: {
        fill: theme.palette.primary.main
    },
    secondary: {
        fill: theme.palette.secondary.main
    },
    green: {
        fill: '#2ecb3e;'
    }
});

const LogoSvg = (props) => {
    const {classes} = props;
    return (
        <SvgIcon className={props.className} viewBox="0 0 355 300">
            <g transform="translate(-5.7738 -13.7692)" className={classes.secondary} stroke="none">
                <path d="M136.3552,299.8878V271.534c-6.0787,1.8038-11.1523-.4118-15.9662-3.6745-33.9157-22.9873-60.7085-52.4159-79.7847-88.7156a311.2307,311.2307,0,0,1-28.686-78.0961A274.5808,274.5808,0,0,1,6.9357,69.9661C6.5536,66.24,6.2093,62.51,5.8505,58.7812a12.1682,12.1682,0,0,1,4.5939-11.2894,82.7149,82.7149,0,0,1,11.71-7.4647A293.0955,293.0955,0,0,1,73.588,17.1661c1.4252-.472,2.8982-.8021,4.3553-1.1734a2.8381,2.8381,0,0,1,1.8435.0516C73.0686,21.5558,70.3586,28.87,69.7077,37.188a4.8094,4.8094,0,0,1-2.2,1.137A67.5038,67.5038,0,0,0,41.8579,54.29c-1.6815,1.65-3.439,3.2274-5.2149,4.777-3.863,3.3706-4.9982,7.5442-4.1538,12.5344,1.5581,9.2081,2.8971,18.453,4.4,27.6707,2.585,15.8561,5.7715,31.571,10.9567,46.825A161.8062,161.8062,0,0,0,72.0212,191.23a253.9355,253.9355,0,0,0,32.6061,36.3756,101.9452,101.9452,0,0,0,23.1793,16.2338c2.6389,1.3016,5.277,1.9891,8.079.5389.1343-.07.2635-.1488.47-.2663V200.1073H182.4a217.3135,217.3135,0,0,0,14.0036-20.7038h1.2175q10.1471,0,20.2941.0078a6.6254,6.6254,0,0,0,2.2854-.276,3.8661,3.8661,0,0,1,2.8023.3006c-3.81,7.003-7.8323,13.7886-12.2,20.5957H239.12v99.8562Zm99.2042-2.4272V202.597H140.6648v94.8636Z" className={classes.primary}/>
                <path d="M124.0044,206.782c.8478,8.8632.6132,15.802-.6629,19.4184a11.3376,11.3376,0,0,1-6.8743.5274,34.245,34.245,0,0,1-15.1066-6.6561c-5.5611-4.4661-8.5659-10.426-9.9716-17.2918a46.8829,46.8829,0,0,1-.9319-10.3649,9.7758,9.7758,0,0,0-2.6245-7.0565c-5.6028-6.255-8.8737-13.7667-11.2887-21.7067a11.933,11.933,0,0,1-.5051-3.5262q.0042-54.2688-.0259-108.5377c-.0014-3.7079-.225-7.4117.0084-11.1242.7716-12.2709,6.9229-20.7226,18.0512-25.7163a10.2572,10.2572,0,0,1,4.5721-.975c3.3993.0857,6.8023.0327,10.2038.0191a3.8266,3.8266,0,0,1,1.5714.3c12.3039,5.2988,19.3529,14.5116,20.909,27.8889.6238,5.3629.3383,10.7309.3234,16.0976-.0251,9.0827-.1384,18.1651-.1744,27.2477q-.1377,34.73.1184,69.4584c.0323,4.4049.2368,8.81-.0887,13.2111-.4557,6.163-1.802,12.0589-5.271,17.3019a28.8879,28.8879,0,0,1-11.8743,10.07,2.6712,2.6712,0,0,0-1.6755,2.62c-.0943,4.078,1.487,6.3381,5.3888,7.59A49.16,49.16,0,0,0,124.0044,206.782ZM97.4157,103.12h-.1374c0,4.0179.027,8.036-.005,12.0536-.0975,12.21-.25,24.419-.3126,36.6288-.0234,4.518-.3233,9.0394-.0075,13.5543a9.9,9.9,0,0,0,3.6237,7.3693,5.0566,5.0566,0,0,0,8.2158-2.6142,16.9166,16.9166,0,0,0,.4787-4.4774c.2786-20.9508-.1252-41.9016.3-62.8571.4006-19.7376.0713-39.49.05-59.236a15.2912,15.2912,0,0,0-.592-4.9231c-.917-2.7277-2.82-3.9014-6.0411-3.7158-2.4325.14-4.0716,1.6544-4.6438,4.2581a25.2189,25.2189,0,0,0-.3578,5.1922Q97.68,73.7363,97.4157,103.12Z" className={classes.primary}/>
                <path d="M136.9451,16.3919c6.6043-.5319,13.1043-1.1213,19.6248-1.4524,5.9489-.302,11.8907-.2124,17.8441-.0241,5.7322.1812,9.397,2.9487,11.486,8.1246a31.6725,31.6725,0,0,1,2.1819,11.0784c.2269,8.267.3465,16.5369.5074,24.8057.0623,3.2071.3373,6.4283.1348,9.6189-.6,9.451-.2217,18.9084-.4445,28.3584-.06,2.5675-1.1943,5.01-1.9543,7.4768a100.8948,100.8948,0,0,1-3.5763,9.6049,25.13,25.13,0,0,1,4.7815,12.1116,60.1027,60.1027,0,0,1,.5687,8.3179c.0093,9.856-.0207,19.7121.0142,29.568a113.5659,113.5659,0,0,0,.8324,14.2069,32.7156,32.7156,0,0,1,.0262,4.1125,43.3332,43.3332,0,0,1-19.3685,7.5274,7.725,7.725,0,0,1-1.4657-4.6306c-.1614-8.1913-.3037-16.3829-.4861-24.5736-.1178-5.293-.2812-10.5851-.4513-15.8767a11.7482,11.7482,0,0,0-.6261-3.6379,3.4543,3.4543,0,0,0-3.3878-2.5047,12.8867,12.8867,0,0,0-4.4792.7972,129.312,129.312,0,0,0-.66,17.43c.2508,10.1573.99,20.3056.5856,30.4751-.063,1.5805-.1964,3.1581-.3034,4.8338a42.3091,42.3091,0,0,1-21.3847,1.8646Zm23.1671,90.4535a7.4834,7.4834,0,0,0,7.3422-4.98,13.9564,13.9564,0,0,0,.7311-4.898q-.0192-20.6365-.0212-41.2733a91.028,91.028,0,0,0-.4788-10.4136c-.3536-3.063-1.721-4.8353-4.1009-5.199a10.543,10.543,0,0,0-4.409.2158,14.6919,14.6919,0,0,0-.5315,2.8246,175.3766,175.3766,0,0,0-.8286,18.0522c-.1555,12.8309.1016,25.655,1.104,38.4532A27.8689,27.8689,0,0,0,160.1122,106.8454Z" className={classes.primary}/>
                <path d="M223.1808,173.3039V73.4387h1.1822q9.969,0,19.9381-.004a17.331,17.331,0,0,1,3.46.2754c4.3279.8739,6.7689,3.8111,7.3911,8.7685a60.5012,60.5012,0,0,1,.39,7.6324q-.0478,34.137-.12,68.2741a38.7831,38.7831,0,0,1-.4813,6.46c-.8874,5.2157-3.6107,8.1659-8.9071,8.38C238.4442,173.5327,230.8337,173.3039,223.1808,173.3039Zm12.5645-86.8915v74.1694c.7886,0,1.4827.0007,2.1768,0,2.974-.0031,4.6735-1.5859,4.8793-4.5494.0214-.308.02-.618.02-.9271q.0014-31.65.0053-63.3a8.6344,8.6344,0,0,0-.2741-2.2934,4.0738,4.0738,0,0,0-3.641-3.0888C237.8766,86.366,236.8358,86.4124,235.7453,86.4124Z"/>
                <path d="M325.62,173.2926H312.9089v-1.3c0-7.9612-.1569-15.9265.05-23.8824a55.7087,55.7087,0,0,0-2.9537-18.4166c-3.7987-11.9836-9.3314-23.1735-15.6473-34.0009-.1937-.3321-.3832-.6667-.6392-1.1125a27.6375,27.6375,0,0,1,5.9322-3.2973,2.0724,2.0724,0,0,1,1.5522.3246c3.8342,1.71,6.5968,4.6508,9.04,7.938a75.8279,75.8279,0,0,1,7.7555,13.939c.16.35.3422.69.5267,1.0283.03.0547.1263.0731.2946.1632,7.4629-25.2126,19.3909-48.2286,34.0277-69.9375h7.06c.1669.6032-.284,1.0106-.5169,1.4606a408.4147,408.4147,0,0,0-21.808,49.1639,239.64,239.64,0,0,0-9.971,34.8959c-.7541,3.8268-1.26,7.6875-1.7612,11.5534a42.3076,42.3076,0,0,0-.2239,5.41q-.0219,12.3476-.0064,24.6953Z" className={classes.green}/>
                <path d="M206.9425,160.8591H219.258v12.46H194.4148V73.515h24.8358V85.922H206.9758v31.29h12.2916v12.47H206.9425Z" />
                <path d="M257.1112,67.8173a8.6112,8.6112,0,0,0-2.614,2.1274,21.3276,21.3276,0,0,0-10.7258-2.5578c-3.4314.0165-6.8628.0035-10.4065.0035a19.4464,19.4464,0,0,0-.3365-3.9576,11.2684,11.2684,0,0,0-5.3631-7.3741c-3.256-2-6.9121-3.0339-10.495-4.2115-5.639-1.8534-11.32-3.578-16.9756-5.3819a17.96,17.96,0,0,1-3.3955-1.4611,2.1727,2.1727,0,0,1-1.2184-2.1593c.1194-4.4779.2786-8.9568-.013-13.4365-.231-3.5506-.48-7.0868-2.4985-10.268a3.5716,3.5716,0,0,1,2.51.1487,115.3292,115.3292,0,0,1,18.2737,6.5348,266.6587,266.6587,0,0,1,31.66,16.32,25.7044,25.7044,0,0,1,8.9734,8.2191c2.866,4.5782,3.2246,9.65,2.8581,14.8542C257.2843,66.0621,257.1936,66.9051,257.1112,67.8173Z" className={classes.primary}/>
                <path d="M275.6146,73.4954h24.829V85.9092H288.1378V117.17h8.4289v12.4873h-8.46v43.6384H275.6146Z"/>
                <path d="M259.3785,73.462h12.36v99.8349h-12.36Z" />
                <path d="M217.0234,154.7762h-4.15c.39-3.9755-.7291-7.9914,1.1792-11.8257.9725-1.954,1.573-4.0922,2.3628-6.139.126-.3266.1275-.75.6076-1.02Z" className={classes.primary}/>
                <path d="M235.5594,297.4606H140.6648V202.597h94.8946Zm-92.4008-2.4051h90.0777V204.9806H143.1586Z"/>
                <path d="M224.5113,263.1439h2.7147a24.4912,24.4912,0,0,1,.1545,2.7766h2.8085v5.83h-5.6955v2.8755h-5.8322c.0565,2.0662-.0964,4.0077.1047,5.9522a13.8,13.8,0,0,0-2.9648-.0587v2.7371h2.7852l.155-2.7049,2.7416-.1619v-2.7835h5.8291v2.836h2.89V283.29h-2.8249v5.7993c-1.0207,0-1.9283,0-2.8366.0009-.0572-.9046-.1136-1.81-.1725-2.7555h-2.7536v2.7288h2.9527l-.1634,2.9311h-11.51v-5.627H207.153v2.7164l.0272-.0258-2.9838.1387v2.77a10.1758,10.1758,0,0,1-2.8654.0294v-2.8586h2.7842V283.3l-.0206.0211h8.6984V280.52h-8.698l.02.02v-2.9993h-2.8662V274.72H195.514l.0234.0241q-.0125-1.5224-.0248-3.0446h2.78v-2.78a13.114,13.114,0,0,1,3.0306.0694v2.7448a13.8242,13.8242,0,0,0,2.7457-.0948v-5.59c-.95-.223-1.8858.0845-2.7265-.185v-2.7117h5.77v2.7333h5.66v-5.6484H201.3688c0,1.0266,0,1.9845-.0007,2.9417-.9859-.0473-1.971-.0938-3.0644-.1454v-2.8007H195.54v5.6953h-8.7385v-2.8623h2.895v-2.8215c1-.2372,1.9378.0732,2.8234-.2107v-2.7094l-.0237.0238h3.0413v-3.0427l-.0233.0241,2.8025-.0267v2.9233h2.9984v-2.9222a50.19,50.19,0,0,0,5.85-.03,13.6419,13.6419,0,0,0-.02,2.9235h5.6562v-2.8981h-5.6622c-.0433-.9074-.0866-1.8147-.1347-2.8239h-2.8893V248.51l-.0215.02,5.7824-.0438-.0253-.0275c.0482.9516.0965,1.9032.1476,2.9109a13.8205,13.8205,0,0,0,2.722.1382c.3125-.9109-.0111-1.8076.2279-2.7065h2.69c.2714.78-.0213,1.6732.1652,2.58.957.28,1.9647-.02,2.9348.1693l-.0294-.025q0,1.4023,0,2.8048l.0244-.0247-2.9171.14v2.9026h2.9173l-.0244-.024v2.8578H215.812v2.9316h2.9152v5.7833a15.6562,15.6562,0,0,0,2.8465-.1558v-2.8554h2.8049c.055-1.0041.1032-1.8824.1513-2.7608Zm-8.8555,14.3426v-8.4971H207.16v8.4971Zm.1518,8.8264v2.73H218.58v-2.73Z"/>
                <path d="M183.9381,225.3328v-2.7611h-2.97v-2.8883H178.15v8.52a11.5084,11.5084,0,0,1-3.0108.0683v-8.6619h-5.7215v-2.77c.8808-.3061,1.8632.0382,2.8265-.21v-2.6818c-.9059-.2947-1.8522.0256-2.8106-.2109a14.38,14.38,0,0,1-.04-2.817H172.27v-2.79a10.355,10.355,0,0,1,2.8671-.0907v5.6487a14.6255,14.6255,0,0,0,2.9277.0771v-2.78h5.7829v2.7364c-.9213.2906-1.9116-.0866-2.824.2269v5.5489c3.8765,0,7.6922,0,11.5077,0a25.7983,25.7983,0,0,0,.1609,3.0342,13.9306,13.9306,0,0,0,2.73-.14v-2.71L192.51,219.52V213.901a10.159,10.159,0,0,1,2.968-.0265v2.7662h5.7949v-2.8352h2.8v-2.8429H189.7371V208.066h17.3068v8.55c-.8946.2753-1.831-.0094-2.7857.1819a14.9668,14.9668,0,0,0-.1132,2.6425c.9454.3356,1.9294-.0164,2.8926.2351v8.533a17.0183,17.0183,0,0,1-2.9444.1241l.0224.0208v-5.7527h-5.7v2.7877h2.8989v2.9648l.0215-.02H198.36v-2.859h-2.7575c-.2507.869.0106,1.7954-.1845,2.8584h-2.9224l.0227.02v-2.9h-2.8008v2.9l.02-.02-3.0378,0,.02.02v-2.8884l-2.8007-.15Zm-5.8991-8.5173h-2.8065v2.711h2.8065Z"/>
                <path d="M149.1608,242.6926h2.83v-2.7948h5.7376v-2.7909c-.9029-.2962-1.8432.0324-2.8083-.2164v-2.702h-2.8753v2.7236h-5.7828v-5.67h14.4219v2.9093h2.84l-.0183-.019a11.527,11.527,0,0,1,.0257,2.8h-2.73V239.93h2.73a13.23,13.23,0,0,1-.0061,2.8064H160.69v3.0018h-2.8268v2.7935h11.4625l-.0241-.0242v5.7994h-2.7792l.021.0213V251.6h-5.82v2.7287l.0256-.0236h-8.6825v5.824h-2.8623v8.6139a14.5637,14.5637,0,0,1-2.9267.181V251.5362h2.7642v-2.944h-2.7937v-2.8559h2.9376v-3.0707Zm2.9032,8.7343h2.7838v-5.6392H152.064Z"/>
                <path d="M178.12,268.9254V283.293h5.7075v2.9459h-2.854v5.7741h-2.86v-5.6755h-2.9258v5.6583h-5.7646V277.5819h5.6421v-5.7538h-2.7089v2.7474a12.6361,12.6361,0,0,1-2.9605.0744v-5.715h-5.8693v-3.0324l-.0211.0224a14.2492,14.2492,0,0,0,3.0172-.0958V263.12l5.821.0263-.0227-.0233v5.8218h5.8213Z"/>
                <path d="M146.2743,208.0646H166.476v20.1713H146.2743Zm2.9142,2.904v14.3128h14.2921V210.9686Z"/>
                <path d="M230.1921,228.2579h-20.201v-20.195h20.201Zm-2.9075-17.2565h-14.35v14.2854h14.35Z"/>
                <path d="M146.2718,271.7807h20.1655v20.2076H146.2718ZM163.5073,289.08V274.7692H149.1832V289.08Z"/>
                <path d="M163.5233,234.1511V231.23h14.52v2.8623H175.24v5.6873a10.5083,10.5083,0,0,0,2.812.0772v-2.8225h5.7851v2.86h-2.939v5.8623l.0251-.0286q-1.406.0181-2.8118.0362c0-.95,0-1.9,0-2.9628a11.7516,11.7516,0,0,0-2.8777.0981v2.8407h2.9012v2.768h-8.8333l.0241.0242L169.3,245.73c.9763-.1667,1.987.1274,2.933-.1621v-5.6126h-2.9345v-5.8233H163.505Z"/>
                <path d="M180.8981,245.7557H192.55v2.7737h2.9868l-.0215-.0206q-.0007,2.91-.0014,5.82l.0233-.0241c-.9629.254-1.9869-.14-3.0413.2614v2.7813l.0237-.0238h-5.8531c0-1.1393,0-2.0949.0007-3.05a13.5892,13.5892,0,0,0,2.9691.0309v-2.7476a11.4464,11.4464,0,0,0-2.9436.0744v2.6692l-2.84.154v2.8182a14.3677,14.3677,0,0,1-2.8313-.0479v-5.66c.9759-.2017,1.9139.093,2.8076-.1941v-2.7089c-.9627-.2391-1.9422.0809-2.9057-.2242v-2.7116Z"/>
                <path d="M192.5168,280.5193h-5.7081c-.05-1.0919-.0955-2.07-.14-3.0468.9794.0006,1.9581.0006,2.963.0006v-2.73a9.9982,9.9982,0,0,0-2.9372.0869v2.6694h-2.75v2.8621a12.351,12.351,0,0,1-2.9159.085v-5.743h2.7725v-2.9246h-2.8789v-2.8537l-.0231.02h8.7574a22.1441,22.1441,0,0,0,.1535,2.7541h2.7064v2.9047l3.0208.14-.0234-.0241,0,2.8039.0236-.0238a25.8,25.8,0,0,0-3.0415.1985v2.8444Z"/>
                <path d="M204.1152,283.3h-2.6911a28.5707,28.5707,0,0,0-.2033,2.9313h-5.6682v2.8547l.0268-.026-2.984.1383v2.8051h-2.8569v-2.8121H186.86a14.277,14.277,0,0,1-.099-2.9393H189.68v-2.9087h2.8371v-2.8239l-.02.0237,3.0415-.1619V277.5l-.0236.0238h2.8574v3.0167h5.7435l-.02-.02a13.0063,13.0063,0,0,1,0,2.8006Z"/>
                <path d="M180.9224,268.9248l-2.8028.0006.022.02v-5.8218H172.32l.0227.0233q-.0126-1.5229-.0253-3.0459h2.78v-2.778h3.03v2.8436h5.7145v2.9324h-2.9432v5.8466Z"/>
                <path d="M207.1117,239.9317v2.704c-.96.3183-1.94-.01-3.018.2563V248.53l.0215-.02q-4.3.16-8.6-.0008l.0215.0206q-.1383-2.9126.0016-5.8251l-.0243.0282h2.8477v2.8859h2.9726v-5.6878l-.02.02,5.8207.0022Z"/>
                <path d="M195.5388,239.9563l0-3.0511-.0243.0287h2.8222a13.4889,13.4889,0,0,0-.1157-2.8039h-2.7065l.0247.0275v-2.9253H198.3v2.9226h8.8394l-.0279-.0247,0,2.804.0237-.0242h-5.82V239.95l.02-.02-5.82-.002Z"/>
                <path d="M218.7558,234.1707c-.0435.8783-.0871,1.7567-.1358,2.7384h-8.7315l.027.0246V234.13h-2.8041l.0279.0247v-2.9215h2.6873c.2945.8672-.0059,1.7976.1788,2.752a10.387,10.387,0,0,0,2.7353.0811c.2711-.9184-.0075-1.85.184-2.7942a15.2029,15.2029,0,0,1,2.8019-.1084v2.8655l3.0571.1667Z"/>
                <path d="M195.5384,236.9052H189.71V234.17h-2.99v-3.0591l-.02.0223a12.1346,12.1346,0,0,0,3.0377-.1v-2.7008l-.02.02,2.8008,0-.0227-.02c0,1.8624,0,3.7247,0,5.6014,1.08.3483,2.0827.0312,3.0436.2234l-.0247-.0275v2.8039Z"/>
                <path d="M163.5259,265.9025h-5.7286V263.165H154.961l.0252.0271v-5.8088a14.091,14.091,0,0,1,2.7874-.0747v2.8181h2.9716l-.0215-.0214a14.8093,14.8093,0,0,0,.13,3.0191h2.6771a11.3805,11.3805,0,0,1-.0257,2.8Z"/>
                <path d="M224.5086,254.3044l.0027,8.8395.0186-.0194h-3.0618v-5.8007h-2.76l.0244.024a11.2557,11.2557,0,0,1,0-3.0425l-.0244.0246,5.8218-.002Z"/>
                <path d="M186.72,231.1112l-2.76.1358v2.7361a16.64,16.64,0,0,1-2.9289.1544v-8.655a23.7,23.7,0,0,1,2.9073-.15l-.019-.018a13.3441,13.3441,0,0,0,.1091,3.0385H186.72l-.02-.02a12.9189,12.9189,0,0,1,0,2.8Z"/>
                <path d="M160.7452,260.1267q.0021-2.9109.0041-5.8219l-.0256.0236,5.82,0-.021-.0213q.0126,1.5221.0253,3.0439h-3.0235v2.7813l-2.8007-.0266Z"/>
                <path d="M218.7806,245.8073c-.3053-1.9145-.0375-3.8372-.151-5.8007a13.8642,13.8642,0,0,1,2.8635-.091v2.7422a16.8278,16.8278,0,0,0,3.0388.0736l-.0229-.0218v3.0736c-2.0083,0-3.88,0-5.7521-.0013Z"/>
                <path d="M218.7521,248.4817h5.7563v3.0677l.0212-.0218-5.8211-.0024.0294.025a8.9441,8.9441,0,0,1,.0391-3.0957Z"/>
                <path d="M172.2716,222.59v5.6426a14.2367,14.2367,0,0,1-2.833.0593V222.59Z"/>
                <path d="M189.6218,216.6775H186.8v-2.8043a14.0087,14.0087,0,0,1,2.8221-.04Z"/>
                <path d="M209.8761,248.4859v-2.68a14.8188,14.8188,0,0,1,2.9443-.1052v2.8408a12.64,12.64,0,0,1-2.9687-.0819Z"/>
                <path d="M195.5385,242.7043h-2.9294v-2.71a11.4525,11.4525,0,0,1,2.93-.038l-.0247-.0277v2.8039Z"/>
                <path d="M186.7719,242.7209V240.008a10.7929,10.7929,0,0,1,2.8723-.0618v2.7329A11.2176,11.2176,0,0,1,186.7719,242.7209Z"/>
                <path d="M149.1859,242.6656a17.2077,17.2077,0,0,1-2.897.1235,12.0643,12.0643,0,0,1-.059-2.831h2.9293c0,.9182,0,1.8273.0008,2.7354Z"/>
                <path d="M209.8885,236.9091v2.9052a19.28,19.28,0,0,1-2.7768.1174l.0235.0209a14.21,14.21,0,0,1,0-3.0427l-.0237.0242,2.8044,0Z"/>
                <path d="M227.3555,260.0931v-2.73h2.8571v2.73Z" />
                <path d="M218.777,248.4545a13.2719,13.2719,0,0,1-2.9982.0971v-2.8254a11.9154,11.9154,0,0,1,3.0008.08l-.0227-.0243a9.1683,9.1683,0,0,0-.0058,2.701Z"/>
                <path d="M154.961,263.165V265.87a11.0171,11.0171,0,0,1-2.9269.0238v-2.7319a9.2772,9.2772,0,0,1,2.9512.0289Z"/>
                <path d="M227.245,234.1934a14.9187,14.9187,0,0,1,2.9066-.07,16.459,16.459,0,0,1,.1173,2.8027h-2.9978v-2.759Z"/>
                <path d="M227.2711,234.1676h-2.7728a13.842,13.842,0,0,1,.0139-2.9452h2.7444a11.9765,11.9765,0,0,1-.0107,2.97Z"/>
                <path d="M218.7837,234.196c-.3168-.9417-.0264-1.8995-.1674-2.8893a12.6506,12.6506,0,0,1,2.8471-.1362v2.9984c-.8934,0-1.8016,0-2.7087.0009Z"/>
                <path d="M230.2147,245.7546v2.75h-2.83v-2.75Z" />
                <path d="M195.5526,289.0859h2.6907a10.2717,10.2717,0,0,1,.048,2.9281h-2.7329a10.5133,10.5133,0,0,1,.02-2.9533Z"/>
                <path d="M207.153,289.0839h2.6932a15.5249,15.5249,0,0,1,.0759,2.9334h-2.7773a9.6,9.6,0,0,1,.0344-2.9583Z"/>
                <path d="M212.9187,239.9461h2.7257a14.6564,14.6564,0,0,1,.09,2.76h-2.816Z"/>
                <path d="M224.5084,251.5494h2.7655v2.7551h-2.7653l.0215.0235a8.7376,8.7376,0,0,1-.0005-2.8Z"/>
                <path d="M224.5319,242.7314a16.5682,16.5682,0,0,1-.0976-2.7743h2.8363V242.71h-2.7617Z"/>
                <path d="M204.0932,228.3327v2.7986h-2.7573c0-.9729,0-1.8854,0-2.7979l-.0215.02,2.8011,0Z"/>
                <path d="M175.1235,257.3484l-2.8036,0v-5.7964h2.8036Z"/>
                <path d="M166.52,260.1291q0-1.4031,0-2.8062l5.8245.0014,0,2.8033Z"/>
                <path d="M198.3181,268.9459h-2.8037q0-1.5219,0-3.0436h2.8036Z"/>
                <path d="M195.5385,268.9219v2.803l-3.0433,0v-2.8025Z" />
                <path d="M209.9837,271.7758h2.794a14.0055,14.0055,0,0,1,.09,2.7983,11.381,11.381,0,0,1-2.8843.0795Z"/>
                <path d="M201.3369,254.3284H198.294v-2.6972a14.0313,14.0313,0,0,1,3.0429-.1343Z"/>
                <path d="M169.3268,239.927q.1367,2.9123-.0022,5.8243h-5.822v-3.043H166.52v-2.7807Z"/>
                <path d="M163.5024,239.9529a15.7559,15.7559,0,0,0,0-3.0433h3.0429v3.0437Z"/>
                <path d="M163.5026,260.1039l3.0428,0v3.044l-3.0429,0A15.9817,15.9817,0,0,0,163.5026,260.1039Z"/>
                <path d="M152.0269,222.4429V213.88h8.6074v8.5631Z" />
                <path d="M224.3989,222.48h-8.6124v-8.6223h8.6124Z" />
                <path d="M152.0468,277.6h8.6145v8.61h-8.6145Z" />
            </g>
        </SvgIcon>
    )
};

export default withStyles(styles)(LogoSvg);