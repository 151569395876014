import {compose, isEmpty} from 'ramda';
import {NAME} from './constants';
import {createSelector} from "reselect";
import {getToken} from "../authorize";

const getState = state => state[NAME];

const getProgress = compose(({progress}) =>({progress}), getState);

export const getAvailable = compose(({available}) => isEmpty(available) ? {members: 0, qrCodes: 0} : available , getState);

const getTrialEnd = compose(({trialEnd}) => (new Date(trialEnd * 1000).toDateString()), getState);

const getIsTrialing = compose(({trialEnd}) => (!!trialEnd), getState);

export const getMetaObject = compose(({meta}) =>(meta), getState);

export const transformMeta = (m) => Object.keys(m).map((k) => ({name: k, value:m[k]}));

export const getOrganization = compose(({name, phone, website, isAssigned, meta, addresses}) => ({name, phone, website, isAssigned, meta: !!meta ? transformMeta(meta) : [], addresses: !!addresses ? addresses : []}), getState);

export const getExists = compose(({phone, website}) => !!phone && !!website, getOrganization);

export const getName = compose(({name}) => (name), getOrganization);

const getIsAssigned = compose(({isAssigned})=>(isAssigned), getOrganization);

const getIsNewOrg = compose(({name}) => !!!name, getState);

export const getLogoImage = compose(({logoImage}) => (logoImage), getState);

export const dashboardProps = {getAvailable, getTrialEnd, getIsTrialing, getName, getIsNewOrg, getLogoImage};

export const mapUpdateOrgToProps = createSelector(
    getToken,
    getOrganization,
    getExists,
    (token, organization, exists) => ({
        token,
        initialValues: {token, ...organization},
        exists
    })
);

export const mapOrgContentToProps = createSelector(
    getIsAssigned,
    getExists,
    (isAssigned, exists) => ({
        isAssigned,
        exists
    })
);

export const mapLogoImageToProps = createSelector(
    getToken,
    getLogoImage,
    getProgress,
    getExists,
    (token, logoImage, {progress}, exists) => ({
        progress,
        initialValues: {
            token,
            logoImage
        },
        exists
    })
);

export const mapPreviewToProps = createSelector(
    getOrganization,
    getLogoImage,
    getMetaObject,
    (organization, logoImage, meta) => ({
        organization: {
            ...organization,
            meta,
            logoImage
        }
    })
);