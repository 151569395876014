import React from 'react';
import PropTypes from 'prop-types/prop-types';
import {reduxForm, Field} from '../../utils/redux-form';
import * as formValidators from '../../utils/formValidation';
import Grid from "@material-ui/core/Grid";
import {withStyles} from '@material-ui/core/styles';
import {ADD_PROVIDER_FORM} from '../constants';
import Input from '../../components/Input';
import Typography from "@material-ui/core/Typography";
import Button from '../../components/StandardButton';
import {phoneParser} from '../../utils/fieldParser';
import {phoneFormatter} from '../../utils/fieldFormatter';

const styles = () => ({
    button: {
        textAlign: 'right'
    }
});

export class AddProviderForm extends React.Component {

    render() {
        const {classes, pristine, invalid, handleSubmit, handleClose} = this.props;

        return (
            <form>
                <Grid container direction="column"
                      justify="center"
                      alignItems="center">
                    <Grid item>
                        <Field
                            name="token"
                            component="input"
                            type="hidden"
                        />
                        <Field
                            name="name"
                            component={Input}
                            type="text"
                            label="Name"
                            placeholder={'Name'}
                        />
                        <Field
                            name="email"
                            component={Input}
                            type="text"
                            label="Contact Email Address"
                            placeholder={'Contact Email Address'}
                        />
                        <Field
                            name="website"
                            component={Input}
                            type="text"
                            label="Provider Website"
                            placeholder={'Provider Website'}
                        />
                        <Field
                            name="phone"
                            component={Input}
                            type="text"
                            label="Contact Phone"
                            placeholder={'Contact Phone'}
                            format={phoneFormatter}
                            parse={phoneParser}
                        />
                        <Field
                            name="contact"
                            component={Input}
                            type="text"
                            label="Contact Name"
                            placeholder={'Contact Name'}
                        />
                        <Typography className={classes.button}>
                            <Button onClick={handleClose} color="inherit" variant={'text'}>
                                Cancel
                            </Button>
                            <Button
                                variant={'text'}
                                color={'secondary'}
                                disabled={pristine || invalid}
                                onClick={handleSubmit}
                                type="button">
                                Add Provider
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
        )
    }
}

AddProviderForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    pristine: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired
};

export const StyledAddProviderForm = withStyles(styles)(AddProviderForm);

export default reduxForm({
    form: ADD_PROVIDER_FORM,
    validate: formValidators.createValidator({
        name: formValidators.required,
        email: formValidators.email
    })
})(StyledAddProviderForm);