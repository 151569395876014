import Typography from "@material-ui/core/Typography";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import {PROVIDER_STATUS_TEXT, getWeight, getStyle, getColor} from '../common/provider-styles';

const ProviderText = ({name, status}) => {
    return (
        <Tooltip title={PROVIDER_STATUS_TEXT[status]} >
        <Typography component="h2"
                    style={{
                        fontSize: 'inherit',
                        fontWeight: getWeight(status) ,
                        fontStyle: getStyle(status),
                        color: getColor(status)
                    }}
        >{name}</Typography>
        </Tooltip>
    )
};

export default ProviderText;