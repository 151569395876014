import React from 'react';
import Container from '../containers/ConfirmEmailContainer';
import Grid from "@material-ui/core/Grid";
import AuthPaper from './AuthPaper';
import Link from "../../components/RouterLink";

export const ConfirmEmail = () => {
    return (
        <div>
            <AuthPaper>
                <Grid item>
                    <Container/>
                </Grid>
                <Grid item>
                    <p><Link to={`/`}>Go Home</Link></p>
                </Grid>
            </AuthPaper>
        </div>
    );
};

export default ConfirmEmail;