import React, {Fragment} from 'react';
import Typography from "@material-ui/core/Typography";
import Navigation from '../../components/ConnectedNavigation';
import AlertDialog from "../../components/AlertDialog";
import Button from "@material-ui/core/Button";
import UpdateAccountFormContainer from "../containers/UpdateAccountFormContainer";
import Grid from "@material-ui/core/Grid";
import AddPaymentMethodDialog from "./AddPaymentMethodDialog";
import {loadStripe} from "@stripe/stripe-js";
import {REACT_APP_STRIPE_PUBLIC_KEY} from "../../config";
import {Elements} from "@stripe/react-stripe-js";
import PaymentMethodItem from "./PaymentMethodItem";

const NoMethodsMessage = ({isExpired}) => {
    return (<Typography component={'span'} variant={'body2'} color={isExpired ? 'error' : "textSecondary"}>No payment
        methods on file.</Typography>)
};

export class AccountContent extends React.Component {
    state = {
        isOpen: false,
        isPaymentOpen: false,
        stripePromise: '',
        dialogProps: {}
    };

    componentDidMount() {
        this.setState({stripePromise: loadStripe(REACT_APP_STRIPE_PUBLIC_KEY)});
        const {loadPaymentMethods, token, hasMethods, loadAccount, accountExists} = this.props;
        if (!hasMethods) {
            loadPaymentMethods({token})
        }

        if(!accountExists) {
            loadAccount({token})
        }
    }

    handlePaymentOpen = () => {
        this.setState({isPaymentOpen: !this.state.isPaymentOpen});
    };

    handlePaymentClose = () => {
        this.setState({isPaymentOpen: !this.state.isPaymentOpen});
    };

    handleClose = async (e, _id) => {

        if (!!_id) {
            const {deleteAccount, deletePaymentMethod, token} = this.props;

            const fn = _id === '1' ? deleteAccount : deletePaymentMethod;

            await fn({token, _id})
        }
        this.setState({isOpen: !this.state.isOpen, dialogProps: {}})
    };

    handleOpen = () => {
        this.setState({isOpen: !this.state.isOpen, dialogProps: this.getEndAllBenefitsDialogProps()});
    };

    handleDelete = (e, paymentMethod) => {
        // alert(`Delete '${id}'`)
        this.setState({isOpen: !this.state.isOpen, dialogProps: this.getDeletePaymentMethodDialogProps(paymentMethod)})

    };

    getDeletePaymentMethodDialogProps = ({id, name, last4}) => ({
        title: `Delete Payment Method`,
        contentText: `This will delete the ${name} card ending in ${last4} from your account.`,
        _id: id
    });

    getEndAllBenefitsDialogProps = () => ({
        title: `End My Benefits`,
        contentText: `This will cancel your account and delete all your data.`,
        _id: '1'
    });

    render() {
        const {isOpen, isPaymentOpen} = this.state;

        const {
            paymentMethods, hasMethods, isExpired
            , accountExists
        } = this.props;

        return (
            <Navigation title={'Account'}>
                <Typography variant={"h4"} gutterBottom>
                    My Account
                </Typography>
                <Grid container
                      direction="column"
                      spacing={2}
                >
                    {accountExists && <Fragment>
                        <Grid item>
                            <UpdateAccountFormContainer/>
                        </Grid>

                        <Grid item>
                            <Button
                                type="button"
                                variant="contained" color="primary"
                                onClick={this.handlePaymentOpen}
                            >
                                Add Payment Method
                            </Button>
                        </Grid>
                        <Grid item>
                            {hasMethods ? paymentMethods.map((method, i) => (
                                    <PaymentMethodItem key={i} {...method} onDeleteClick={this.handleDelete}/>))
                                : <NoMethodsMessage {...{isExpired}} />}
                        </Grid>
                    </Fragment>}
                    <Grid item>
                        <Button
                            type="button"
                            variant="contained" color="primary"
                            onClick={this.handleOpen}
                        >
                            End All Benefits
                        </Button>
                    </Grid>
                </Grid>

                {!!this.state.stripePromise && <Elements stripe={this.state.stripePromise}>
                    <AddPaymentMethodDialog isOpen={isPaymentOpen} handleClose={this.handlePaymentClose}/>
                </Elements>
                }
                {isOpen && <AlertDialog isOpen={isOpen} {...this.state.dialogProps} handleClose={this.handleClose}/>}
            </Navigation>
        )
    }
}

export default AccountContent;