import React from 'react';
import Typography from "@material-ui/core/Typography";
import Navigation from '../../components/ConnectedNavigation';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import UpdateEmailFormContainer from '../containers/UpdateEmailFormContainer';
import UpdateProfileFormContainer from '../containers/UpdateProfileFormContainer';
import ChangePasswordFormContainer from '../containers/ChangePasswordFormContainer';
import {withStyles} from '@material-ui/core/styles';
import {Redirect} from 'react-router-dom'
import VerifierFormContainer from "../containers/VerifierFormContainer";

const styles = (theme) => ({
    root: {
        minWidth: 250,
        [theme.breakpoints.up('sm')]: {
            minWidth: 400,
        }
    }
});

export class ProfileContent extends React.Component {
    state = {
        isChanging: false,
        isPasswordChanging: false
    };

    handleChangeClick = toChange => {
        this.setState({
            isChanging: !toChange
        })
    };

    handlePasswordChange = () => {
        const toChange = this.state.isPasswordChanging;
        this.setState({
            isPasswordChanging: !toChange
        })
    };

    render() {
        const {classes, confirmClickHandler, isConfirmed, isExpired, goToOrganization, isVerifier} = this.props;
        const {isChanging} = this.state;

        if (goToOrganization) {
            return <Redirect to={'/app/organization'}/>
        }

        return (
            <Navigation title={'Profile'}>
                <Typography variant={"h4"} gutterBottom>
                    Update Profile
                </Typography>
                <Typography variant={"body1"} gutterBottom color={isExpired? 'error': 'inherit'}>
                    {!isConfirmed ? !isExpired ? 'Confirm your email address to get started.' : 'Add a valid payment method to your account.' : ''}
                </Typography>
                <Grid container
                      direction="column"
                      justify="flex-start"
                      alignItems="center"
                      spacing={4}
                >
                    <Grid item container
                          direction="row"
                          justify="center"
                          alignItems="stretch"
                    >
                        <Grid item>
                            <Grid container
                                  direction="column"
                                  justify="flex-start"
                                  alignItems="stretch"
                                  className={classes.root}
                            >
                                <UpdateEmailFormContainer onChangeClick={this.handleChangeClick} {...{
                                    isChanging,
                                    isConfirmed
                                }}/>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {
                                (!isConfirmed && !isExpired) &&
                                <Button
                                    type="button"
                                    variant="contained" color="primary"
                                    disabled={isChanging}
                                    onClick={confirmClickHandler}
                                >
                                    Confirm
                                </Button>
                            }
                        </Grid>
                    </Grid>
                    {
                        isConfirmed &&
                        <React.Fragment>
                            {isVerifier &&
                            <Grid item>
                                <Grid container
                                      direction="column"
                                      justify="flex-start"
                                      alignItems="stretch"
                                      className={classes.root}
                                >
                                    <VerifierFormContainer />
                                </Grid>
                            </Grid>
                            }
                            <Grid item>
                                <Grid container
                                      direction="column"
                                      justify="flex-start"
                                      alignItems="stretch"
                                      className={classes.root}
                                >
                                    <ChangePasswordFormContainer onChangeClick={this.handlePasswordChange}
                                                                 isChanging={this.state.isPasswordChanging}/>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container
                                      direction="column"
                                      justify="flex-start"
                                      alignItems="stretch"
                                      className={classes.root}
                                >
                                    <UpdateProfileFormContainer/>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </Navigation>
        )
    }
}

export default withStyles(styles)(ProfileContent)