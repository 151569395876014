import React from "react";
import PropTypes from "prop-types";
import DropZone from "react-dropzone";
import ImagePreview from "./ImagePreview";
import Placeholder from "./Placeholder";
import ShowError from "./ShowError";

const DropZoneField = ({
                           handleOnDrop,
                           input,
                           imageFile,
                           defaultImage,
                           meta: {error, touched}
                       }) => {
    const {onChange} = input;
    return (
        <React.Fragment>
            <DropZone
                accept="image/jpeg, image/png, image/gif, image/bmp"
                className="upload-container"
                onDrop={file => handleOnDrop(file, onChange)}
                multiple={false}
            >
                {
                    (props) => (
                        <div>
                            {
                                imageFile && imageFile.length > 0 ? <ImagePreview imagefile={imageFile}/> :
                                    <Placeholder {...props} error={error} touched={touched} defaultImage={defaultImage}/>
                            }
                        </div>
                    )
                }
            </DropZone>
            <ShowError error={error} touched={touched}/>
        </React.Fragment>
    )
};

DropZoneField.propTypes = {
    error: PropTypes.string,
    handleOnDrop: PropTypes.func.isRequired,
    imagefile: PropTypes.arrayOf(
        PropTypes.shape({
            file: PropTypes.file,
            name: PropTypes.string,
            preview: PropTypes.string,
            size: PropTypes.number
        })
    ),
    onChange: PropTypes.func,
    touched: PropTypes.bool
};

export default DropZoneField;
