/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from "@material-ui/core/FormHelperText";
import {AddProviderDialog} from "../providers";
import {VerifiedIcon, PreferredIcon} from "../common/icons";
import {PROVIDER_STATUS_TEXT, getWeight, getStyle, getColor} from '../common/provider-styles';
import Tooltip from "@material-ui/core/Tooltip";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: 'flex',
        paddingLeft: 0
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        // marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    }
});

const CustomCursorPointerStyles = (base) => ({
    ...base,
    cursor: 'pointer'
});

function inputComponent({inputRef, ...props}) {
    return <div ref={inputRef} {...props} />;
}

const ProviderIcon = ({status, ...props}) => {

    const PROVIDER_ICON = {
        verifier: VerifiedIcon,
        preferred: PreferredIcon
    };

    const Icon = PROVIDER_ICON[status.toLowerCase()];

    return (!!Icon ? <Icon {...props} /> : <div/>)
};

const components = {
    Control: (props) => {
        return (
            <TextField
                fullWidth
                InputProps={{
                    inputComponent,
                    inputProps: {
                        className: props.selectProps.classes.input,
                        inputRef: props.innerRef,
                        children: props.children,
                        ...props.innerProps,
                    },
                }}
                {...props.selectProps.textFieldProps}
            />
        );
    },
    Menu: (props) => {
        return (
            <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
                {props.children}
            </Paper>
        );
    },
    NoOptionsMessage: (props) => {
        return (
            <Button
                size="small"
                color="secondary"
                className={props.selectProps.classes.noOptionsMessage}
                {...props.innerProps}
                onClick={(e) => {
                    const a = props.selectProps.inputValue;
                    return props.selectProps.onNoOptionClick(e, a)
                }}
            >
                {props.children}
            </Button>
        );
    },
    Option: (props) => {
        return (
            <Tooltip title={PROVIDER_STATUS_TEXT[props.data.status]}>
                <MenuItem
                    buttonRef={props.innerRef}
                    selected={props.isFocused}
                    component="div"
                    style={{
                        fontWeight: props.isSelected ? 500 : getWeight(props.data.status),
                        fontStyle: getStyle(props.data.status),
                        color: getColor(props.data.status)
                    }}
                    {...props.innerProps}
                >
                    {props.data.status && <ProviderIcon status={props.data.status}/>}&nbsp;
                    {props.children}
                </MenuItem>
            </Tooltip>
        );
    },
    Placeholder: (props) => {
        return (
            <FormHelperText
                margin={'dense'}
                error={props.selectProps.error}
                className={props.selectProps.classes.placeholder}
                {...props.innerProps}
            >
                {props.children}
            </FormHelperText>
        );
    },
    SingleValue: (props) => {
        return (
            <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
                {props.children}
            </Typography>
        );
    },
};

export class Autocomplete extends React.Component {
    state = {
        isOpen: false,
        search: ''
    };

    handleChange = (selection) => {
        const {input} = this.props;

        input.onChange(!!selection ? selection.value : '');
    };

    handleBlur = (selection) => {
        const {input} = this.props;

        input.onBlur(!!selection ? selection.value : undefined);
    };

    handleNoOptionClick = (e, a) => {
        const search = !!a ? a : undefined;
        this.setState({isOpen: !this.state.isOpen, search})
    };

    handleDialogClose = (providerId) => {
        const search = this.state.search;

        const {input} = this.props;
        this.setState({isOpen: !this.state.isOpen, search: ''});

        if (!!search) {
            input.onChange(!!providerId ? providerId : undefined);//<<<<<need to get the providerId of the added provider
        }
    };

    render() {

        const {classes, input, label, meta: {touched, error}, options, theme} = this.props;

        const [selectedValue] = options.filter((o) => o.value === input.value);

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
            clearIndicator: CustomCursorPointerStyles,
            dropdownIndicator: CustomCursorPointerStyles
        };

        const errorState = touched && error === 'Required' ? {error: true} : undefined;

        const {search, isOpen} = this.state;

        return (
            <FormControl margin={'dense'} fullWidth={true} {...errorState}>
                <div className={classes.root}>
                    <Select {...errorState}
                            {...{classes, components, options}}
                            styles={selectStyles}
                            textFieldProps={{
                                ...errorState,
                                label,
                                InputLabelProps: {
                                    shrink: true,
                                    margin: 'dense'
                                },
                            }}
                            value={selectedValue}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            onNoOptionClick={this.handleNoOptionClick}
                            noOptionsMessage={() => 'Add new provider...'}
                            placeholder="Search for Provider"
                            isClearable
                    />
                </div>
                {touched && error && <FormHelperText
                    id={`error-text-${input.name}`}>
                    {error}
                </FormHelperText>}
                <AddProviderDialog {...{search, isOpen, handleClose: this.handleDialogClose}} />
            </FormControl>
        );
    }
}

Autocomplete.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Autocomplete);
