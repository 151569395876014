import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import OrganizationViewer from "../../qr_coded/components/OrganizationViewer";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CredentialPreviewerList from "../../members/components/CredentialPreviewerList";
import MemberViewer from "../../qr_coded/components/MemberViewer";
import TeamViewer from "../../qr_coded/components/TeamViewer";

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
});


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class PreviewDialog extends React.Component {
    state = {
        open: false,
        maxWidth: 'xs',
    };

    handleMaxWidthChange = event => {
        this.setState({maxWidth: event.target.value});
    };

    render() {
        const {classes, nickname, type} = this.props;

        const {name, phone, website, logoImage, meta} = !!this.props.organization ? this.props.organization : {};
        const org = {name, phone, website, logoImage, meta};

        const {email, firstName, lastName, mobilePhone, profileImage, credentials, showElements} = !!this.props.member ? this.props.member : {};
        const member = {email, firstName, lastName, mobilePhone, profileImage, credentials, showElements};

        const team = !!this.props.team ? this.props.team : [];

        return (
            <Dialog
                fullWidth={true}
                maxWidth={this.state.maxWidth}
                open={this.props.open}
                onClose={this.props.handleClose}
                TransitionComponent={Transition}
                scroll={'paper'}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" onClick={this.props.handleClose} aria-label="Close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            Preview QR
                        </Typography>
                    </Toolbar>
                </AppBar>
                <form className={classes.form} noValidate>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="max-width">Preview Width</InputLabel>
                        <Select
                            className={'maxWidth'}
                            value={this.state.maxWidth}
                            onChange={this.handleMaxWidthChange}
                            inputProps={{
                                name: 'max-width',
                                id: 'max-width',
                            }}
                        >
                            <MenuItem value="xs">Mobile Phone</MenuItem>
                            <MenuItem value="xl">Computer</MenuItem>
                        </Select>
                    </FormControl>
                </form>
                <DialogContent>
                    <DialogContentText component={'div'}>
                        <Typography variant="body2" gutterBottom component="p">
                            <Typography variant="body1" display={'inline'} component={'span'}>
                                {!!type &&type.toUpperCase()}
                            </Typography> {nickname}
                        </Typography>
                        {type === 'organization' && <OrganizationViewer {...org}/>}
                        {type === 'member' && <MemberViewer {...member} isPreview={true} component={CredentialPreviewerList}/>}
                        {type === 'team' && <TeamViewer team={team} isPreview={true}  component={CredentialPreviewerList} />}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

PreviewDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    organization: PropTypes.object.isRequired
};

export default withStyles(styles)(PreviewDialog);