import {makeStyles} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";

const getLabel = (provider) => `Yes, I want to be the Verifier for ${provider}. 
I understand that the Qredify system will send me an email for every credential assigned to ${provider}. 
The email will contain a special link that will provide me secure access to view the credential and associated data. 
I confirm that I will be able to use this information to conclude the validity of the credential and accuracy of the data. 
If the credential is valid and the data accurate I will have the opportunity to confirm that the credential is Verified. 
If I find that the credential does not match my records I will have the opportunity to Reject that credential and provide a reason why it is rejected. 
I agree that this service is provided to me to protect the validity of my credentials free of charge. 
I also agree that I am not receiving any remuneration from Qredify. 
I understand that I can cancel my Verifier status at any time by visiting the Profile page of my Qredify account. 
Cancelling my Verifier status will revert ${provider} to non-verifier status and the status of all credentials associated with ${provider} will revert to: ‘Provider Unable to Verify’`

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'flex-start'
    }
}));

export default (props) => {

    const classes = useStyles();

    const {input} = props;

    const{value: thisval} = input;

    const isChecked = thisval === 'true' || (thisval === 'false' ? false : false)

    const onChange = (e, checked) => {
        input.onChange(checked.toString());
    }

    return (
        <FormControlLabel
            className={classes.root}
            control={<Checkbox checked={isChecked} onChange={(e, checked) => onChange(e, checked)} name="checkedA"/>}
            label={getLabel(props.provider)}
        />
    )
}