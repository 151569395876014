import {combineReducers} from "redux";

export const combine = (persistedState, reducers) => {
    const reducerNames = Object.keys(reducers);
    Object.keys(persistedState).forEach(item => {
        if (reducerNames.indexOf(item) === -1) {
            reducers[item] = (state = null) => state;
        }
    });
    return combineReducers(reducers);
};

export class ReducerRegistry {
    constructor() {
        this._emitChange = null;
        this._reducers = {};
    }

    getReducers() {
        return { ...this._reducers };
    }

    register(name, reducer) {
        if(Object.keys(this._reducers).includes(name)) throw Error(`The reducer name '${name}' is already in use`);

        this._reducers = { ...this._reducers, [name]: reducer };
        if (this._emitChange) {

            this._emitChange(this.getReducers());
        }
    }

    setStoreChangeListener(persistedState, store) {
        this._emitChange = reducers => {
            store.replaceReducer(combine(persistedState, reducers));
        }
    }
}

const reducerRegistry = new ReducerRegistry();
export default reducerRegistry;