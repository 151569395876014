import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = () => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        width: '100%',
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const SelectControl = (props) => {

    const {classes, input, label, meta: {touched, error}, options, disabled} = props;

    const errorState = touched && error ? {error: true} : undefined;

    return (
        <FormControl className={classes.formControl} {...errorState} disabled={disabled}>
            <InputLabel htmlFor={input.name}>{label}</InputLabel>
            <Select
                {...input}
                renderValue={selected => {
                    return options.filter((o) => selected.includes(o.key)).map((o) => o.value).join(', ')
                    // return selected.join(', ')
                }}
                MenuProps={MenuProps}
            >
                {options.map(({key, value}) => (
                    <MenuItem key={key} value={key}>
                        <ListItemText primary={value}/>
                    </MenuItem>
                ))}
            </Select>
            {touched && error && <FormHelperText
                id={`error-text-${input.name}`}>
                {error}
            </FormHelperText>}
        </FormControl>
    );
};

SelectControl.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectControl);
