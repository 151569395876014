import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import zxcvbn from 'zxcvbn';
import {isEmpty} from 'ramda';
import styles, {variantIcon, states as validStates} from '../../common/validation-styles';

const {error, success, warning} = validStates;

const states = {
    0: error,
    1: error,
    2: warning,
    3: warning,
    4: success,
    default: undefined
};

const messages = {
    3: 'Better, but still weak',
    4: 'STRONG, good job!'
};

const getValidationState = (d, e, s) => Boolean(e) ? states[0] : d && s ? states[s.score] : states['default'];

const getMsgText = (e, s) => Boolean(e) ? e : messages[s.score];

class StyledPassword extends React.Component {
    state = {
        showPassword: false,
    };

    componentDidMount() {
        this.forceUpdate();
    }

    handleClickShowPassword = () => {
        this.setState(state => ({showPassword: !state.showPassword}));
    };

    render() {

        const {classes, label, input, meta: {dirty, error, touched}} = this.props;
        const isValid = !(dirty && error);
        const pass = input.value;
        const fStrength = isValid && pass ? zxcvbn(pass) : {};
        const validationState = getValidationState(dirty, error, fStrength);
        const errorState = touched && error === 'Required' ? {error: true} : undefined;
        const msg = error || !isEmpty(fStrength) ? getMsgText(error, fStrength) : '';
        const Icon = variantIcon[validationState];
        return (
            <FormControl className={classes.formControl} {...errorState}>
                <InputLabel htmlFor={input.name}>{label}</InputLabel>
                <Input
                    id={input.name}
                    type={this.state.showPassword ? 'text' : 'password'}
                    {...input}
                    onChange={input.onChange}
                    aria-describedby={`msg-text-${input.name}`}
                    autoComplete={"new-password"}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickShowPassword}
                            >
                                {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />

                {dirty && msg && <FormHelperText
                    className={classNames(classes[validationState], classes.message)}
                    id={`msg-text-${input.name}`}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)}/>
                    {msg}
                </FormHelperText>}

                {touched && error === 'Required' && <FormHelperText
                    id={`error-text-${input.name}`}>
                    {error}
                </FormHelperText>}
            </FormControl>
        )
    }
}

StyledPassword.propTypes = {
    label: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default withStyles(styles)(StyledPassword)