import React from "react";
import PropTypes from 'prop-types/prop-types';
import {Field, Form, reduxForm} from "../../utils/redux-form";
import {MEMBER_IMAGE_FORM} from "../constants";
import * as formValidators from "../../utils/formValidation";
import ImageField, {IMAGE_TYPE} from "../../components/ImageField";

export class MemberImageForm extends React.Component {

    render() {
        const {handleSubmit, progress, reset} = this.props;

        return (

            <Form onSubmit={handleSubmit}>
                <Field
                    name="token"
                    component="input"
                    type="hidden"
                />
                <Field
                    name="memberId"
                    component="input"
                    type="hidden"
                />
                <Field
                    name={IMAGE_TYPE.PROFILE}
                    component={ImageField}
                    type="text"
                    progress={progress}
                    onClick={handleSubmit}
                    onCancel={reset}
                />
            </Form>
        )
    }
}

MemberImageForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    progress: PropTypes.number
};

export default reduxForm({
    form: MEMBER_IMAGE_FORM,
    enableReinitialize: true,
    validate: formValidators.createValidator({
        profileImage: formValidators.imageIsRequiredAndNotTilde
    },)
})(MemberImageForm);