import {Route, Switch} from 'react-router-dom';
// import AddContent from "./AddCredential";
import React from "react";
import UpdateProviderContentContainer from "../containers/UpdateProviderContentContainer";
import ProviderAdminContent from "./ProviderAdminContent";


export default () => {
    return (
        <Switch>
            <Route path={'/app/providers/:providerId'} component={UpdateProviderContentContainer} />
            <Route path={'/'} component={ProviderAdminContent} />
        </Switch>
    )
}