import React from 'react'
import {Route, Redirect, withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {mapAuthToProps} from './selectors';
import {initializeHandler} from "../common/actions";

export const AuthorizedRoute = (props) => {
    const {component: Component, isFetching, auth, history, ...rest} = props;

    initializeHandler(history);

    return (
        <Route {...rest} render={(props) => auth ? <Component {...props} /> :
                <Redirect to={
                    {pathname: '/auth/signin', state: { from: props.location}}
                }/>
        }/>
    )
};

export default withRouter(connect(mapAuthToProps)(AuthorizedRoute))