import {isEmpty} from 'ramda';

export default (loadingState = 0, action) => {

    //async calls end with either success or failure
    if(!isEmpty(action) && (action.type.includes('/SUCCESS') || action.type.includes('/FAILURE'))){
        return loadingState === 0 ? loadingState : loadingState - 1;
    }
    else {
        if(!isEmpty(action) && action.type.includes('/REQUEST')) { return loadingState + 1;}

        return loadingState;
    }
};
