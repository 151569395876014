import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import styles from '../../common/validation-styles';

class ViewablePassword extends React.Component {
    state = {
        showPassword: false,
    };

    componentDidMount() {
        this.forceUpdate();
    }

    handleClickShowPassword = () => {
        this.setState(state => ({showPassword: !state.showPassword}));
    };

    render() {

        const {classes, label, input} = this.props;
        return (
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor={input.name}>{label}</InputLabel>
                <Input
                    id={input.name}
                    type={this.state.showPassword ? 'text' : 'password'}
                    {...input}
                    autoComplete={"current-password"}
                    aria-describedby={`msg-text-${input.name}`}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Toggle password visibility"
                                onClick={this.handleClickShowPassword}
                            >
                                {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        )
    }
}

ViewablePassword.propTypes = {
    label: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired
};

export default withStyles(styles)(ViewablePassword)