import {createSelector} from 'reselect/lib';
import {NAME} from './constants';
import {getToken} from "../authorize"
import {compose} from "ramda";

const getState = state => state[NAME];

const getCredential = compose(({credential}) => credential,getState)

export const mapCodedContentToProps = createSelector(
    getToken,
    getState,
    (token, state) => ({
        token,
        ...state
    })
);

export const mapSingleCredentialToProps = createSelector(
    getToken,
    getCredential,
    (token, credential) => ({token, credential})
)