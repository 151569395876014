import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default () => (<TableRow >
    <TableCell colSpan={6} >
        <Typography variant={'body2'}  >
            No expiring credentials found
        </Typography>
    </TableCell>
</TableRow>);