import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {withStyles} from '@material-ui/core/styles';
import Navigation from "../../components/ConnectedNavigation";
import Typography from "@material-ui/core/Typography";
import LogoSvg from "../../common/content/logo";
import DataTable from "./DataTable";

const styles = (theme) => ({
    reportHeader: {
        marginBottom: theme.spacing(3)
    },
    logo: {
        width: 105,
        height: 89,
    },
    header: {
        fontWeight: 600
    }
});

export default withStyles(styles)((props) => {

    const {classes, report, loadReportById, token} = props;

    const {match: {params: {templateId}}} = props;

    useEffect(() => {
        const loadNow = () => {
            return loadReportById({token, templateId});
        };
        loadNow();
    }, [token, loadReportById, templateId]);

    const {name, reportDate, dataset} = report;

    const reptDate = new Date(reportDate);

    return (
        <Navigation title={'Credentials'}>
            <Grid container
                  direction="column"
                  justify="center"
                  alignItems="center"
            >
                <Grid item container direction={'row'} justify={'flex-start'} spacing={4}
                      className={classes.reportHeader}>
                    <Grid item xs={2}>
                        <LogoSvg className={classes.logo}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container
                              direction="column"
                              justify="flex-start"
                              alignItems="stretch">
                            <Grid item>
                                <Grid container
                                      direction="column"
                                      justify="center"
                                      alignItems="center">
                                    <Grid item>
                                        <Typography variant={"h5"}>
                                            {name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container
                                      direction="column"
                                      justify="flex-start"
                                      alignItems="flex-end">
                                    <Grid item>
                                        <Typography variant={"body1"} component={'span'}>
                                            Report Date: <Typography display={'inline'} variant={'body2'} color={"textSecondary"}>
                                            {reptDate.toDateString()}
                                        </Typography>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={2}/>
                </Grid>
                <Grid item>
                    <DataTable {...{dataset}}/>
                </Grid>
            </Grid>
        </Navigation>
    )
});
