import React, {useState} from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = () => ({
    formControl: {
        width: '100%'
    },
    label: {
        position: 'relative'
    },
    element: {
        padding: 10
    },
});

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

function CardSection({classes, handleReady}) {

    const [isTouched, setIsTouched] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const handleChange = ({error, empty, complete}) => {

        setErrorMsg(!!error ? error.message : '');
        setIsTouched(true);

        handleReady(!empty && complete)

    };

    const errorState = isTouched && errorMsg ? {error: true} : undefined;

    return (
        <FormControl className={classes.formControl}  {...errorState} >
            <InputLabel htmlFor={'cardElement'} shrink className={classes.label}>Card details</InputLabel>
            <div id={'cardElement'} className={classes.element}>
                <CardElement onChange={handleChange}
                    options={CARD_ELEMENT_OPTIONS}
                />
            </div>
            {isTouched && errorMsg && <FormHelperText
                id={`error-text-cardElement`}>
                {errorMsg}
            </FormHelperText>}
        </FormControl>
    );
}

export default withStyles(styles)(CardSection);