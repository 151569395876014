import {combineReducers} from 'redux'
import {AUTH} from './actionTypes';
import {isEmpty} from 'ramda';

const message = (messageState = '', action) => {

    //async calls end with either success or failure if they have message there is something to do
    if ((!isEmpty(action) && (action.type.includes('/SUCCESS') || action.type.includes('/FAILURE'))) && !!action.payload && !!action.payload.message) {
        return action.payload.message;
    }
    if (action.type === AUTH.CLEAR) {
        return '';
    }
    return messageState;
};

const msgState = (msgStateState = 'info', action) => {

    //async calls end with either success or failure if they have msgState there is something to do
    if((!isEmpty(action) && (action.type.includes('/SUCCESS') || action.type.includes('/FAILURE'))) && !!action.payload && !!action.payload.msgState){
        return action.payload.msgState;
    }
    if (action.type === AUTH.CLEAR) {
        return 'info';
    }
    return msgStateState;
};

const authReducers = {message, msgState};

//this export for testing
export const {
    message : messageReducer,
    msgState : msgStateReducer
} = authReducers;

export default combineReducers(authReducers)