import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types/prop-types';
import {reduxForm, Field, Form, submit} from '../../utils/redux-form';
import * as formValidators from '../../utils/formValidation';
import Grid from "@material-ui/core/Grid";
import {withStyles} from '@material-ui/core/styles';
import {UPDATE_PROVIDER_FORM} from '../constants';
import Input from '../../components/Input';
import Typography from "@material-ui/core/Typography";
import Button from '../../components/StandardButton';
import {phoneParser} from '../../utils/fieldParser';
import {phoneFormatter} from '../../utils/fieldFormatter';
import Select from "../../components/SelectControl";
import {getStatusList} from "../../common/provider-styles";
import {Redirect} from "react-router-dom";
import AlertDialog from '../../components/AlertDialog';
import VerifierViewer from "../containers/VerifierViewerContainer";

const styles = (theme) => ({
    button: {
        textAlign: 'right'
    },
    viewer: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(2)
    }
});

const statusList = getStatusList();

const UpdateProviderForm = (props) => {

    const [isOpen, setIsOpen] = useState(false);

    const {classes, submitting, pristine, invalid, handleSubmit, isVeriferStatus, verifier, isEmpty, verifierEmail, name} = props;

    const submitForm = () => {

        const {dispatch} = props;
        return dispatch(submit(UPDATE_PROVIDER_FORM))
    }

    const handleCancel = () => {
        const {selectProvider} = props;

        return selectProvider({_id: ''});
    }

    const handleClose = (event, id) => {
        setIsOpen(!isOpen)

        if(!!id) {
            return submitForm()
        }
    }

    const handleSubmitClick = () => {

        if(!isVeriferStatus || !!verifier) {
            return submitForm();
        }
        setIsOpen(!isOpen);
    }

    const getDialogProps = (props) => ({
        title: `Send Email to Verifier`,
        contentText: `An email will be sent to ${props.verifierEmail} when this Provider is updated, inviting them to be the Verifier for ${props.name}.`,
        _id: !!props.verifierEmail ? props.verifierEmail : ''
    });

    const dialogProps = getDialogProps({verifierEmail, name});

    if (isEmpty || submitting) {
        return <Redirect to={'/app/providers'}/>
    }

    return (
        <Fragment>
            <Form onSubmit={handleSubmit}>
                <Grid container direction="column"
                      justify="center"
                      // alignItems="center"
                >
                    <Grid item>
                        <Field
                            name="token"
                            component="input"
                            type="hidden"
                        />
                    </Grid>
                    <Grid item>
                        <Field
                            name="_id"
                            component="input"
                            type="hidden"
                        />
                        <Field
                            name="name"
                            component={Input}
                            type="text"
                            label="Name"
                            placeholder={'Name'}
                        />
                    </Grid>
                    <Grid item>
                        <Field
                            name="status"
                            component={Select}
                            // onChange={handleChange}
                            type="text"
                            label="Status"
                            options={statusList}
                        />
                    </Grid>
                    <Grid item>
                        {isVeriferStatus && (!!verifier ? <VerifierViewer {...{verifier}} /> : <Field
                            name="verifierEmail"
                            component={Input}
                            type="text"
                            label="Verifier Email Address"
                            placeholder={'Verifier Email Address'}
                            />)
                        }
                    </Grid>
                    <Grid item>
                        <Field
                            name="email"
                            component={Input}
                            type="text"
                            label="Contact Email Address"
                            placeholder={'Contact Email Address'}
                        />
                        <Field
                            name="website"
                            component={Input}
                            type="text"
                            label="Provider Website"
                            placeholder={'Provider Website'}
                        />
                        <Field
                            name="phone"
                            component={Input}
                            type="text"
                            label="Contact Phone"
                            placeholder={'Contact Phone'}
                            format={phoneFormatter}
                            parse={phoneParser}
                        />
                        <Field
                            name="contact"
                            component={Input}
                            type="text"
                            label="Contact Name"
                            placeholder={'Contact Name'}
                        />
                        <Typography className={classes.button}>
                            <Button
                                // to={'../providers'}
                                // component={Link}
                                onClick={handleCancel}
                                color={'inherit'}
                                type="button">
                                Cancel
                            </Button>
                            <Button
                                disabled={pristine || invalid}
                                onClick={handleSubmitClick}
                                type="button">
                                Update
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Form>
            <AlertDialog isOpen={isOpen} {...dialogProps} handleClose={handleClose}/>
        </Fragment>
    )
}

UpdateProviderForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    pristine: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired
};

export const StyledUpdateProviderForm = withStyles(styles)(UpdateProviderForm);

export default reduxForm({
    form: UPDATE_PROVIDER_FORM,
    enableReinitialize: true,
    validate: formValidators.createValidator({
        name: formValidators.required,
        email: formValidators.email,
        verifierEmail: [formValidators.email, formValidators.required]
    })
})(StyledUpdateProviderForm);