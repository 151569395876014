import React from 'react';
import {Provider} from 'react-redux';
import ThemeProvider from './ThemeProvider';
import Router from './Router';
import CloudinaryProvider from "./CloudinaryProvider";
import store from './store'
import 'typeface-roboto';
import CssBaseline from '@material-ui/core/CssBaseline';

export default () => (
    <React.Fragment>
        <CssBaseline/>
        <ThemeProvider>
            <CloudinaryProvider>
                <Provider store={store}>
                    <Router/>
                </Provider>
            </CloudinaryProvider>
        </ThemeProvider>
    </React.Fragment>
);
