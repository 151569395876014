import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types/prop-types";
import AddPaymentMethodFormContainer from "../containers/AddPaymentMethodFormContainer";

class AddPaymentMethodDialog extends React.Component {
    render() {

        const {isOpen, handleClose} = this.props;
        return (
            <div>
                <Dialog
                    fullWidth
                    maxWidth={'sm'}
                    open={isOpen}
                    onClose={handleClose}
                    aria-labelledby="add-payment-method-dialog-title"
                    aria-describedby="add-payment-method-dialog-description"
                >
                    <DialogTitle id="add-payment-method-dialog-title">Add Payment Method</DialogTitle>
                    <DialogContent>
                        <AddPaymentMethodFormContainer onClose={handleClose} />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

AddPaymentMethodDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default AddPaymentMethodDialog;
