import React from 'react';
import { withRouter } from 'react-router-dom'
import {connect} from 'react-redux';
import ResetPasswordForm from '../components/ResetPasswordForm';
import {loadReset, resetPassword} from '../actions';
import { mapResetPasswordToProps } from '../selectors';

class ResetPasswordFormWrapper extends React.Component {
    componentDidMount() {
        const {loadReset, match: {params: {confirm}}} = this.props;
        loadReset({token: confirm})
    }

    render() {
        const {...rest} = this.props;
        return ( <ResetPasswordForm { ...rest} /> )
    }
}

export const ResetPasswordFormContainer = connect(mapResetPasswordToProps, {
    loadReset,
    onSubmit: resetPassword})(ResetPasswordFormWrapper);

export default withRouter(ResetPasswordFormContainer);