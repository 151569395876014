import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {Image, Transformation} from "cloudinary-react";
import {withStyles} from "@material-ui/core";
import classNames from "classnames";

const styles = () => ({
    image: {
        // width: '100%'
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    preview: {
        border: '4px dashed #ccc',
        cursor: 'pointer',
        minHeight: 100,
        textAlign: 'center',
        maxWidth: 400,
        margin: 'auto'
    }
});
//todo determine how to refactor the Placeholder component
const Placeholder = (props) => {
    const { getInputProps, getRootProps, error, touched, classes, imageFile, defaultImage } = props;
    return (
    <div
        {...getRootProps()}
        className={classNames(`placeholder-preview ${error && touched ? 'has-error' : ''}`,classes.preview)}
    >
        <input {...getInputProps()} />
        <Image
            publicId={!!imageFile ? imageFile : defaultImage}
            fetch-format="auto"
            quality="auto"
            className={classes.image}
        >
            <Transformation opacity="40"/>
        </Image>
        <Typography variant={"h5"} gutterBottom >
            Click or drag image file to this area to upload.
        </Typography>
    </div>
)
};

Placeholder.propTypes = {
    error: PropTypes.string,
    getInputProps: PropTypes.func.isRequired,
    getRootProps: PropTypes.func.isRequired,
    touched: PropTypes.bool,
    defaultImage: PropTypes.string
};

export default withStyles(styles)(Placeholder);
