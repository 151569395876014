import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux';
import Form from '../components/UpdateOrganizationForm';
import {updateOrganization} from '../actions';
import {loadDashboard} from "../../dashboard/actions";
import {mapUpdateOrgToProps} from '../selectors';
import React from "react";

class Wrapper extends React.Component {
    componentDidMount() {
        const {loadDashboard, token, history, exists} = this.props;
        if(!exists) {
            loadDashboard({token, history})
        }
    }

    render() {
        const {...rest} = this.props;

        return ( <Form { ...rest} /> )
    }
}

export const Container = connect(mapUpdateOrgToProps, {onSubmit: updateOrganization, loadDashboard})(Wrapper);

export default withRouter(Container);