import React from 'react';
import PropTypes from 'prop-types/prop-types';
import {withStyles} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EnhancedTableHead from "../../components/EnhancedTableHead";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Link from "../../components/RouterLink";
import {Redirect} from 'react-router-dom';
import {AddCredentialIcon, DeleteIcon} from '../../common/icons';
import {Image, Transformation} from "cloudinary-react";
import credentialStyles, {
    getStatusText,
    getStatusTitle,
    STATUS_COLOR,
    typeComparator,
    STATUS_ICON
} from "../../common/credential-styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import ProviderText from "../../components/ProviderText";
import StatusIcon from "../../components/StatusIcon";
import classNames from "classnames";
import {expirationFormatter} from '../../utils/fieldFormatter';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    const sortFunc = orderBy === 'type' ? typeComparator : desc;
    return order === 'desc' ? (a, b) => sortFunc(a, b, orderBy) : (a, b) => -sortFunc(a, b, orderBy);
}

const styles = theme => {
    const cStyles = credentialStyles(theme);
    return ({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            minWidth: 700,
            padding: 'none'
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        clickable: {
            cursor: 'pointer'
        },
        tableRow: {
            backgroundColor: 'wheat'
        },
        center: {
            textAlign: 'center'
        },
        row: {
            cursor: 'pointer'
        },
        ...cStyles
    })
};

const rows = [
    {id: 'view', label: 'View', align: 'center'},
    {id: 'status', label: 'Status'},
    {id: 'type', label: 'Type', align: 'center'},
    {id: 'title', label: 'Credential Title'},
    {id: 'memberName', label: 'Member'},
    {id: 'providerName', label: 'Provider'},
    {id: 'dateExpires', label: 'Expires', align: 'center'}
];

class CredentialList extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'type',
        page: 0,
        rowsPerPage: 5,
        selectedId: ''
    };

    componentDidMount() {
        const {
            loadCredentials, token,
            // exists,
            membersExist, loadDashboard
        } = this.props;
        // if(!exists) {
        loadCredentials({token})
            .then(() => {
                if (!membersExist) {
                    loadDashboard({token})
                }
            })
        // }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };

    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    handleRowSelected = (event, n) => {
        const {_id} = n;
        this.props.selectCredential(event, n);

        this.setState({selectedId: _id});

    };

    render() {
        const {classes, credentials: dataRows, active, progress, openDialog} = this.props;
        const rowLength = dataRows.length;
        const {order, orderBy, rowsPerPage, page, selectedId} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowLength - page * rowsPerPage);

        if (!!selectedId) {
            return <Redirect to={`/app/credentials/${selectedId}`}/>
        }

        return (
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            rows={rows}
                            firstColumnName={'view'}
                        >
                            ({active})
                            <Tooltip title="Add Credential">
                                <IconButton aria-label="Add Credential" component={Link} to={'credentials/add'}>
                                    <AddCredentialIcon/>
                                </IconButton>
                            </Tooltip>
                        </EnhancedTableHead>
                        <TableBody>
                            {!!progress && <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={'pending'}>
                                <TableCell colSpan={7} component="th" scope="row">
                                    <LinearProgress variant="determinate" value={progress}/>
                                </TableCell>
                            </TableRow>}
                            {stableSort(dataRows, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(n => {
                                    return (
                                        <TableRow
                                            hover
                                            role="button"
                                            tabIndex={-1}
                                            key={n._id}
                                        >
                                            <TableCell component={"th"} scope={"row"}
                                                       className={classes.row}
                                                       onClick={(event) => this.handleRowSelected(event, n)}
                                            >
                                                <Image publicId={n.credentialImage} fetch-format="auto" quality="auto"
                                                       className={classes[n.status]}>
                                                    <Transformation crop={'thumb'} width={42}/>
                                                </Image>
                                            </TableCell>
                                            <TableCell padding="none" style={{color: STATUS_COLOR[n.status]}}
                                                       className={classes.row}
                                                       onClick={(event) => this.handleRowSelected(event, n)}
                                            >
                                                <Tooltip
                                                    title={getStatusTitle(n.status, n.rejectReason, n.dateStatused)}>
                                                    <span>
                                                            <StatusIcon icon={STATUS_ICON[n.status]}
                                                                        style={{fontSize: 12}}/> {getStatusText(n.status)}
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell padding="none"
                                                       className={classNames(classes.center, classes.row)}
                                                       onClick={(event) => this.handleRowSelected(event, n)}
                                            ><span className={classes[n.type.toLowerCase()]}>{n.type}</span></TableCell>
                                            <TableCell padding="none"
                                                       className={classes.row}
                                                       onClick={(event) => this.handleRowSelected(event, n)}
                                            >{n.title}</TableCell>
                                            <TableCell padding="none"
                                                       className={classes.row}
                                                       onClick={(event) => this.handleRowSelected(event, n)}
                                            >{n.memberName}</TableCell>
                                            <TableCell padding="none"
                                                       className={classes.row}
                                                       onClick={(event) => this.handleRowSelected(event, n)}
                                            ><ProviderText name={n.providerName} status={n.providerStatus}/></TableCell>
                                            <TableCell padding="none"
                                                       className={classes.center}>{expirationFormatter(n.dateExpires)}</TableCell>
                                            <TableCell padding="none">
                                                <Tooltip title="Delete">
                                                    <IconButton aria-label="Delete"
                                                                onClick={event => openDialog(event, n)}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{height: 49 * emptyRows}} key={'empty'}>
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rowLength}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

CredentialList.propTypes = {
    loadCredentials: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    credentials: PropTypes.array.isRequired,
    active: PropTypes.number.isRequired,
    openDialog: PropTypes.func.isRequired,
    selectCredential: PropTypes.func.isRequired
};

export default withStyles(styles)(CredentialList);
