import React from 'react';
import ResetPasswordFormContainer from '../containers/ResetPasswordFormContainer';
import Grid from "@material-ui/core/Grid";
import AuthPaper from './AuthPaper';

export const ResetPassword = () => {
    return (
        <div>
            <AuthPaper>
                <Grid item>
                    <ResetPasswordFormContainer/>
                </Grid>
            </AuthPaper>
        </div>
    );
};

export default ResetPassword;