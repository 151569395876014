import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from 'react-router-dom'
import Link from "@material-ui/core/Link";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import React from "react";

const styles = () => ({
    root: {
        margin: 'auto',
    }
});

function DenseAppBar(props) {
    const { classes } = props;
    return (
        <div>
            <AppBar position="static">
                <Toolbar variant="regular">
                    <Typography className={classes.root} color="inherit">
                        No Account? <Link component={RouterLink} color="inherit" to={`/auth/signup`}>Sign up now</Link>
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
}

DenseAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DenseAppBar);