import React from 'react';
import PropTypes from 'prop-types/prop-types';
import {withStyles} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EnhancedTableHead from "../../components/EnhancedTableHead";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Link from "../../components/RouterLink";
import {Redirect} from 'react-router-dom';
import {AddCodeIcon, DeleteIcon} from '../../common/icons';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    table: {
        minWidth: 250,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    clickable: {
        cursor: 'pointer'
    },
    row: {
        cursor: 'pointer'
    },
});

const rows = [
    // { id: 'copy', label: ''},
    {id: 'type', label: 'QRedentialed As'},
    {id: 'name', label: 'Profiles Assigned'},
    {id: 'nickname', label: 'Nick Name'}
];

class ContentCodeList extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'nickname',
        page: 0,
        rowsPerPage: 5,
        selectedId: ''
    };

    componentDidMount() {
        const {loadDashboard, token, exists} = this.props;
        if (!exists) {
            loadDashboard({token})
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };

    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    handleRowSelected = (event, n) => {
        const {code} = n;
        this.props.selectContentCode(event, n);

        this.setState({selectedId: code});
    };

    render() {
        const {
            classes, contentCodes: dataRows, actOfAvail, available, activated
            , openDialog
        } = this.props;

        const remaining = available - activated;
        const rowLength = dataRows.length;
        const {order, orderBy, rowsPerPage, page, selectedId} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowLength - page * rowsPerPage);

        if (!!selectedId) {
            return <Redirect to={`/app/qrCodes/${selectedId}`}/>
        }

        return (
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            rows={rows}
                            firstColumnName={'type'}
                        >
                            {actOfAvail}
                            <Tooltip title="Add QR Code">
                                <IconButton aria-label="Add Member" component={Link} to={'qrCodes/add'}
                                            disabled={!!available ? remaining < 1 : true}>
                                    <AddCodeIcon/>
                                </IconButton>
                            </Tooltip>
                        </EnhancedTableHead>
                        <TableBody>
                            {stableSort(dataRows, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(n => {
                                    return (
                                        <TableRow
                                            hover
                                            role="button"
                                            tabIndex={-1}
                                            key={n.code}
                                            className={classes.row}
                                        >
                                            <TableCell
                                                onClick={(event) => this.handleRowSelected(event, n)}
                                            >{n.type}</TableCell>
                                            <TableCell padding="none"
                                                       onClick={(event) => this.handleRowSelected(event, n)}>
                                                {n.name.split('\r\n').map((item, key) => <span
                                                    key={key}>{item}<br/></span>)}
                                            </TableCell>
                                            <TableCell padding="none"
                                                       onClick={(event) => this.handleRowSelected(event, n)}>{n.nickname}</TableCell>
                                            <TableCell padding={'none'}>
                                                <Tooltip title="Delete">
                                                    <IconButton aria-label="Delete"
                                                                onClick={event => openDialog(event, n)}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{height: 49 * emptyRows}} key={'empty'}>
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rowLength}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

ContentCodeList.propTypes = {
    loadDashboard: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    contentCodes: PropTypes.array.isRequired,
    actOfAvail: PropTypes.string.isRequired,
    available: PropTypes.number.isRequired,
    selectContentCode: PropTypes.func.isRequired
};

export default withStyles(styles)(ContentCodeList);
