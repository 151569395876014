import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        width: '90%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const MultiSelect = (props) => {

        const { classes, input, label, meta: {touched, error}, options, disabled } = props;

        // const newInput = {...input, value: !!input.value ? input.value : []};
    const errorState = touched && error ? {error: true} : undefined;

        return (
                <FormControl className={classes.formControl} {...errorState} {...{disabled}}>
                    <InputLabel htmlFor={input.name}>{label}</InputLabel>
                    <Select
                        multiple
                        {...{...input, value: !!input.value ? input.value : []}}
                        renderValue={selected => options.filter((o) => selected
                            .includes(o.key))
                            .map((o) => o.value)
                            .join(', ')}
                        MenuProps={MenuProps}
                    >
                        {options.map(({key, value}) => (
                            <MenuItem key={key} value={key}>
                                <Checkbox checked={input.value.indexOf(key) > -1} />
                                <ListItemText primary={value} />
                            </MenuItem>
                        ))}
                    </Select>
                    {touched && error && <FormHelperText
                        id={`error-text-${input.name}`}>
                        {error}
                    </FormHelperText>}
                </FormControl>
        );
};

MultiSelect.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MultiSelect);
