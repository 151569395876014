import React, {useState} from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import Button from '../../components/StandardButton';
import Input from '../../components/Input';

export default function AddPaymentMethodForm({onClose, token, addPaymentMethod}) {
    const stripe = useStripe();
    const elements = useElements();

    const [isReady, setIsReady] = useState(false);
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [touched, setTouched] = useState(false);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                name
            },
        });

        if(!result.error) {

            // alert(JSON.stringify(result, null, 2))
            await addPaymentMethod({token, ...result});
            // stripePaymentMethodHandler(result);

            onClose(event);
        }
    };

    const handleReady = (isNotEmptyAndComplete) => {
        setIsReady(isNotEmptyAndComplete);
    };

    const handleChange = ({target: {value}}) => {
        setName(value);
        setTouched(true);
        setError( !value ? 'Required' : '')
    };

    const handleBlur = ({target: {value}}) => {
        setName(value);
        setTouched(true);
        setError( !value ? 'Required' : '')

    };

    return (
        <form onSubmit={handleSubmit}>
            <Input
                label={'Name'}
                placeholder={'Name on card'}
                type={'text'}
                meta={{touched, error}}
                input={{
                    name: 'name',
                    value: name,
                    onChange: handleChange,
                    onBlur: handleBlur
                }}


            />
            <CardSection handleReady={handleReady} />
            <Button disabled={!stripe || !isReady || !!error || !name}
                variant="contained" color="primary"
                type="submit"
            >
                Submit Payment Method
            </Button>
        </form>
    );
}