import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ProviderItem from "./ProviderItem";
import AddProviderDialog from "./AddProviderDialog";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {ProvidersIcon} from "../../common/icons";
import AlertDialog from '../../components/AlertDialog';
import {Redirect} from "react-router-dom";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
    },
});

class ProviderList extends React.Component {
    state = {
        isAddOpen: false,
        isDeleteOpen: false,
        // isUpdateOpen: false,
        providerToDelete: {}
    };

    componentDidMount() {
        const {loadProviders, token} = this.props;
        loadProviders({token})
    }

    getDialogProps = (props) => {
        const {name, _id} = props;
        return {
            title: `Delete Provider`,
            contentText: `The "${name}" provider will be deleted.`,
            _id: !!_id ? _id : ''
        }
    };

    handleAddDialogClose = () => {
        this.setState({isAddOpen: !this.state.isAddOpen});
    };

    handleOpen = () => {
        this.setState({isAddOpen: !this.state.isAddOpen})
    };

    handleDelete = (e, selectedProvider) => {
        this.setState({isDeleteOpen: !this.state.isDeleteOpen, providerToDelete: selectedProvider})
    };

    handleSelect = (e, payload) => {
        const {selectProvider} = this.props;

        return selectProvider(payload)
    }

    handleClose = async (e, providerId) => {
        if(!!providerId) {
            const {deleteProvider, token} = this.props;
            await deleteProvider({token, providerId})
        }
        this.setState({isDeleteOpen: !this.state.isDeleteOpen})
    };

    render() {
        const {classes, providers, selectedId} = this.props;

        if(!!selectedId){
            return <Redirect to={`/app/providers/${selectedId}`} />
        }
        const {isAddOpen, isDeleteOpen} = this.state;

        const dialogProps = this.getDialogProps(this.state.providerToDelete);


        return (
            <div className={classes.root}>
                <Tooltip title="Add Provider">
                    <IconButton aria-label="Add Provider" onClick={this.handleOpen}>
                        <ProvidersIcon />
                    </IconButton>
                </Tooltip>
                <div>
                    <List dense>
                        {!!providers && providers.map((p, i) => <ProviderItem key={i} {...p} deleteProvider={this.handleDelete} handleSelect={this.handleSelect}/>)}
                    </List>
                </div>
                <AddProviderDialog {...{isOpen: isAddOpen, handleClose: this.handleAddDialogClose}} />
                <AlertDialog {...{isOpen: isDeleteOpen}} {...dialogProps}
                             handleClose={this.handleClose}/>
            </div>
        );
    }
}

ProviderList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProviderList);
