import {AUTH, PROFILE} from './actionTypes';
import {SIGN_UP_FORM, RESET_PASSWORD_FORM} from "./constants";
import db from '../utils/dbAccess';
import {reset, change, focus} from "../utils/redux-form";
import {states} from "../common/validation-styles";
import {handleError} from "../common/actions";

const {error, success} = states;

//sign in actions
const postAuthenticateRequest = () => {
    return ({
    type: AUTH.POST.REQUEST
})};

const postAuthenticateSuccess = ({payload}) => ({
    type: AUTH.POST.SUCCESS,
    payload
});

const postAuthenticateFailure = (message) => ({
    type: AUTH.POST.FAILURE,
    payload: {msgState: error, message}
});

export const postAuthenticate = (req) => (dispatch) => {
    dispatch(postAuthenticateRequest());

    return db.postAuthenticate(req)
        .then(({data}) => {
            return dispatch(postAuthenticateSuccess(data));
        })
        .catch((e) => dispatch(handleError(e, postAuthenticateFailure)))
};

// sign up actions
const checkEmailRequest = () => ({
    type: AUTH.GET.REQUEST
});

const checkEmailSuccess = () => ({
    type: AUTH.GET.SUCCESS
});

//we don't want any message payload here.
const checkEmailFailure = () => ({
    type: AUTH.GET.FAILURE,
});

//asyncValidate function for SignUpForm (must return a Promise)
export const checkEmail = ({email} , dispatch) => {
    dispatch(checkEmailRequest());

    return db.checkEmail({email})
        .then(({data:{count}}) => new Promise((resolve, reject) => {
                if(count !== 0) {
                    dispatch(checkEmailFailure());
                    return reject({email: 'User already exists.'})
                }
                return resolve(dispatch(checkEmailSuccess()));
            }))
        //we don't want a catch here we want to return a rejected promise
};

export const clearEmail = () => (dispatch) => {
    return Promise.resolve(dispatch(change(SIGN_UP_FORM, 'email', '')))
        .then(dispatch(focus(SIGN_UP_FORM, 'email')));
};

const createProfileSuccess = (addrs) => ({
    type: PROFILE.POST.SUCCESS,
    payload: {msgState: success, message: 'Profile created', ...addrs}
});

const createProfileFailure = (message) => ({
    type: PROFILE.POST.FAILURE,
    payload: {msgState: error, message}
});


const createProfileRequest = () => ({
    type: PROFILE.POST.REQUEST
});

export const createProfile = (opts) => (dispatch) => {
    dispatch(createProfileRequest());

    return db.postProfile(opts)
        .then(({data: {payload}}) => {
            return dispatch(createProfileSuccess(payload))
        })
        .catch((e) => dispatch(handleError(e, createProfileFailure)))
};

//forgot password actions
const authenticateEmailSuccess = (email) => ({
    type: AUTH.EMAIL.SUCCESS,
    payload: {msgState: success, message: 'Check your email.', email}
});

const authenticateEmailFailure = (message) => ({
    type: AUTH.EMAIL.FAILURE,
    payload: {msgState: error, message}
});


const authenticateEmailRequest = () => ({
    type: AUTH.EMAIL.REQUEST
});

export const authenticateEmail = ({email}) => (dispatch) => {
    dispatch(authenticateEmailRequest());
    return db.authenticateEmail({email})
        .then(({data}) => {
            const {email} = data;
            return dispatch(authenticateEmailSuccess(email))
        })
        .catch((e) => dispatch(handleError(e, authenticateEmailFailure)))
};

//reset password actions
const loadResetRequest = () => ({
    type: AUTH.RESET.REQUEST
});

const loadResetFailure = (message) => ({
    type: AUTH.RESET.FAILURE,
    payload: {msgState: error, message}
});

const loadResetSuccess = (payload) => ({
    type: AUTH.RESET.SUCCESS,
    payload

});

export const loadReset = (opts) => (dispatch) => {
    dispatch(loadResetRequest());

    return db.getUserByToken(opts)
        .then(({data:{payload}}) => {
            return dispatch(loadResetSuccess(payload))
        })
        .catch((e) => dispatch(handleError(e, loadResetFailure)))
};

const resetPasswordSuccess = () => ({
    type: AUTH.PWD.SUCCESS,
    payload: {msgState: success, message: 'Password successfully changed.'}
});

const resetPasswordFailure = (message) => ({
    type: AUTH.PWD.FAILURE,
    payload: {msgState: error, message}
});


const resetPasswordRequest = () => ({
    type: AUTH.PWD.REQUEST
});

export const resetPassword = ({password, token}) => (dispatch) => {
    dispatch(resetPasswordRequest());

    return db.updateProfile({password, token})
        .then(() => dispatch(resetPasswordSuccess()))
        .then(() => dispatch(reset(RESET_PASSWORD_FORM)))
        .catch((e) => dispatch(handleError(e, resetPasswordFailure)))
};

//confirmEmail actions
const loadConfirmEmailRequest = () => ({
    type: AUTH.CONFIRM_EMAIL.REQUEST
});

const loadConfirmEmailFailure = (message) => ({
    type: AUTH.CONFIRM_EMAIL.FAILURE,
    payload: {msgState: error, message}
});

const loadConfirmEmailSuccess = () => ({
    type: AUTH.CONFIRM_EMAIL.SUCCESS,
    payload: {msgState: success, message: 'Email is confirmed.'}
});

export const loadConfirmEmail = ({token, status}) => (dispatch) => {
    dispatch(loadConfirmEmailRequest());

    return db.confirmEmailByToken({token, status})
        .then(() => dispatch(loadConfirmEmailSuccess()))
        .catch((e) => dispatch(handleError(e, loadConfirmEmailFailure)))
};

export const tryNewEmailAddress = () => ({
    type: AUTH.NEW
});

export const confirmVerifier = (opts) => (dispatch) => {
    const confirmVerifierSuccess = () => ({
        type: AUTH.VERIFIER.SUCCESS,
        payload: {msgState: success, message: 'Verifier status confirmed.'}
    });

    const confirmVerifierFailure = (message) => ({
        type: AUTH.VERIFIER.FAILURE,
        payload: {msgState: error, message}
    });

    const confirmVerifierRequest = () => ({
        type: AUTH.VERIFIER.REQUEST
    });
    
    dispatch(confirmVerifierRequest());

    return db.updateProfile(opts)
        .then(() => dispatch(confirmVerifierSuccess()))
        .then(() => dispatch(reset(RESET_PASSWORD_FORM)))
        .catch((e) => dispatch(handleError(e, confirmVerifierFailure)))
};