import React from 'react';
import PropTypes from 'prop-types/prop-types';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EnhancedTableHead from "../../components/EnhancedTableHead";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {phoneFormatter} from "../../utils/fieldFormatter";
import LinearProgress from '@material-ui/core/LinearProgress';
import {Link, Redirect} from 'react-router-dom';
import {AddMemberIcon, DeleteIcon, AddCredentialIcon} from '../../common/icons';

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    row: {
        cursor: 'pointer'
    }
});

const rows = [
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'email', label: 'Email Address' },
    { id: 'mobilePhone', label: 'Mobile' }
];

class MemberList extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'email',
        page: 0,
        rowsPerPage: 5,
        selectedId: '',
        anchorEl: null,
    };

    componentDidMount() {
        const {loadDashboard, token, exists} = this.props;
        if(!exists) {
            loadDashboard({token})
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleRowSelected = (event, n) => {
        const {_id: memberId} = n;
        this.props.selectMember(event, n);

        this.setState({selectedId: memberId});
    };

    render() {
        const { classes, members, actOfAvail, available, progress, openDialog, activated} = this.props;
        const dataRows = !!members ? members : [];
        const rowLength = dataRows.length;
        const { order, orderBy, rowsPerPage, page, selectedId } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowLength- page * rowsPerPage);
        const remaining = available - activated;

        if(!!selectedId){
            return <Redirect to={`/app/members/${selectedId}`} />
        }

        return (
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                            rows={rows}
                            firstColumnName={'firstName'}
                        >
                            {actOfAvail}
                            <Tooltip title="Add Member">
                                <IconButton aria-label="Add Member" component={Link} to={'members/add'} disabled={!!available ? remaining < 1 : true}>
                                    <AddMemberIcon />
                                </IconButton>
                            </Tooltip>
                        </EnhancedTableHead>
                        <TableBody>
                            { !!progress && <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={'pending'}>
                                <TableCell colSpan={5} component="th" scope="row" >
                                    <LinearProgress variant="determinate" value={progress} />
                                </TableCell>
                            </TableRow> }
                            {stableSort(dataRows, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(n => {
                                    return (
                                        <TableRow
                                            hover
                                            // role="checkbox"
                                            tabIndex={-1}
                                            key={n._id}
                                        >
                                            <TableCell component="th" scope="row"
                                                       className={classes.row}
                                                       onClick={(event) => this.handleRowSelected(event, n)}
                                            >{n.firstName}</TableCell>
                                            <TableCell padding="none"
                                                       className={classes.row}
                                                       onClick={(event) => this.handleRowSelected(event, n)}
                                            >{n.lastName}</TableCell>
                                            <TableCell padding="none"
                                                       className={classes.row}
                                                       onClick={(event) => this.handleRowSelected(event, n)}
                                            >{n.email}</TableCell>
                                            <TableCell padding="none"
                                                       className={classes.row}
                                                       onClick={(event) => this.handleRowSelected(event, n)}
                                            >{phoneFormatter(n.mobilePhone)}</TableCell>
                                            <TableCell padding="none">
                                                <Tooltip title="Add Credential" >
                                                    <IconButton aria-label="Add Credential"  component={Link} to={`credentials/add/${n._id}`}>
                                                        <AddCredentialIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete" >
                                                    <IconButton aria-label="Delete" onClick={event => openDialog(event, n)}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }} key={'empty'}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rowLength}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

MemberList.propTypes = {
    loadDashboard: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    members: PropTypes.array.isRequired,
    actOfAvail: PropTypes.string.isRequired,
    available: PropTypes.number.isRequired,
    progress: PropTypes.number,
    openDialog: PropTypes.func.isRequired,
    selectMember: PropTypes.func.isRequired
};

export default withStyles(styles)(MemberList);
