import Navigation from "../../components/ConnectedNavigation";
import Typography from "@material-ui/core/Typography";
import UpdateCodeFormContainer from "../containers/UpdateCodeFormContainer";
import React from "react";
import PreviewDialogContainer from "../containers/PreviewDialogContainer";

export class UpdateCode extends React.Component {
    state = {
        open: false,
        code: ''
    };

    handleClickOpen = (code) => {
        this.setState({open: true, code});
    };

    handleCloseDialog = () => {
        this.setState({open: false, code: ''});
    };

    render() {

        return (
            <Navigation title={'Dynamic QR Codes'}>
                <Typography variant={"h4"} gutterBottom>
                    Update QR-Code
                </Typography>
                <UpdateCodeFormContainer handlePreview={this.handleClickOpen} />
                <PreviewDialogContainer
                    open={this.state.open}
                    code={this.state.code}
                    handleClose={this.handleCloseDialog}
                />
            </Navigation>
        )
    }
}

export default UpdateCode;