import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default ({children, ...rest}) => (
    <Grid item {...rest}>
        <Typography component={'span'} variant={'body2'} color={"textSecondary"}>
            {children}
        </Typography>
    </Grid>
)