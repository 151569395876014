import statusColor from "./statusColors";
import {EducatorIcon, PreferredIcon, RejectedIcon, VerifiedIcon, VerifyingIcon} from "./icons";

const PROVIDER_STATUS = {
    PENDING: 'Pending',
    EXISTS: 'Exists',
    NONEXISTENT: 'Nonexistent',
    CONFIRMED: 'Confirmed',
    VERIFIER: 'Verifier',
    PREFERRED: 'Preferred'
};

export const STATUS_ICON = {
    [PROVIDER_STATUS.PENDING]: VerifyingIcon,
    [PROVIDER_STATUS.EXISTS]: EducatorIcon,
    [PROVIDER_STATUS.NONEXISTENT]: RejectedIcon,
    [PROVIDER_STATUS.CONFIRMED]: EducatorIcon,
    [PROVIDER_STATUS.VERIFIER]: VerifiedIcon,
    [PROVIDER_STATUS.PREFERRED]: PreferredIcon
};

const getWeight = (status) => {
    switch (status) {
        case PROVIDER_STATUS.EXISTS:
        case PROVIDER_STATUS.NONEXISTENT:
        case PROVIDER_STATUS.CONFIRMED:
        case PROVIDER_STATUS.PREFERRED:
            return 600;
        default: return 'inherit'
    }
};

const getStyle = (status) => {
    switch (status) {
        case PROVIDER_STATUS.PREFERRED:
            return 'italic';
        default: return 'inherit'
    }
};

const getColor = (status) => {
    switch (status) {
        case PROVIDER_STATUS.EXISTS:
        case PROVIDER_STATUS.NONEXISTENT:
        case PROVIDER_STATUS.CONFIRMED:
            return statusColor.rejected;
        case PROVIDER_STATUS.VERIFIER:
        case PROVIDER_STATUS.PREFERRED:
            return statusColor.verified;
        default: return statusColor.educator
    }
};

const PROVIDER_STATUS_TEXT = {
    [PROVIDER_STATUS.PENDING]: 'New, we are confirming this provider exists.',
    [PROVIDER_STATUS.EXISTS]: 'We have confirmed this provider exists.',
    [PROVIDER_STATUS.NONEXISTENT]: 'We have not been able to find this provider.',
    [PROVIDER_STATUS.CONFIRMED]: 'The provider has responded to us but they don’t verify credentials.',
    [PROVIDER_STATUS.VERIFIER]: 'The provider has responded to us and they do verify credentials.',
    [PROVIDER_STATUS.PREFERRED]: 'This is a preferred system-to-system integration partner'
};

export const getStatusList = () => Object.keys(PROVIDER_STATUS).map((k) =>({key: PROVIDER_STATUS[k], value: PROVIDER_STATUS[k]}));

export {PROVIDER_STATUS_TEXT, getColor, getStyle, getWeight, PROVIDER_STATUS}