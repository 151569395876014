import {withStyles} from "@material-ui/core";
import GridList from "@material-ui/core/GridList";
import FlipCard from "./FlipCard";
import React from "react";

const styles = () => {
    return ({
        gridList: {
            maxWidth: 600,
        }
    })
};

const CredentialList = (props) => {
    const {classes, credentials, showElements} = props;
    return(

        <div>
            <GridList className={classes.gridList}>
                {credentials.filter((c) => showElements.credentials.includes(c._id)).map((c) => <FlipCard key={c._id} {...c} />)}
            </GridList>
        </div>
    )
};

export default withStyles (styles)(CredentialList);