import React from 'react';
import PropTypes from 'prop-types/prop-types';
import {reduxForm, Field, Form} from '../../utils/redux-form';
import * as formValidators from '../../utils/formValidation';
import Grid from "@material-ui/core/Grid";
import {UPDATE_MEMBER_FORM} from '../constants';
import Input from '../../components/Input';
import Button from '../../components/StandardButton';
import {phoneParser} from '../../utils/fieldParser';
import {phoneFormatter} from '../../utils/fieldFormatter';
import {Link, Redirect} from 'react-router-dom'
import lowercaseParser from "../../authorize/lowercaseParser";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from "@material-ui/core/Tooltip";
import {CodeListIcon, ContentPreviewIcon} from "../../common/icons";
import IconButton from "@material-ui/core/IconButton";
import {withStyles} from "@material-ui/core";

const styles = (theme) => ({
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
});

export class UpdateMemberForm extends React.Component {
    render() {
        const {classes, handleSubmit, handleClickOpen, pristine, invalid, submitting, isAssociated, isEmpty, openDialog, isAssigned} = this.props;

        if (isEmpty || submitting) {
            return <Redirect to={'/app/members'}/>
        }

        return (
            <Form onSubmit={handleSubmit}>
                <Grid item container
                      direction='row'
                      justify="space-between"
                      alignItems="center"
                >
                    <Grid item>
                        <Tooltip title={'View QR Code List'}>
                            <span>
                            <Button
                                variant="outlined" size="small"
                                aria-label="Assign"
                                to={'/app/qrCodes'}
                                component={Link}
                                disabled={!(pristine || invalid)}
                                color={isAssigned ? 'secondary' : 'primary'}>
                                <CodeListIcon className={classes.extendedIcon}/>
                                {isAssigned ? 'Assigned' : 'Assign'}
                            </Button>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Preview Member Content">
                            <IconButton
                                aria-label="Preview Member Content"
                                onClick={handleClickOpen}
                                disabled={!(pristine || invalid)}
                            >
                                <ContentPreviewIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isAssociated}
                                onChange={(e) => openDialog(e, this.props.initialValues)}
                                value="checkedA"
                                disabled={!isAssociated}
                            />
                        }
                        label="Use Profile"
                    />
                </Grid>
                <Grid item>
                    <Field
                        name="token"
                        component="input"
                        type="hidden"
                    />
                    <Field
                        name="memberId"
                        component="input"
                        type="hidden"
                    />
                    <Field
                        name="profileId"
                        component="input"
                        type="hidden"
                    />
                    <Field
                        name="email"
                        component={Input}
                        parse={lowercaseParser}
                        type="text"
                        label="Email Address"
                        placeholder={'Email Address'}
                        isDisabled={isAssociated}
                    />
                </Grid>
                <Grid item>
                    <Field
                        name="firstName"
                        component={Input}
                        type="text"
                        label="First Name"
                        placeholder={'First Name'}
                        isDisabled={isAssociated}
                    />
                </Grid>
                <Grid item>
                    <Field
                        name="lastName"
                        component={Input}
                        type="text"
                        label="Last Name"
                        placeholder={'Last Name'}
                        isDisabled={isAssociated}
                    />
                </Grid>
                <Grid item>
                    <Field
                        name="mobilePhone"
                        component={Input}
                        type="text"
                        label="Mobile Phone"
                        placeholder={'Mobile Phone'}
                        format={phoneFormatter}
                        parse={phoneParser}
                        isDisabled={isAssociated}
                    />
                </Grid>
                <Grid item>
                    <Button
                        to={'../members'}
                        component={Link}
                        variant={'outlined'}
                        color={'default'}
                        type="button">
                        Cancel
                    </Button>
                    <Button
                        disabled={pristine || invalid}
                        type="submit">
                        Update
                    </Button>
                </Grid>
            </Form>
        )
    }
}

UpdateMemberForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    isAssociated: PropTypes.bool.isRequired,
    isEmpty: PropTypes.bool.isRequired,
    openDialog: PropTypes.func.isRequired
};

const StyledUpdateMemberForm = withStyles(styles)(UpdateMemberForm);

export default reduxForm({
    form: UPDATE_MEMBER_FORM,
    // enableReinitialize: true,
    validate: formValidators.createValidator({
        email: [formValidators.required, formValidators.email],
        firstName: formValidators.required,
        lastName: formValidators.required,
        mobilePhone: formValidators.required
    },)
})(StyledUpdateMemberForm);