import {combineReducers} from 'redux'
import {SIGN_OUT, ORGANIZATION} from './actionTypes';
import {ACCOUNT, CONTENT_CODES} from "../common/actionTypes";
import {head, curry} from 'ramda';

const updateAvailable = (availableState, action) => {
    const getQuantity = ({quantity})=> quantity;
    const filterIt = curry((product, i) => i.nickname === product);
    const members = head(action.payload.items.filter(filterIt('monthlyMember')).map(getQuantity));
    const qrCodes = head(action.payload.items.filter(filterIt('monthlyQRCode')).map(getQuantity));
    return {...availableState, members, qrCodes}
};

const available = (availableState = {}, action) => {
    switch (action.type) {
        case ORGANIZATION.DASHBOARD.SUCCESS:
            return action.payload.available;
        case ACCOUNT.POST.SUCCESS:
            return updateAvailable(availableState, action);
        case SIGN_OUT:
            return {};
        default: return availableState;
    }
};

const trialEnd = (trialEndState = null, action) => {
    switch (action.type){

        case ORGANIZATION.DASHBOARD.SUCCESS:
            return action.payload.trialEnd;
        case SIGN_OUT:
            return null;
        default: return trialEndState;
    }
};

const name = (nameState = '', action) => {
    switch (action.type) {
        case ORGANIZATION.DASHBOARD.SUCCESS:
            return action.payload.organization.name || '';
        case ORGANIZATION.POST.SUCCESS:
            return action.payload.organization.name;
        case SIGN_OUT:
            return '';
        default: return nameState;
    }
};

const phone = (phoneState = '', action) => {
    switch (action.type) {
        case ORGANIZATION.DASHBOARD.SUCCESS:
            return action.payload.organization.phone || '';
        case ORGANIZATION.POST.SUCCESS:
            return action.payload.organization.phone;
        case SIGN_OUT:
            return '';
        default: return phoneState;
    }
};

const website = (websiteState = '', action) => {
    switch (action.type) {
        case ORGANIZATION.DASHBOARD.SUCCESS:
            return action.payload.organization.website || '';
        case ORGANIZATION.POST.SUCCESS:
            return action.payload.organization.website;
        case SIGN_OUT:
            return '';
        default: return websiteState;
    }
};

const meta = (metaState = {}, action) => {
    switch (action.type) {
        case ORGANIZATION.DASHBOARD.SUCCESS:
            return action.payload.organization.meta || {} ;
        case ORGANIZATION.POST.SUCCESS:
            return action.payload.organization.meta;
        case SIGN_OUT:
            return {};
        default: return metaState;
    }
};

const isAssigned = (assignedState = false, action) => {
    switch (action.type) {
        case ORGANIZATION.DASHBOARD.SUCCESS:
            return action.payload.organization.isAssigned;
        case ORGANIZATION.POST.SUCCESS:
            return action.payload.organization.isAssigned;
        case CONTENT_CODES.PUT.SUCCESS:
            const {assignments} = action.payload;
            return assignments.includes('organization');
        case SIGN_OUT:
            return false;
        default: return assignedState;

    }
};

const progress = (progressState = null, action) => {
    switch (action.type) {
        case ORGANIZATION.POST.REQUEST:
            return 0;
        case ORGANIZATION.SHOW_PROGRESS:
            return action.percentCompleted;
        case ORGANIZATION.POST.SUCCESS:
        case ORGANIZATION.POST.FAILURE:
        case ORGANIZATION.UPDATE_IMAGE.SUCCESS:
        case ORGANIZATION.UPDATE_IMAGE.FAILURE:
        case SIGN_OUT:
            return null;
        default: return progressState;
    }
};

const logoImage = (logoImageState = '', action) => {
    switch (action.type) {
        case ORGANIZATION.UPDATE_IMAGE.SUCCESS:
            return action.payload.logoImage;
        case ORGANIZATION.DASHBOARD.SUCCESS:
            return action.payload.organization.logoImage || '';
        case SIGN_OUT:
            return '';
        default: return logoImageState;
    }
};

const addresses = (addressesState = [], action) => {
    switch (action.type) {
        case ORGANIZATION.DASHBOARD.SUCCESS:
            return action.payload.organization.addresses || [] ;
        case ORGANIZATION.POST.SUCCESS:
            return action.payload.organization.addresses;
        case SIGN_OUT:
            return [];
        default: return addressesState;
    }
};

const organizationReducers = {available, trialEnd, name, phone, website, meta, isAssigned, progress, logoImage, addresses};

//this export for testing
export const {
    available: availableReducer,
    trialEnd: trialEndReducer,
    name: nameReducer,
    phone: phoneReducer,
    website: websiteReducer,
    meta: metaReducer,
    isAssigned: isAssignedReducer,
    progress: progressReducer,
    logoImage: logoImageReducer
} = organizationReducers;

const reducer = combineReducers(organizationReducers);

export default reducer;