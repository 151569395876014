import React from 'react';
import PropTypes from 'prop-types/prop-types';
import {reduxForm, Field, Form} from '../../utils/redux-form';
import * as formValidators from '../../utils/formValidation';
import Grid from "@material-ui/core/Grid";
import {withStyles} from '@material-ui/core/styles';
import {ADD_MEMBER_FORM} from '../constants';
import Input from '../../components/Input';
import Button from '../../components/StandardButton';
import {phoneParser} from '../../utils/fieldParser';
import {phoneFormatter} from '../../utils/fieldFormatter';
import {Link, Redirect} from 'react-router-dom'
import lowercaseParser from "../../authorize/lowercaseParser";
import DropZoneField from "../../components/DropZoneField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const styles = (theme) => ({
    root: {
        minWidth: 250,
        [theme.breakpoints.up('sm')]: {
            minWidth: 400,
        }
    }
});

export class AddMemberForm extends React.Component {
    state = {
        imageFile: [],
        useProfile: false
    };

    handleOnDrop = ([newImageFile], onChange) => {
        const imageFile = {
            file: newImageFile,
            name: newImageFile.name,
            preview: URL.createObjectURL(newImageFile),
            size: newImageFile.size
        };

        this.setState({imageFile: [imageFile]}, () => onChange(imageFile));
    };

    useProfileChecked = () => {
        this.setState({useProfile: !this.state.useProfile});

        if (!this.state.useProfile) {
            this.props.onUseProfileChecked();
        }
    };

    render() {
        const {classes, handleSubmit, pristine, invalid, submitting, hasAssociated} = this.props;

        if (submitting) {
            return <Redirect to={'/app/members'}/>
        }

        return (
            <Form onSubmit={handleSubmit}>
                <Grid container
                      direction="column"
                      justify="flex-start"
                      alignItems="center"
                >
                    <Grid item>
                        <Grid container
                              direction="column"
                              justify="flex-start"
                              alignItems="stretch"
                              className={classes.root}
                        >
                            <Grid item >
                                <Field
                                    name="profileImage"
                                    component={DropZoneField}
                                    type="file"
                                    imageFile={this.state.imageFile}
                                    handleOnDrop={this.handleOnDrop}
                                    defaultImage={"profile_image_placeholder"}
                                />
                            </Grid>

                            <Grid item >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.useProfile}
                                            onChange={this.useProfileChecked}
                                            disabled={hasAssociated}
                                        />
                                    }
                                    label="Use Profile Data"
                                />
                                <Field
                                    name="token"
                                    component="input"
                                    type="hidden"
                                />
                                <Field
                                    name="profile"
                                    component="input"
                                    type="hidden"
                                />
                            </Grid>
                            <Grid item >
                                <Field
                                    name="email"
                                    component={Input}
                                    parse={lowercaseParser}
                                    type="text"
                                    label="Email Address"
                                    placeholder={'Email Address'}
                                    isDisabled={this.state.useProfile}
                                />
                            </Grid>
                            <Grid item >
                                <Field
                                    name="firstName"
                                    component={Input}
                                    type="text"
                                    label="First Name"
                                    placeholder={'First Name'}
                                    isDisabled={this.state.useProfile}
                                />
                            </Grid>
                            <Grid item >
                                <Field
                                    name="lastName"
                                    component={Input}
                                    type="text"
                                    label="Last Name"
                                    placeholder={'Last Name'}
                                    isDisabled={this.state.useProfile}
                                />
                            </Grid>
                            <Grid item >
                                <Field
                                    name="mobilePhone"
                                    component={Input}
                                    type="text"
                                    label="Mobile Phone"
                                    placeholder={'Mobile Phone'}
                                    format={phoneFormatter}
                                    parse={phoneParser}
                                    isDisabled={this.state.useProfile}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button
                            to={'../members'}
                            component={Link}
                            variant={'outlined'}
                            color={'default'}
                            type="button">
                            Cancel
                        </Button>
                        <Button
                            disabled={pristine || invalid}
                            type="submit">
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }
}

AddMemberForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onUseProfileChecked: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    pristine: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    hasAssociated: PropTypes.bool.isRequired
};

export const StyledAddMemberForm = withStyles(styles)(AddMemberForm);

export default reduxForm({
    form: ADD_MEMBER_FORM,
    enableReinitialize: true,
    validate: formValidators.createValidator({
        email: [formValidators.required, formValidators.email],
        firstName: formValidators.required,
        lastName: formValidators.required,
        mobilePhone: formValidators.required,
        profileImage: formValidators.imageIsRequired
    },)
})(StyledAddMemberForm);