import {Route, Switch} from 'react-router-dom';
import UpdateCode from './UpdateCode';
import AddCode from "./AddCode";
import React from "react";
import Content from '../containers/QRCodesContentContainer';


export default () => {
    return (
        <Switch>
            <Route path={'/app/qrCodes/add'} component={AddCode} />
            <Route path={'/app/qrCodes/:code'} component={UpdateCode} />
            <Route path={'/'} component={Content} />
        </Switch>
    )
}