import AddProviderDialog from './components/AddProviderDialog';
import Content from './components/ProvidersRouter';
import {getProviderListValues} from './selectors';

import reducer from './reducer';
import reducerRegistry from "../utils/reducerRegistry";
import {NAME} from "./constants";

reducerRegistry.register(NAME, reducer);

export {
    Content as default,
    AddProviderDialog,
    getProviderListValues
}