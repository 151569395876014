import Content from '../components/CredentialPreview';
import {hideCredential} from "../actions";
import {connect} from "react-redux";
import {mapHideUnhideToProps} from "../selectors";

const mergeProps = function({token}, {hideCredential}, props) {
    const {member} = props;
    return {
        ...props,
        handleHide: (credentialId) => {
            return hideCredential({token, member, credentialId})
        }
    }
};

export default connect(mapHideUnhideToProps, {hideCredential}, mergeProps)(Content);