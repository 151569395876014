import axios from './axios';
import {stringify} from 'querystring';
import {isEmpty} from 'ramda';

const getHeader = (token) => ({ headers: {'x-access-token': token}});

const getQuerystringFromRest = (rest) => !isEmpty(rest) ? `?${stringify(rest)}` : '';

export default {
    checkEmail: ({email}) => axios.get(`/authenticate?${stringify({email})}`),
    postProfile: (req) => axios.post(`/profiles`, req),
    postAuthenticate: (req) => axios.post(`/authenticate`, req),
    authenticateEmail: ({email}) => axios.post('/authenticate/forgotPassword', {email}),
    getUserByToken: (obj) => axios.get(`/profiles?${stringify(obj)}`),
    updateProfile: ({token, ...rest}) => axios.put('/profile', {...rest}, getHeader(token)),
    getProfile: ({token}) => axios.get(`/profile`, getHeader(token)),
    requestConfirm: ({token}) => axios.post('/authenticate/requestConfirm', null, getHeader(token)),
    confirmEmailByToken: ({token, status}) => axios.put(`/profiles?${stringify({token})}`, {status}),
    getDashboard: ({token}) => axios.get('/organization/dashboard', getHeader(token)),
    updateOrganization: ({token, ...rest}) => axios.post('/organization', {...rest}, getHeader(token)),
    deleteAccount: ({token}) => axios.delete('/profile', getHeader(token)),
    addMember: ({token, ...rest}) => axios.post('/members', rest, getHeader(token)),
    deleteMember: ({token, memberId}) => axios.delete(`/members/${memberId}`, getHeader(token)),
    updateMemberImage: ({token, memberId, profileImage}) => axios.post(`/members/${memberId}/profileImage`, {profileImage}, getHeader(token)),
    updateMember: ({token, memberId, ...rest}) => axios.post(`/members/${memberId}`, rest, getHeader(token)),
    updateContentCode: ({token, code, ...rest}) => axios.post(`/contentCodes/${code}`, rest, getHeader(token)),
    getCodedContent: ({token, code}) => axios.get(`/codedContent/${code}`, getHeader(token)),
    getCodesByMember: ({token, memberId}) => axios.get(`/members/${memberId}/codes`, getHeader(token)),
    updateLogo: ({token, logoImage}) => axios.post(`/organization/logo`, {logoImage}, getHeader(token)),
    addCredential: ({token, ...rest}) => axios.post('/credentials', rest, getHeader(token)),
    getCredentials: ({token, ...rest}) => axios.get(`/credentials${getQuerystringFromRest(rest)}`, getHeader(token)),
    getProviders: ({token}) => axios.get('/providers', getHeader(token)),
    deleteCredential: ({token, credentialId}) => axios.delete(`/credentials/${credentialId}`, getHeader(token)),
    updateCredential: ({token, credentialId, ...rest}) => axios.post(`/credentials/${credentialId}`, rest, getHeader(token)),
    updateCredentialImage: ({token, credentialId, credentialImage}) => axios.post(`/credentials/${credentialId}/credentialImage`, {credentialImage}, getHeader(token)),
    addProvider: ({token, ...rest}) => axios.post('/providers', rest, getHeader(token)),
    unHideCredential: ({token, member, credentialId}) => axios.post(`/member/${member}/unHideCredential`, {credentialId}, getHeader(token)),
    hideCredential: ({token, member, credentialId}) => axios.post(`/member/${member}/hideCredential`, {credentialId}, getHeader(token)),
    getUsers: ({token}) => axios.get('/users', getHeader(token)),
    patchUser: ({token, _id, ...rest}) => axios.patch(`/users/${_id}`, rest, getHeader(token)),
    deleteProvider: ({token, providerId}) => axios.delete(`/providers/${providerId}`, getHeader(token)),
    updateProvider: ({token, _id, ...rest}) => axios.patch(`/providers/${_id}`, rest, getHeader(token)),
    deDupeProvider: ({token, from, to}) => axios.patch(`/providers/${to}/dedupe`, {from}, getHeader(token)),
    loadAccount: ({token}) => axios.get('/account', getHeader(token)),
    updateAccount: ({token, ...rest}) => axios.post('/account', rest, getHeader(token)),
    addContentCode: ({token, ...rest}) => axios.post(`/contentCodes`, rest, getHeader(token)),
    deleteContentCode: ({token, code}) => axios.delete(`/contentCodes/${code}`, getHeader(token)),
    getPaymentMethods: ({token}) => axios.get('/paymentMethods', getHeader(token)),
    addPaymentMethod: ({token, ...rest}) => axios.post('/paymentMethods', rest, getHeader(token)),
    deletePaymentMethod: ({token, _id}) => axios.delete(`/paymentMethods/${_id}`, getHeader(token)),
    getReports: ({token}) => axios.get('/reports', getHeader(token)),
    getReportById: ({token, templateId, ...rest}) => axios.get(`/reports/${templateId}${getQuerystringFromRest(rest)}`, getHeader(token)),
    getCoupons: ({token}) => axios.get('/coupons', getHeader(token)),
    getSingleCredential: ({token, credentialId}) => axios.get(`/credentials/${credentialId}`, getHeader(token)),
    putCredential: ({token, credentialId, ...rest}) => axios.put(`/credentials/${credentialId}`, {...rest}, getHeader(token)),
    resendVerifierInvite: ({token, verifierEmail, name}) => axios.post('/providers/resendInvite', {verifierEmail, name}, getHeader(token)),
    stopVerifying: ({token}) => axios.patch('/profile/stopVerifying', {}, getHeader(token)),
    getAffectedProviders: ({token, verifier}) => axios.get(`/providers/affected${!!verifier ? `?${stringify({verifier})}` : ''}`, getHeader(token)),
}