import {red, yellow, green, blue, grey} from "@material-ui/core/colors"
import {VerifyingIcon, EducatorIcon, RejectedIcon, VerifiedIcon, PreferredIcon} from "./icons";
import credentialColor from './statusColors';


const TYPES = {
    SAFETY: 'Safety',
    SKILLS: 'Skills',
    MERIT: 'Merit',
    MEMBERSHIP: 'Membership',
    OTHER: 'Other'
};
const credentialTypes = Object.values(TYPES);

export const typeComparator = ( a, b ) => credentialTypes.indexOf(b.type) - credentialTypes.indexOf(a.type);

export const STATUS_ICON = {
    verifying_provider: VerifyingIcon,
    provider_exists: EducatorIcon,
    provider_not_found: RejectedIcon,
    provider_confirmed: EducatorIcon,
    provider_verifying: VerifyingIcon,
    provider_verified: VerifiedIcon,
    provider_rejected: RejectedIcon,
    preferred_verifying: VerifyingIcon,
    preferred_verified: PreferredIcon,
    preferred_rejected: RejectedIcon
};

export const STATUS_COLOR = {
    verifying_provider: credentialColor.verifying,
    provider_exists: credentialColor.educator,
    provider_not_found: credentialColor.rejected,
    provider_confirmed: credentialColor.educator,
    provider_verifying: credentialColor.verifying,
    provider_verified: credentialColor.verified,
    provider_rejected: credentialColor.rejected,
    preferred_verifying: credentialColor.verifying,
    preferred_verified: credentialColor.verified,
    preferred_rejected: credentialColor.rejected
};

export default () => ({
    safety: {
        backgroundColor: red[100],
    },
    skills: {
        backgroundColor: yellow[100]
    },
    merit: {
        backgroundColor: green[100],
    },
    membership: {
        backgroundColor: blue[100],
    },
    other: {
        backgroundColor: grey[100],
    },
    verifying_provider: {
        border: 3,
        borderColor: STATUS_COLOR.verifying_provider,
        borderStyle: 'solid'
    },
    provider_exists: {
        border: 3,
        borderColor: STATUS_COLOR.provider_exists,
        borderStyle: 'solid'
    },
    provider_not_found: {
        border: 3,
        borderColor: STATUS_COLOR.provider_not_found,
        borderStyle: 'solid'
    },
    provider_confirmed: {
        border: 3,
        borderColor: STATUS_COLOR.provider_confirmed,
        borderStyle: 'solid'
    },
    provider_verifying: {
        border: 3,
        borderColor: STATUS_COLOR.provider_verifying,
        borderStyle: 'solid'
    },
    provider_verified: {
        border: 3,
        borderColor: STATUS_COLOR.provider_verified,
        borderStyle: 'solid'
    },
    provider_rejected: {
        border: 3,
        borderColor: STATUS_COLOR.provider_rejected,
        borderStyle: 'solid'
    },
    preferred_verifying: {
        border: 3,
        borderColor: STATUS_COLOR.preferred_verifying,
        borderStyle: 'solid'
    },
    preferred_verified: {
        border: 3,
        borderColor: STATUS_COLOR.preferred_verified,
        borderStyle: 'solid'
    },
    preferred_rejected: {
        border: 3,
        borderColor: STATUS_COLOR.preferred_rejected,
        borderStyle: 'solid'
    }
});

const STATUS_TEXT = {
    verifying_provider: 'Contacting Provider',
    provider_exists: 'Provider Contacted',
    provider_not_found: 'Provider Not Found',
    provider_confirmed: 'Provider Unable to Verify',
    provider_verifying: 'Provider Verifying',
    provider_verified: 'Verified',
    provider_rejected: 'Provider Rejected',
    preferred_verifying: 'Provider Verifying',
    preferred_verified: 'Verified',
    preferred_rejected: 'Provider Rejected'
};

export const getStatusText = (status) => STATUS_TEXT[status];

export const getStatusTitle = (status, rejectReason, dateStatused) => {
    const providerStatuses = ['provider_verified', 'provider_rejected', 'preferred_verified', 'preferred_rejected']
    const isStatused = providerStatuses.includes(status)

    return `${STATUS_TEXT[status]}${isStatused?  ` on ${new Date(dateStatused).toLocaleString()}` : ''}${!!rejectReason ? ` because ${rejectReason}` : ''}`
};

export const getTypeList = () => Object.keys(TYPES).map((k) =>({key: TYPES[k], value: TYPES[k]}));
