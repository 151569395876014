import React from 'react';
import Link from '@material-ui/core/Link';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        cursor: 'pointer'
    }
}));
const StyledLink = React.forwardRef ((props, ref) => {
    const classes = useStyles();

    return(<Link ref={ref} className={classes.root} {...props} />);
})

export default StyledLink;