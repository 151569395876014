import {createSelector} from 'reselect/lib';
import {getToken} from "../authorize";
import {dashboardProps} from '../organization';
import {getIsNew as getIsNewProfile} from "../profile";
import {getActivatedMembers, getExists as membersExist} from "../members";
import {getActivatedContentCodes, getExists as codesExist} from "../qrCodes";
import {getCredentialCounts} from "../credentials";

const {getAvailable, getIsTrialing, getTrialEnd, getIsNewOrg, getName, getLogoImage} = dashboardProps;

const getActivated = (state) => {

    const members = getActivatedMembers(state);
    const qrCodes = getActivatedContentCodes(state);
    return ({
        members,
        qrCodes
    })
};

const getExists = (state) => membersExist(state) || codesExist(state);


// const getIsNew = getIsNewOrg || getIsNewProfile;

/*
Dashboard is only visible to user with confirmed status and admin role otherwise service should return 403 forbidden
client should handle 403 with 'forbidden' message.

Things needed from service for dashboard:
Trial ending date - from stripe
Existence of Organization object (isNew is true if no existence)
OrganizationName
Available members (from stripe)
Activated members (count of members)
Available qrCodes (from stripe)
Activated qrCodes (count of qrCodes)

* */
export const mapDashboardToProps = createSelector(
    getToken,
    getAvailable,
    getActivated,
    getTrialEnd,
    getIsTrialing,
    getName,
    getIsNewOrg,
    getIsNewProfile,
    getExists,
    getLogoImage,
    getCredentialCounts,
    (token, available, activated, trialEnd, isTrialing, name, isNewOrg, isNewProfile, exists, logoImage, credentials) => ({
        token,
        available,
        activated,
        trialEnd,
        isTrialing,
        isNew: isNewOrg || isNewProfile,
        name,
        exists,
        logoImage,
        credentials
    })
);

// export {mapDashboardToProps};

