import { makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1)
    }
}));

export const StandardButton = React.forwardRef ((props, ref) => {

    const classes = useStyles();
    return (
        <Button ref={ref} variant="contained" color="primary" className={classes.root} {...props}>
            {props.children}
        </Button>)
});

export default StandardButton;