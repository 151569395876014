import React from 'react';
import PropTypes from "prop-types/prop-types";
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Navigation from '../../components/ConnectedNavigation';
import UpdateOrganizationFormContainer from '../containers/UpdateOrganizationFormContainer';
import LogoFormContainer from "../containers/LogoFormContainer";
import PreviewDialogContainer from "../containers/PreviewDialogContainer";


const styles = (theme) => ({
    root: {
        minWidth: 250,
        [theme.breakpoints.up('sm')]: {
            minWidth: 400,
        }
    },
    hasMargin: {
        margin: theme.spacing(1)
    },
});

export class OrganizationContent extends React.Component {
    state = {
        open: false,
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleCloseDialog = () => {
        this.setState({open: false});
    };

    render() {

        const {classes, isAssigned, exists} = this.props;

        return (
            <Navigation title={'Organization'}>
                <Typography variant={"h4"} gutterBottom>
                    Update Organization
                </Typography>
                <Grid container
                      direction="column"
                      justify="flex-start"
                      alignItems="center"
                      spacing={4}
                >
                    <Grid item>
                        <Grid container
                              direction="column"
                              justify="flex-start"
                              alignItems="stretch"
                              className={classes.root}
                              spacing={4}
                        >
                            {exists && <Grid item className={classes.hasMargin}>
                                <LogoFormContainer/>
                            </Grid>}
                            <UpdateOrganizationFormContainer
                                {...{isAssigned, handlePreview: this.handleClickOpen}}/>
                        </Grid>
                    </Grid>

                </Grid>
                <PreviewDialogContainer
                    open={this.state.open}
                    handleClose={this.handleCloseDialog}
                />
            </Navigation>
        )
    }
}

OrganizationContent.propTypes = {
    classes: PropTypes.object.isRequired,
    isAssigned: PropTypes.bool.isRequired
};

export default withStyles(styles)(OrganizationContent)