import Navigation from "../../components/ConnectedNavigation";
import Typography from "@material-ui/core/Typography";
import CredentialImageFormContainer from "../containers/CredentialImageFormContainer";
import UpdateCredentialFormContainer from "../containers/UpdateCredentialFormContainer";
import React from "react";
import Grid from "@material-ui/core/Grid";

export class UpdateCredentialContent extends React.Component {
    render() {
        return (
            <Navigation title={'Credentials'}>
                <Typography variant={"h4"} gutterBottom>
                    Update Credential
                </Typography>
                <Grid container
                      direction="column"
                      justify="center"
                      alignItems="center"
                >
                    <Grid item>
                        <CredentialImageFormContainer/>
                    </Grid>
                    <Grid item>
                        <UpdateCredentialFormContainer/>
                    </Grid>
                </Grid>
            </Navigation>
        )
    }
}

export default UpdateCredentialContent;