import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types/prop-types";
import FieldSelector from "./FieldSelector";

class AddFieldDialog extends React.Component {
    state = {
        input: ''
    };

    handleSelectedfield = (e, field) => {
        return this.handleClose(e, field)

    };

    handleInputChange = (e) => {
        this.setState({input: e.target.value})
    };

    handleClose = (e, input) => {
        this.setState({input: ''});
        this.props.onClose(e, input);
    };

    render() {
        const {isOpen, currentFields} = this.props;
        return (
            <div>
                <Dialog
                    open={isOpen}
                    onClose={(e) => this.handleClose(e)}
                    aria-labelledby="addFields-dialog-title"
                    aria-describedby="addFields-dialog-description"
                >
                    <DialogTitle id="addFields-dialog-title">Add a new field</DialogTitle>
                    <DialogContent>
                        <FieldSelector currentFields={currentFields} input={this.state.input} onInputChange={this.handleInputChange} onSelected={this.handleSelectedfield} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={ (e) => this.handleClose(e, this.state.input)} color="secondary" autoFocus>
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

AddFieldDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    currentFields: PropTypes.array.isRequired
};

export default AddFieldDialog;
