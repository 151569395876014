import React from 'react';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import {phoneFormatter} from '../../utils/fieldFormatter';
import {withStyles} from '@material-ui/core';
import {IMAGE_TYPE, UnstyledViewImage} from "../../components/ImageField";

const LABELS = {
    'email': 'Email Address',
    'firstName': 'First Name',
    'lastName': 'Last Name',
    'mobilePhone': 'Mobile Phone'
};
const styles = theme => {
    return ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        gridList: {
            maxWidth: 600,
        },
        wrapper: {
            position: 'relative',
            width: 250
        },
        image: {
            display: 'block',
            margin: '0 auto',
            objectFit: 'cover',
            maxWidth: '100%',
            maxHeight: '100%'
        },
    })
};

const MemberViewer = (props) => {
    const {classes, profileImage, email, firstName, lastName, credentials, showElements, isPreview, component: CredentialList, ...rest} = props;

    return (
        <Card raised>
            <CardContent>
                <Grid container
                      direction="column"
                      justify="flex-start"
                      alignItems="center"
                >
                    <Grid item>
                        <Grid container
                              direction="column"
                              justify="flex-start"
                              alignItems="center"
                              spacing={4}
                        >
                            <Grid item>
                                <UnstyledViewImage value={profileImage} name={IMAGE_TYPE.PROFILE} {...{classes}}/>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" gutterBottom component="p">
                                    <Typography variant="body1" display={'inline'} component={'span'}>
                                        Name
                                    </Typography> {`${firstName} ${lastName}`}
                                </Typography>
                                <Typography variant="body2" gutterBottom component="p">
                                    <Typography variant="body1" display={'inline'} component={'span'}>
                                        {LABELS['email']}
                                    </Typography> {email}
                                </Typography>
                                {
                                    Object.keys(rest).map((k, i) => {
                                        return (
                                            <Typography key={i} variant="body2" gutterBottom component="p">
                                                <Typography variant="body1" display={'inline'} component={'span'}>
                                                    {LABELS[k]}
                                                </Typography> {k === 'mobilePhone' ? phoneFormatter(rest[k]) : rest[k]}
                                            </Typography>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                        <Grid item>
                            <CredentialList {...{credentials, showElements}}/>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};

export default withStyles(styles)(MemberViewer);