import {PROFILE} from "./actionTypes";
import db from "../utils/dbAccess";
import {states} from "../common/validation-styles";
import {handleError} from "../common/actions";
import {reset} from "redux-form";
import {CHANGE_PASSWORD_FORM} from "./constants";

const {error, success} = states;

const loadProfileRequest = () => ({
    type: PROFILE.GET.REQUEST
});

const loadProfileFailure = (message) => ({
    type: PROFILE.GET.FAILURE,
    payload: {msgState: states.error, message}
});

const loadProfileSuccess = (payload) => ({
    type: PROFILE.GET.SUCCESS,
    payload

});

export const loadProfile = ({token}) => (dispatch) => {
    dispatch(loadProfileRequest());

    return db.getProfile({token})
        .then(({data:{payload}}) => dispatch(loadProfileSuccess({...payload})))
        .catch((e) => dispatch(handleError(e, loadProfileFailure)))
};

const updateEmailSuccess = ({email, status}) => ({
    type: PROFILE.UPDATE_EMAIL.SUCCESS,
    payload: {msgState: success, message: 'Email successfully updated.', email, status}
});

const updateEmailFailure = (message) => ({
    type: PROFILE.UPDATE_EMAIL.FAILURE,
    payload: {msgState: error, message}
});


const updateEmailRequest = () => ({
    type: PROFILE.UPDATE_EMAIL.REQUEST
});

export const updateEmail = (opts) => (dispatch) => {
    dispatch(updateEmailRequest());

    return db.updateProfile(opts)
        .then(({data: {payload}}) => {
            return dispatch(updateEmailSuccess(payload))
        })
        .catch((e) => dispatch(handleError(e, updateEmailFailure)))
};

const requestConfirmRequest = () => ({
    type: PROFILE.REQUEST_CONFIRM.REQUEST
});

const requestConfirmFailure = (message) => ({
    type: PROFILE.REQUEST_CONFIRM.FAILURE,
    payload: {msgState: states.error, message}
});

const requestConfirmSuccess = () => ({
    type: PROFILE.REQUEST_CONFIRM.SUCCESS,
    payload: {msgState: success, message: 'Check your email.'}

});

export const requestConfirm = ({token}) => (dispatch) => {
    dispatch(requestConfirmRequest());

    return db.requestConfirm({token})
        .then(() => dispatch(requestConfirmSuccess()))
        .catch((e) => dispatch(handleError(e, requestConfirmFailure)))
};

const changePasswordSuccess = () => ({
    type: PROFILE.CHANGE_PASSWORD.SUCCESS,
    payload: {msgState: success, message: 'Password successfully changed.'}
});

const changePasswordFailure = (message) => ({
    type: PROFILE.CHANGE_PASSWORD.FAILURE,
    payload: {msgState: error, message}
});


const changePasswordRequest = () => ({
    type: PROFILE.CHANGE_PASSWORD.REQUEST
});

export const changePassword = ({password, token}) => (dispatch) => {
    dispatch(changePasswordRequest());

    return db.updateProfile({password, token})
        .then(() => dispatch(changePasswordSuccess()))
        .then(() => dispatch(reset(CHANGE_PASSWORD_FORM)))
        .catch((e) => dispatch(handleError(e, changePasswordFailure)))
};

const updateProfileSuccess = (addrs) => ({
    type: PROFILE.PUT.SUCCESS,
    payload: {msgState: success, message: 'Profile successfully updated.', ...addrs}
});

const updateProfileFailure = (message) => ({
    type: PROFILE.PUT.FAILURE,
    payload: {msgState: error, message}
});


const updateProfileRequest = () => ({
    type: PROFILE.PUT.REQUEST
});

export const updateProfile = (opts) => (dispatch) => {
    dispatch(updateProfileRequest());

    return db.updateProfile(opts)
        .then(({data: {payload}}) => dispatch(updateProfileSuccess(payload)))
        .catch((e) => dispatch(handleError(e, updateProfileFailure)))
};

export const stopVerifying = (opts) => (dispatch) => {

    const stopVerifyingSuccess = (addrs) => ({
        type: PROFILE.STOP_VERIFYING.SUCCESS,
        payload: {msgState: success, message: 'Verifier permission successfully canceled.', ...addrs}
    });

    const stopVerifyingFailure = (message) => ({
        type: PROFILE.STOP_VERIFYING.FAILURE,
        payload: {msgState: error, message}
    });


    const stopVerifyingRequest = () => ({
        type: PROFILE.STOP_VERIFYING.REQUEST
    });
    
    dispatch(stopVerifyingRequest());

    return db.stopVerifying(opts)
        .then(({data: {payload}}) => dispatch(stopVerifyingSuccess(payload)))
        .catch((e) => dispatch(handleError(e, stopVerifyingFailure)))
};



export const loadProviders = (opts) => (dispatch) => {

    const loadProvidersRequest = () => ({
        type: PROFILE.GET_PROVIDERS.REQUEST
    });

    const loadProvidersFailure = (message) => ({
        type: PROFILE.GET_PROVIDERS.FAILURE,
        payload: {msgState: states.error, message}
    });

    const loadProvidersSuccess = (payload) => ({
        type: PROFILE.GET_PROVIDERS.SUCCESS,
        payload

    });

    dispatch(loadProvidersRequest());

    return db.getAffectedProviders(opts)
        .then(({data:{payload}}) => dispatch(loadProvidersSuccess(payload)))
        .catch((e) => dispatch(handleError(e, loadProvidersFailure)))
};