import {createSelector} from "reselect";
import {getToken} from "../authorize";
import {NAME} from "./constants";
import {compose} from "ramda";

const getState = state => state[NAME];

const getReports = compose(({list}) => list, getState);

const getSelected = (state) => {
    const {selectedName: name, selectedReportDate: reportDate, selectedDataset: dataset} = getState(state);

    return {name, reportDate, dataset};
};

export const mapReportsToProps = createSelector(
    getToken,
    getReports,
    (token, reports) => ({
        token,
        reports
    })
);

export const mapReportViewerToProps = createSelector(
    getToken,
    getSelected,
    (token, report) => ({
        token,
        report
    })
);