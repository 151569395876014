import React from 'react';
import { withRouter } from 'react-router-dom'
import {connect} from 'react-redux';
import VerfierConfirmationForm from '../components/VerfierConfirmationForm';
import {loadReset, confirmVerifier} from '../actions';
import { mapVerifierConfirmationToProps } from '../selectors';
import {parse} from 'querystring';

class VerfierConfirmationFormWrapper extends React.Component {
    state = {
        name: ''
    }

    componentDidMount() {
        const {loadReset, match: {params: {confirm}}, location: {search}} = this.props;
        const {n: name} = parse(search.slice(1));
        this.setState({name})
        return loadReset({token: confirm})
    }

    render() {
        const {name} = this.state;
        const {...rest} = this.props;
        return ( <VerfierConfirmationForm {...{name, ...rest}} /> )
    }
}

export const VerfierConfirmationFormContainer = connect(mapVerifierConfirmationToProps, {
    loadReset,
    onSubmit: confirmVerifier})(VerfierConfirmationFormWrapper);

export default withRouter(VerfierConfirmationFormContainer);