import {ORGANIZATION} from "../common/actionTypes";
import {states} from "../common/validation-styles";
import db from "../utils/dbAccess";
import {handleError} from "../common/actions";
import {uploadPhoto, APP_FOLDER} from "../utils/cloudinary";
import {reset} from "redux-form";
import {ORGANIZATION_LOGO_FORM} from "./constants";

const {error, success} = states;

// todo fix the adding of the addrs to the payload so that its contained in an organization element
const updateOrganizationSuccess = (addrs) => ({
    type: ORGANIZATION.POST.SUCCESS,
    payload: {msgState: success, message: 'Organization successfully updated.', ...addrs}
});

const updateOrganizationFailure = (message) => ({
    type: ORGANIZATION.POST.FAILURE,
    payload: {msgState: error, message}
});


const updateOrganizationRequest = () => ({
    type: ORGANIZATION.POST.REQUEST
});

export const updateOrganization = (opts) => (dispatch) => {
    dispatch(updateOrganizationRequest());

    const addrs = {...opts, meta: opts.meta.reduce((a,f) => ({...a, [f.name]: f.value}), {})};

    return db.updateOrganization(addrs)
        .then(({data: {payload}}) => dispatch(updateOrganizationSuccess(payload)))
        .catch((e) => dispatch(handleError(e, updateOrganizationFailure)))
};

const showProgress = (percentCompleted) => ({
    type: ORGANIZATION.SHOW_PROGRESS,
    percentCompleted
});

const updateLogoSuccess = (addrs) => ({
    type: ORGANIZATION.UPDATE_IMAGE.SUCCESS,
    payload: {msgState: success, message: 'Organization Logo successfully updated.', ...addrs}
});

const updateLogoFailure = (message) => ({
    type: ORGANIZATION.UPDATE_IMAGE.FAILURE,
    payload: {msgState: error, message}
});

const updateLogoRequest = () => ({
    type: ORGANIZATION.UPDATE_IMAGE.REQUEST
});

export const updateLogo = ({logoImage: image, token}) => (dispatch) => {
    dispatch(updateLogoRequest());


    const {LOGO: folder} = APP_FOLDER;

    return uploadPhoto({image, folder}, showProgress, dispatch)
        .then((fileName) => db.updateLogo({token, logoImage: fileName}))
        .then((value) => {
            const {data: {payload}} = value;
            return dispatch(updateLogoSuccess(payload));
        })
        .then(() => dispatch(reset(ORGANIZATION_LOGO_FORM)))
        .catch((e) => dispatch(handleError(e, updateLogoFailure)))
};