import React from 'react';
import {reduxForm, Field, Form} from '../../utils/redux-form';
import Button from '../../components/Button';
import * as formValidators from '../../utils/formValidation';
import {VERIFIER_CONFIRMATION_FORM} from '../constants';
import ValidatorPassword from "./ValidatorPassword";
import Link from "../../components/RouterLink";
import ConfirmCheckbox from "./ConfirmCheckbox";

export const VerifierConfirmationForm = props => {
    const {handleSubmit, isDisabled, isFormVisible, isConfirmed, name} = props;

    if (isFormVisible) {
        return (
            <div>
                <Form onSubmit={handleSubmit}>
                    <Field
                        name="token"
                        component="input"
                        type="hidden"
                    />
                    <Field
                        name={'verifierConfirm'}
                        component={ConfirmCheckbox}
                        provider={name}
                    />
                    {!isConfirmed && <Field
                        name="password"
                        component={ValidatorPassword}
                        label="New Password"
                    />}
                    <Button
                        color='primary'
                        disabled={isDisabled}
                        type="submit">
                        Confirm Verifier Status
                    </Button>
                </Form>
            </div>
        )
    } else {
        return (<div>is done... <Link to={`/`}>Go Home</Link>.</div>)
    }
};

export default reduxForm({
    form: VERIFIER_CONFIRMATION_FORM,
    enableReinitialize: true,
    validate: formValidators.createValidator({
        password: [formValidators.required, formValidators.minLengthAndStrength(8, 3)]
    })
})(VerifierConfirmationForm);