import {withStyles} from "@material-ui/core";
import React from "react";
import classNames from "classnames";
import {Image, Transformation} from "cloudinary-react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from '@material-ui/icons/Clear';
import {IMAGE_TYPE, defaultImage} from "../constants";

const styles = (theme) => ({
    wrapper: {
        position: 'relative',
        width: 250,
        [theme.breakpoints.up('sm')]: {
            width: 400,
        }
    },
    topRight: {
        margin: 0,
        position: 'absolute',
        top: 0,
        right: 0,
    },
    image: {
        display: 'block',
        margin: '0 auto',
        objectFit: 'cover',
        maxWidth: '100%',
        maxHeight: '100%'
    },
});


export const UnstyledViewImage = (props) => {
    const {classes, onChange, value, name} = props;
    return (
        <div className={classNames(classes.wrapper)}>
            <Image
                fetch-format="auto"
                quality="auto"
                publicId={!!value ? value : defaultImage[name]}
                className={classes.image}
            >
                {!!name && name === IMAGE_TYPE.LOGO && <React.Fragment>
                    <Transformation if={'h_lt_400'} width="400" height="400" crop="fit"
                                    opacity={!!value ? 100 : 40}/>
                    <Transformation if={'h_gte_400'} width="400" crop="fill" opacity={!!value ? 100 : 40}/>
                </React.Fragment>}

                {!!name && name === IMAGE_TYPE.CREDENTIAL &&
                <Transformation gravity="auto:face" height="300" zoom="0.7" width="400" crop="crop"/>}

                {!!name && name === IMAGE_TYPE.PROFILE &&
                <Transformation gravity="auto:face" height="300" width="400" zoom="0.8" crop="thumb"/>}
            </Image>
            {
                !!onChange && <Tooltip title={'Replace Image'}>
                    <IconButton className={classes.topRight}
                                type="button" size="small"
                                onClick={() => onChange('~')}
                    >
                        <ClearIcon fontSize="small"/>
                    </IconButton>
                </Tooltip>
            }
        </div>
    )
};

export default withStyles(styles)(UnstyledViewImage);
