import React from "react";
import PropTypes from 'prop-types/prop-types';
import {Field, Form, reduxForm} from "../../utils/redux-form";
import {ORGANIZATION_LOGO_FORM} from "../constants";
import * as formValidators from "../../utils/formValidation";
import ImageField, {IMAGE_TYPE} from "../../components/ImageField";

export class LogoForm extends React.Component {

    render() {
        const {progress, reset,handleSubmit} = this.props;

        return (
            <Form onSubmit={handleSubmit}>
                <Field
                    name="token"
                    component="input"
                    type="hidden"
                />
                <Field
                    name="memberId"
                    component="input"
                    type="hidden"
                />
                <Field
                    name={IMAGE_TYPE.LOGO}
                    component={ImageField}
                    type="text"
                    progress={progress}
                    onClick={handleSubmit}
                    onCancel={reset}
                />
            </Form>
        )
    }
}


LogoForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    progress: PropTypes.number,
    exists: PropTypes.bool.isRequired
};

export default reduxForm({
    form: ORGANIZATION_LOGO_FORM,
    enableReinitialize: true,
    validate: formValidators.createValidator({
        logoImage: formValidators.imageIsRequiredAndNotTilde
    },)
})(LogoForm);