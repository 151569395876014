import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types/prop-types";
import SelectControl from "../../components/SelectControl";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

class DeDupeProviderDialog extends React.Component {
    state = {
        selectedOption: {}
    };

    render() {

        const {selectedOption} = this.state;
        const { options, isOpen, handleClose} = this.props;
        const meta = {touched: false, error: false};

        const onChange = (e) => {
            const [selectedOption] = options.filter((o) => o.key === e.target.value);
            this.setState({selectedOption});
        };

        const value = !!selectedOption.key ? selectedOption.key : '';

        return (
            <div>
                <Dialog
                    open={isOpen}
                    onClose={(e) => handleClose(e)}
                    aria-labelledby="dedupe-provider-dialog-title"
                    aria-describedby="dedupe-provider-dialog-description"
                >
                    <DialogTitle id="dedupe-provider-dialog-title">Reassign Credentials</DialogTitle>
                    <DialogContent>
                        <SelectControl {...{input: {onChange, value}, label: 'To Provider', meta, options}} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" type={'button'}>
                            Disagree
                        </Button>
                        <Button onClick={ event => handleClose(event, selectedOption)} color="secondary" autoFocus type={'button'}>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

DeDupeProviderDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired
};

export default DeDupeProviderDialog;
