import { createStore, applyMiddleware, compose  } from 'redux'
import reducerRegistry, {combine} from './utils/reducerRegistry';
import thunk from 'redux-thunk';
import { loadState, saveState } from './utils/localStorage';
import throttle from 'lodash/throttle';
// import { createBrowserHistory } from 'history'
// import { middleware } from './common/middleware';
import {startListener} from './common/storageListener';

// const history = createBrowserHistory();

// const routerMiddleWare = middleware(history);

const persistedState = loadState();

const reducer = combine(persistedState, reducerRegistry.getReducers());

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, persistedState, composeEnhancers(
    applyMiddleware(thunk)
));

reducerRegistry.setStoreChangeListener(persistedState, store);

startListener(store);

store.subscribe(throttle(() => {
    saveState({
        authorize: store.getState().authorize
    });
}, 1000));

export default store;