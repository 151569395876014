import React from 'react';
import Typography from "@material-ui/core/Typography";
import Navigation from '../../components/ConnectedNavigation';
import ProviderListContainer from "../containers/ProviderListContainer";

class QRCodesContent extends React.Component {

    render() {

        return (
            <Navigation title={'Provider Administration'}>
                <Typography variant={"h4"} gutterBottom>
                    Provider Administration
                </Typography>
                <ProviderListContainer />
            </Navigation>
        )
    }
}

export default QRCodesContent;