import React from 'react';
import Typography from "@material-ui/core/Typography";
import Navigation from '../../components/ConnectedNavigation';
import QRCodeListContainer from '../containers/QRCodeListContainer';
import AlertDialog from "../../components/AlertDialog";

class QRCodesContent extends React.Component {
    state = {
        isOpen: false,
        selectedCode: {}
    };

    onOpenDialog = (event, code) => {
        this.setState({isOpen: !this.state.isOpen, selectedCode: code})
    };

    onHandleClose = (event, code) => {
        this.setState({isOpen: !this.state.isOpen, selectedCode: {}});
        if(!!code) {
            const {token, deleteCode} = this.props;
            return deleteCode({token, code})
        }
    };

    getDialogProps = ({nickname, name, code = ''}) => ({
        title: `Delete QR-Code ${!!nickname ? `nicknamed '${nickname}'`: ''}`,
        contentText: `This means the QR code pointing to ${name} will be deleted and will no longer be available to you.`,
        _id: code
    });

    render() {
        const{isOpen, selectedCode} = this.state;

        const dialogProps = this.getDialogProps(selectedCode);
        return (
            <Navigation title={'Dynamic QR'}>
                <Typography variant={"h4"} gutterBottom>
                    Dynamic QR
                </Typography>
                <QRCodeListContainer openDialog={this.onOpenDialog}/>
                <AlertDialog isOpen={isOpen} {...dialogProps} handleClose={this.onHandleClose}/>
            </Navigation>

        )
    }
}

export default QRCodesContent;