import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types/prop-types";

class AlertDialog extends React.Component {
    render() {

        const {isOpen, handleClose, title, contentText, _id} = this.props;
        return (
            <div>
                <Dialog
                    open={isOpen}
                    onClose={(e) => handleClose(e)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{ title }</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            { contentText }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" type={'button'}>
                            Disagree
                        </Button>
                        <Button onClick={ event => handleClose(event, _id)} color="secondary" autoFocus type={'button'}>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

AlertDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    contentText: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired
};

export default AlertDialog;
