import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '100%'
    }
});

const StyledButton = (props) => (
    <Button variant="contained" color="primary" className={props.classes.root} {...props}>
        {props.children}
    </Button>
        );

export default withStyles(styles)(StyledButton);