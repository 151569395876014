import React from "react";
import {ReportsIcon} from "../../common/icons";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {Link} from 'react-router-dom'

export default (props) => {
    const {name, slug} = props;

    return (
        <ListItem button component={Link} to={`reports/${slug}`} >
            <ListItemIcon>
                <ReportsIcon color={'inherit'}/>
            </ListItemIcon>
            <ListItemText
                primary={name}
            />
        </ListItem>)
};