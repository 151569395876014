import { withRouter } from 'react-router-dom'
import {connect} from 'react-redux';
import Content from '../components/ProfileContent';
import {requestConfirm, loadProfile} from '../actions';
import {mapProfileContentToProps} from "../selectors";
import React from "react";

const mergeProps = function(state, {requestConfirm, loadProfile}) {
    return {
        ...state,
        loadProfile,
        confirmClickHandler: () => {
            return requestConfirm({token: state.token})
        }
    }
};

class Wrapper extends React.Component {
    componentDidMount() {
        const {loadProfile, token} = this.props;
        loadProfile({token})
    }

    render() {
        const {...rest} = this.props;

        return ( <Content { ...rest} /> )
    }
}

export const ProfileContentContainer = connect(mapProfileContentToProps, {requestConfirm, loadProfile}, mergeProps)(Wrapper);

export default withRouter(ProfileContentContainer);