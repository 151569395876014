import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import {withStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';

export const OPTIONAL_FIELDS = {
    BIO: 'Organization BIO',
    SLOGAN: 'Slogan',
    TAG_LINE: 'Tagline',
    OTHER: 'Other'
};

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        minWidth: 350,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

const getMenuItems = (currentFields) => Object.keys(OPTIONAL_FIELDS).filter((f) => !currentFields.includes(OPTIONAL_FIELDS[f]));

class FieldSelector extends React.Component {
    state = {
        other: false,
        input: ''
    };

    handleSelect = (e) => {
        const selectedValue = e.target.value;

        if (selectedValue === 'OTHER') {
            return this.setState({other: true});
        }
        return this.props.onSelected(e, OPTIONAL_FIELDS[selectedValue]);
    };

    render() {
        const {classes, currentFields, input, onInputChange} = this.props;
        const menuItems = getMenuItems(currentFields);

        return (
            <FormControl
                className={classes.formControl}
            >
                {
                    !this.state.other && <React.Fragment>
                        <InputLabel htmlFor="age-simple">New Field</InputLabel>
                        <Select
                            name={'select-control'}
                            value={'other'}
                            onChange={this.handleSelect}
                            inputProps={{
                                name: 'field-selector',
                                id: 'field-selector-control',
                            }}
                        >
                            {menuItems.map((f, i) => <MenuItem key={i} value={f}>{OPTIONAL_FIELDS[f]}</MenuItem>)}
                        </Select>
                    </React.Fragment>
                }
                {
                    this.state.other && <TextField
                        name={'otherInput'}
                        value={input}
                        label={'Other Field Name'}
                        placeholder={'Other Field Name'}
                        meta={{}}
                        onChange={onInputChange}
                    />
                }
            </FormControl>
        )
    }

}
FieldSelector.propTypes = {
    onInputChange: PropTypes.func.isRequired,
    onSelected: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    currentFields: PropTypes.array.isRequired,
    input: PropTypes.string.isRequired,
};

export default withStyles(styles)(FieldSelector)