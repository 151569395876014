import Navigation from "../../components/ConnectedNavigation";
import Typography from "@material-ui/core/Typography";
import UpdateProviderFormContainer from "../containers/UpdateProviderFormContainer";
import React from "react";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {DeDupeIcon} from "../../common/icons";
import DeDupeProviderDialog from "./DeDupeProviderDialog";
import {Redirect} from "react-router-dom";
import AlertDialog from "../../components/AlertDialog";

export class UpdateProviderContent extends React.Component {
    state = {
        isOpen: false,
        isDeDupeOpen: false,
        to: {}
    };

    toggleIsDeDupeOpen = () => this.setState({isDeDupeOpen: !this.state.isDeDupeOpen});

    handleOpen = () => {
        this.toggleIsDeDupeOpen()
    };

    handleClose = async (e, selectedOption) => {

        if (selectedOption) {
            this.setState({to: selectedOption, isOpen: !this.state.isOpen})
        }
        this.toggleIsDeDupeOpen();
    };

    handleCloseAlert = async (e, to) => {

        if (!!to) {
            const {deDupeProvider, token, _id} = this.props;

            await deDupeProvider({from: _id, to, token});
        }
        this.toggleIsDeDupeOpen();
        this.setState({selected: 'close'})
    };

    getKeyValues = ({providers, selected}) => {

        return providers.map(({_id, name}) => ({key: _id, value: name})).filter((p) => p.key !== selected)
    };

    getDialogProps = ({from, key, value}) => ({
        title: `Delete the '${from}' Provider`,
        contentText: `This will assign it's credentials to '${value}' and then delete '${from}'.`,
        _id: !!key ? key : ''
    });

    render() {
        const {to, selected, isDeDupeOpen, isOpen} = this.state;
        const {providers, name, _id} = this.props;
        const options = this.getKeyValues({providers, selected});

        if (selected === 'close') {
            return <Redirect to={'../providers'}/>
        }

        const dialogProps = this.getDialogProps({from: name, key: _id, ...to});

        return (
            <Navigation title={'Credentials'}>
                <Typography variant={"h4"} gutterBottom>
                    Update Provider
                </Typography>
                <Grid container
                      direction="column"
                      justify="center"
                      alignItems="center"
                >
                    <Grid item>
                        <Tooltip title="De-duplicate Provider">
                            <IconButton aria-label="De-duplicate Provider" onClick={this.handleOpen}>
                                <DeDupeIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <UpdateProviderFormContainer />
                    </Grid>
                </Grid>
                <DeDupeProviderDialog {...{isOpen: isDeDupeOpen, options}} handleClose={this.handleClose}/>
                <AlertDialog isOpen={isOpen} {...dialogProps}
                             handleClose={this.handleCloseAlert}/>
            </Navigation>
        )
    }
}

export default UpdateProviderContent;