import {dateParser} from "../utils/fieldParser";
//todo we might want to remove the console.trace calls when going to production

const errorHelper = (e) => {
    try {
        const deconstructMessage = ({response: {data: {message}}}) => message;
        const messages = {
            401: deconstructMessage,
            400: deconstructMessage,
            403: deconstructMessage,
            500: deconstructMessage,
            default: () => 'Service Error'
        };
        if(process.env.NODE_ENV !== 'test') {
            console.trace(e);
        }
        return messages[(e.response && e.response.status) || 'default'](e);
    }catch (err) {
        if(process.env.NODE_ENV !== 'test') {
            console.trace(e);
        }
        return 'Unknown Service Error'
    }
};

//used by credentials to calc dates locally
const getAddrs = ({dateIssued, dateExpires, ...opts}) => ({
    ...opts,
    dateExpires: dateParser(dateExpires),
    dateIssued: dateParser(dateIssued),
});

export {errorHelper, getAddrs}