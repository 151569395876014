import React from 'react';
import {Redirect} from 'react-router-dom'
import PropTypes from 'prop-types';
import {reduxForm, Field, Form, submit} from '../../utils/redux-form';
import * as formValidators from '../../utils/formValidation';
import Input from '../../components/Input';
import Button from '../../components/Button';
import ViewablePassword from './ViewablePassword';
import {withCookies} from 'react-cookie';

import {SIGN_IN_FORM} from '../constants';

export class SignInForm extends React.Component {
    state = {
        goToUrl: ''
    };

    componentDidMount() {

        const {cookies} = this.props;

        if(!!this.props.location.state) {
            const content = cookies.get('content');
            if(!content) {
                cookies.set('content', this.props.location.state.from.pathname, {path: '/'});
            }
        }
    }

    handleClick = (e) => {
        e.preventDefault();
        const {dispatch, cookies} = this.props;

        const content = cookies.get('content');

        this.setState({goToUrl: content});

        return Promise.resolve(dispatch(submit(SIGN_IN_FORM)))
            .then(() => {
                cookies.remove('content', {path: '/'});
            })
    };

    render() {
        const {handleSubmit, isDisabled, auth, pathname} = this.props;
        if (auth) {
            const from = this.state.goToUrl || (!!this.props.location.state && this.props.location.state.from) || pathname;
            return (<Redirect to={from}/>)
        }

        return (
            <Form onSubmit={handleSubmit}>
                <Field
                    name="email"
                    component={Input}
                    type="text"
                    label="Email Address"
                    placeholder={'Email Address'}
                    autoComplete="username"
                />
                <Field
                    name="password"
                    component={ViewablePassword}
                    type="password"
                    label="Password"
                    placeholder={'Password'}
                />
                <Button
                    color='primary'
                    disabled={isDisabled}
                    type="type"
                    onClick={(e) => this.handleClick(e)}
                    autoFocus
                >
                    Sign In
                </Button>
            </Form>
        )
    }
}

SignInForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired
};

const CookiefiedSignInForm = withCookies(SignInForm);

export default reduxForm({
    form: SIGN_IN_FORM,
    validate: formValidators.createValidator({
    email: [formValidators.required, formValidators.email],
    password: formValidators.required
})
})(CookiefiedSignInForm);