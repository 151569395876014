import {compose, path, isNil, isEmpty} from 'ramda';
import {createSelector} from 'reselect/lib';
import {NAME, SIGN_IN_FORM, SIGN_UP_FORM, FORGOT_PASSWORD_FORM, RESET_PASSWORD_FORM, VERIFIER_CONFIRMATION_FORM} from './constants';
import {formValueSelector, isInvalid} from "../utils/redux-form";

const formSelector = formValueSelector(VERIFIER_CONFIRMATION_FORM);

const signinDisabled = isInvalid(SIGN_IN_FORM);

const signupDisabled = isInvalid(SIGN_UP_FORM);

const forgotDisabled = isInvalid(FORGOT_PASSWORD_FORM);

const resetDisabled = isInvalid(RESET_PASSWORD_FORM);

const verifierDisabled = (state) => {

    const verifierConfirm = formSelector(state, 'verifierConfirm');

    const isChecked = verifierConfirm === 'true' || (verifierConfirm === 'false' ? false : verifierConfirm)

    return isInvalid(VERIFIER_CONFIRMATION_FORM)(state) || !isChecked;
};

const getState = state => state[NAME];

const formVisibility = state => {
    return !state[NAME].pending;
};

export const getToken = compose(path(['token']), getState);

export const getEmail = compose(path(['email']), getState);

export const getAuth = compose((token) => !(isNil(token) || isEmpty(token)), getToken);

const getStatus = compose(({status}) => ({status}), getState);

const isConfirmedFn = ({status}) => (status === 'confirmed');

export const getIsConfirmed = compose(isConfirmedFn, getState);

const isExpiredFn = ({status}) => (status === 'expired');

export const getIsExpired = compose(isExpiredFn, getState);

export const getIsAdmin = compose(({status, roles}) => status === 'confirmed' && roles.includes('admin'), getState);

export const getIsVerifier = compose(({status, roles}) => status === 'confirmed' && roles.includes('provider'), getState);

export const getIsSysAdmin = compose(({roles}) => roles.includes('sysadmin'), getState);

export const getHome = (state) => {
    const isExpired = getIsExpired(state);

    if (isExpired) {
        return '/app/account';
    }

    const isAdmin = getIsAdmin(state);

    if (isAdmin) {
        return '/app/dashboard';
    } else {
        return '/app/account';
    }
};


const getFetch = state => {
    return state.fetching; //<<<--- this is an anomaly, getting fetching from root of state
};

const getInitialValues = compose(({token}) => ({token}), getState);

export const mapSignUpToProps = createSelector(
    signupDisabled,
    formVisibility,
    getAuth,
    (isDisabled, isFormVisible, auth) => ({
        isDisabled,
        isFormVisible,
        auth
    })
);

export const mapSignInToProps = createSelector(
    signinDisabled,
    getAuth,
    getHome,
    (isDisabled, auth, pathname) => ({
        isDisabled,
        auth,
        pathname
    })
);

export const mapForgotPasswordToProps = createSelector(
    forgotDisabled,
    formVisibility,
    getEmail,
    (isDisabled, isFormVisible, email) => ({
        isDisabled,
        isFormVisible,
        email
    })
);

export const mapResetPasswordToProps = createSelector(
    resetDisabled,
    formVisibility,
    getInitialValues,
    (isDisabled, isFormVisible, {token}) => ({
        isDisabled,
        isFormVisible,
        initialValues: {
            token
        }
    })
);

export const mapAuthToProps = createSelector(
    getAuth,
    getFetch,
    getToken,
    // getForbidden,
    (auth, isFetching, token) => ({
        auth,
        isFetching,
        token
    })
);

export const mapConfirmEmailToProps = createSelector(
    getInitialValues,
    getStatus,
    ({token: confirm}, {status}) => ({
        confirm,
        status
    })
);

export const mapVerifierConfirmationToProps = createSelector(
    verifierDisabled,
    formVisibility,
    getInitialValues,
    getIsConfirmed,
    (isDisabled, isFormVisible, {token}, isConfirmed) => ({
        isDisabled,
        isFormVisible,
        isConfirmed,
        initialValues: {
            token
        }
    })
);