import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Link} from 'react-router-dom'
import {
    DashboardIcon,
    CodeListIcon,
    MemberListIcon,
    CredentialListIcon,
    OrganizationIcon,
    ReportsIcon,
    // AdministrationIcon,
    UsersIcon,
    ProvidersIcon
} from '../../../common/icons'
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";

const MainListItems = ({orgExists, isConfirmed}) => {
    return (
        <div>
            <ListItem button component={Link} to={'/app/dashboard'} disabled={!isConfirmed || !orgExists} >
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button component={Link} to={'/app/members'} disabled={!isConfirmed || !orgExists} >
                <ListItemIcon>
                    <MemberListIcon />
                </ListItemIcon>
                <ListItemText primary="Members" />
            </ListItem>
            <ListItem button component={Link} to={'/app/credentials'} disabled={!isConfirmed || !orgExists} >
                <ListItemIcon>
                    <CredentialListIcon />
                </ListItemIcon>
                <ListItemText primary="Credentials" />
            </ListItem>
            <ListItem button component={Link} to={'/app/qrCodes'} disabled={!isConfirmed || !orgExists} >
                <ListItemIcon>
                    <CodeListIcon />
                </ListItemIcon>
                <ListItemText primary="Dynamic QR" />
            </ListItem>
        </div>
    )
};

const SecondaryListItems = ({isConfirmed}) => (
    <div>
{/*        <ListItem button >
            <ListItemIcon>
                <AdministrationIcon />
            </ListItemIcon>
            <ListItemText primary="Administration" />
        </ListItem>*/}
        <ListItem button component={Link} to={'/app/organization'} disabled={!isConfirmed} >
            <ListItemIcon>
                <OrganizationIcon />
            </ListItemIcon>
            <ListItemText primary="Organization" />
        </ListItem>
        <ListItem button component={Link} to={'/app/reports'} disabled={!isConfirmed}  >
            <ListItemIcon>
                <ReportsIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
        </ListItem>
    </div>
);

const SysadminListItems = () => (
    <div>
        <Divider/>
        <ListItem button component={Link} to={'/app/userAdmin'}  >
            <ListItemIcon>
                <UsersIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
        </ListItem>
        <ListItem button component={Link} to={'/app/providers'}  >
            <ListItemIcon>
                <ProvidersIcon />
            </ListItemIcon>
            <ListItemText primary="Providers" />
        </ListItem>
    </div>
);

const ListItems = ({orgExists, isConfirmed, children, isSysAdmin}) => (
    <React.Fragment>
        {children}
        <Divider/>
        <List>
            <MainListItems {...{orgExists, isConfirmed}} />
        </List>
        <Divider/>
        <List>
            <SecondaryListItems {...{isConfirmed}} />
        </List>
        {
            isSysAdmin && <SysadminListItems />
        }
    </React.Fragment>
);

export default ListItems;