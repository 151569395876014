import React from 'react';
// import PropTypes from 'prop-types';
// import Typography from "@material-ui/core/Typography";
import MemberViewer from "./MemberViewer";
import Divider from '@material-ui/core/Divider';
import {withStyles} from '@material-ui/core';

const styles = () => ({
    divider: {
        margin: 15
    }
});

const TeamViewer = ({team, classes, component}) => (
    team.map((m, i) => (
            <React.Fragment key={i}>
                <MemberViewer key={i} {...m} component={component}/>
                {
                    team.length > 1 && i !== team.length - 1 &&
                    <Divider className={classes.divider} variant="middle"/>
                }
            </React.Fragment>
        )
    )
);

export default withStyles(styles)(TeamViewer);