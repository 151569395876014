import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import classNames from "classnames";
import styles, {variantIcon, states} from '../../common/validation-styles';
import Link from "../../components/Link";
import RouterLink from "../../components/RouterLink";

const messages = {
    [true]: 'Checking availability...',
    [false]: 'Available'
};

const getMsgText = (e, s, clickHandler) => Boolean(e) ?
    e === `User already exists.` ?
        (<span>
            {e} <Link onClick={clickHandler} color={'inherit'} underline={'always'}>
                Use different email
            </Link> or <RouterLink color={'inherit'} underline={'always'} to={'/auth/signin'}>
                Return to Sign In
            </RouterLink>.
        </span>)
        : e
    : messages[s];

export class StyledEmail extends React.Component {

    componentDidMount() {
        this.forceUpdate();
    }

    render() {
        const {classes, label, input, meta: {dirty, error, touched, asyncValidating}, onClearClick, isDisabled} = this.props;
        const validationState = !dirty ? states.default :
            asyncValidating ? states.warning : //is dirty
                error ? states.error : states.success;//is not asyncValidating
        const errorState = touched && error === 'Required' ? {error: true} : undefined;
        const msg = getMsgText(error, asyncValidating, onClearClick) ;
        const Icon = variantIcon[validationState];
        return (
            <FormControl className={classes.formControl} {...errorState}>
                <InputLabel htmlFor={input.name}>{label}</InputLabel>
                <Input
                    id={input.name}
                    type={'text'}
                    {...input}
                    onChange={input.onChange}
                    aria-describedby={`msg-text-${input.name}`}
                    autoComplete={'email'}
                    disabled={isDisabled}
                />

                {dirty && msg && <FormHelperText
                    className={classNames(classes[validationState], classes.message)}
                    id={`msg-text-${input.name}`}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)}/>
                    {msg}

                </FormHelperText>}

                {touched && error === 'Required' && <FormHelperText
                    id={`error-text-${input.name}`}>
                    {error}
                </FormHelperText>}
            </FormControl>
        )
    }
}

export default withStyles (styles)(StyledEmail);