import {ORGANIZATION} from "./actionTypes";
import {states} from "../common/validation-styles";
import db from "../utils/dbAccess";
import {handleError} from "../common/actions";
import {loadProfile} from "../profile/actions";

const {DASHBOARD} = ORGANIZATION;

const loadDashboardRequest = () => ({
    type: DASHBOARD.REQUEST
});

const loadDashboardFailure = (message) => ({
    type: DASHBOARD.FAILURE,
    payload: {msgState: states.error, message}
});

const loadDashboardSuccess = (payload) => ({
    type: DASHBOARD.SUCCESS,
    payload

});

export const loadDashboard = ({token}) => (dispatch) => {
    dispatch(loadDashboardRequest());

    return dispatch(loadProfile({token}))
        .then(() => db.getDashboard({token}))
        .then(({data:{payload}}) => dispatch(loadDashboardSuccess({...payload})))
        .catch((e) => dispatch(handleError(e, loadDashboardFailure)))
};
