import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import {Image, Transformation} from "cloudinary-react";
// import StatusIcon from "../../components/StatusIcon";
// import {STATUS_COLOR} from "../../common/credential-styles";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const styles = () => {
    return ({
        card: {
            position: 'relative',
            maxWidth: 600,
            margin: 15,
        },
        actions: {
            display: 'flex',
        },
        spaceIt: {
            marginLeft: 'auto',
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        image: {
            width: '100%',
            height: 'auto'
        },
        opacitator:{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '-webkit-fill-available',
            height: '-webkit-fill-available',
            zIndex: 1,
            backgroundColor: 'black',
            opacity: 0.5,
            cursor: 'pointer'
        }
    })
};

class HiddenPreview extends React.Component {
    state = {
        anchorEl: null
    };

    render() {
        const {classes, credentialImage, _id, handleUnHide} = this.props;

        return (
            <Card className={classes.card} raised>
                <div className={classes.opacitator} >
                    <Fab size="small" color="secondary" aria-label="Unhide" onClick={()=>handleUnHide(_id)}>
                        <AddIcon />
                    </Fab>
                </div>
                <Image publicId={credentialImage} fetch-format="auto" quality="auto"
                       className={classes.image}>
                    <Transformation crop={'fill'} width={600}/>
                </Image>
            </Card>
        );
    }
}

HiddenPreview.propTypes = {
    classes: PropTypes.object.isRequired,
    handleUnHide: PropTypes.func.isRequired
};

export default withStyles(styles)(HiddenPreview);
