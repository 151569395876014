import React from 'react';
// import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const DateField = (props) => {
    const {label, input} = props;
    return (
        <TextField
            {...input}
            label={label}
            type="date"
            InputLabelProps={{
                shrink: true,
            }}
            margin={'dense'}
            fullWidth={true}
        />
    )
};

export default DateField;