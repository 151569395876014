import Navigation from "../../components/ConnectedNavigation";
import Typography from "@material-ui/core/Typography";
import AddMemberFormContainer from "../containers/AddMemberFormContainer";
import React from "react";

export const AddMember = () => {
    return (
        <Navigation title={'Members'}>
            <Typography variant={"h4"} gutterBottom>
                Add Member
            </Typography>
            <AddMemberFormContainer/>
        </Navigation>
    )
};

export default AddMember;