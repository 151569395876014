import React from 'react';
import PropTypes from 'prop-types/prop-types';
import {reduxForm, Field, Form, submit} from '../../utils/redux-form';
import Grid from "@material-ui/core/Grid";
import {UPDATE_CODE_FORM} from '../constants';
import Input from '../../components/Input';
import Button from '../../components/StandardButton';
import {Link, Redirect} from 'react-router-dom'
import MultiSelect from "./MultiSelect";
import Static from "./StaticControl";
import Select from "../../components/SelectControl";
import QRViewer from "./QRViewer";
import * as formValidators from "../../utils/formValidation";
import Tooltip from "@material-ui/core/Tooltip";
import {ContentPreviewIcon} from "../../common/icons";
import IconButton from "@material-ui/core/IconButton";
import TypeSelect from "./TypeSelect";
import AlertDialog from "../../components/AlertDialog";
import ZoomOutOutlinedIcon from '@material-ui/icons/ZoomOutOutlined'

export class UpdateCodeForm extends React.Component {

    state = {
        isOpen: false
    };

    openDialog = () => {
        this.setState({isOpen: !this.state.isOpen})
    };

    onHandleClose = (event, _id) => {
        this.setState({isOpen: !this.state.isOpen});
        if (!!_id) {
            const {dispatch} = this.props;

            return dispatch(submit(UPDATE_CODE_FORM))
        }
    };

    getDialogProps = (props) => ({
        title: `Reassign QR Code for '${props.nickname}${!!props.nickname ? ' - ' : ''}${props.name}'`,
        contentText: `The QR Code will not be deleted. This code will no longer point to 
        '${props.nickname}${!!props.nickname ? ' - ' : ''}${props.name}' 
        but be reassigned to new content.`,
        _id: !!props.code ? props.code : ''
    });

    handleTypeChange = (e, newValue, previousValue) => {
        this.props.change(previousValue, null);
    };

    render() {
        const {handleSubmit, handlePreview, pristine, invalid, submitting, isEmpty, membersList, type, code} = this.props;


        const {isOpen} = this.state;

        const dialogProps = this.getDialogProps({...this.props.initialValues});

        if (isEmpty || submitting) {
            return <Redirect to={'/app/qrCodes'}/>
        }

        return (
            <Form onSubmit={handleSubmit}>
                <Grid container
                      direction="column"
                      justify="flex-start"
                      alignItems="center"
                      spacing={4}
                >
                    <Grid item>
                        <Grid container
                              direction="column"
                              justify="flex-start"
                              alignItems="stretch"
                        >
                            <Grid item>
                                <Field
                                    name={'code'}
                                    component={QRViewer}
                                />
                            </Grid>
                            <Grid item container
                                  direction='row'
                                  justify="space-between"
                                  alignItems="center"
                            >
                                <Grid item>
                                    <Tooltip title="QRedentials View">
                                        <IconButton
                                            aria-label="QRedentials View"
                                            component={Link} to={`/${code}`}
                                            disabled={!(pristine || invalid)}
                                        >
                                            <ZoomOutOutlinedIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item >
                                    <Tooltip title="Restrict Viewing">
                                        <IconButton
                                            aria-label="Restrict Viewing"
                                            onClick={() => handlePreview(code)}
                                            disabled={!(pristine || invalid)}
                                        >
                                            <ContentPreviewIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Field
                                    name="token"
                                    component="input"
                                    type="hidden"
                                />
                                <Field
                                    name="code"
                                    component="input"
                                    type="hidden"
                                />
                                <Field
                                    name="nickname"
                                    component={Input}
                                    type="text"
                                    label="Nick Name"
                                    placeholder={'Nick Name'}
                                    preventDefault
                                />
                            </Grid>
                            <Grid item>
                                <Field
                                    name={'type'}
                                    label={'QRedentialed As'}
                                    component={TypeSelect}
                                    onChange={this.handleTypeChange}
                                />
                            </Grid>
                            {
                                type === 'organization' &&
                                <Grid item>
                                    <Field
                                        name="organizationName"
                                        component={Static}
                                        label="Organization Name"
                                    />
                                </Grid>
                            }
                            {
                                type === 'member' &&
                                <Grid item>
                                    <Field
                                        name="member"
                                        component={Select}
                                        type="text"
                                        label="Member"
                                        options={membersList}
                                        disabled={!(type === 'member')}
                                    />
                                </Grid>
                            }
                            {
                                type === 'team' &&
                                <Grid item>
                                    <Field
                                        name="team"
                                        component={MultiSelect}
                                        label="Team"
                                        options={membersList}
                                        disabled={!(type === 'team')}
                                    />
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    to={'../qrCodes'}
                                    component={Link}
                                    variant={'outlined'}
                                    color={'default'}
                                    type="button">
                                    Cancel
                                </Button>
                                <Button
                                    disabled={pristine || invalid}
                                    type="button"
                                    onClick={this.openDialog}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <AlertDialog isOpen={isOpen} {...dialogProps} handleClose={this.onHandleClose}/>
                </Grid>
            </Form>
        )
    }
}

UpdateCodeForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    membersList: PropTypes.array.isRequired,
    handlePreview: PropTypes.func.isRequired,
    isEmpty: PropTypes.bool.isRequired
};


export default reduxForm({
    form: UPDATE_CODE_FORM,
    enableReinitialize: true,
    validate: formValidators.createValidator({
        team: formValidators.team,
        member: formValidators.member
    },)
})(UpdateCodeForm);