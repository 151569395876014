import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = () => ({
    textField: {
        width: '100%'
    }
});


const inputPropsObject = {onKeyPress: (e) => { e.key === 'Enter' && e.preventDefault(); }};

const StyledInput = (props) => {
    const {classes, input, label, placeholder, type, meta: {touched, error}, autoComplete, isDisabled, preventDefault} = props;

    return (
        <TextField
            {...input}
            label={label}
            type={type}
            placeholder={placeholder}
            className={classes.textField}
            error={touched && Boolean(error)}
            helperText={touched && error }
            margin={'dense'}
            autoComplete={autoComplete}
            disabled={isDisabled}
            inputProps={preventDefault && inputPropsObject}
        >
            {props.children}
        </TextField>
    )
};

export default withStyles (styles)(StyledInput);