import {ACCOUNT, PAYMENT_METHODS, SIGN_OUT} from "./actionTypes";
import {combineReducers} from "redux";

const removeItemFromArray = (array, itemId) => {
    return array.filter(item => item.id !== itemId);
};

const items = (listState = [], action) => {
    switch (action.type) {
        case ACCOUNT.GET.SUCCESS:
            return action.payload.items;
        case ACCOUNT.POST.SUCCESS:
            return action.payload.items;
        case SIGN_OUT:
            return [];
        default:
            return listState;
    }
};

const id = (idState = '', action) => {
    switch (action.type) {
        case ACCOUNT.GET.SUCCESS:
            return action.payload.id;
        case ACCOUNT.POST.SUCCESS:
            return action.payload.id;
        case SIGN_OUT:
            return '';
        default:
            return idState;
    }
};

const activeMembers = (activeMembersState = 0, action) => {
    switch (action.type) {
        case ACCOUNT.GET.SUCCESS:
            return action.payload.activeMembers;
        case SIGN_OUT:
            return '';
        default:
            return activeMembersState;
    }
};

const activeCodes = (activeCodesState = 0, action) => {
    switch (action.type) {
        case ACCOUNT.GET.SUCCESS:
            return action.payload.activeCodes;
        case SIGN_OUT:
            return '';
        default:
            return activeCodesState;
    }
};

const coupon = (couponState = '', action) => {
    switch (action.type) {
        case ACCOUNT.GET.SUCCESS:
            return action.payload.coupon;
        case SIGN_OUT:
            return '';
        default:
            return couponState;
    }
};

const until = (untilState = '', action) => {
    switch (action.type) {
        case ACCOUNT.GET.SUCCESS:
            return action.payload.until;
        case SIGN_OUT:
            return '';
        default:
            return untilState;
    }
};

const paymentMethods = (paymentMethodState = [], action) => {
    switch (action.type) {
        case PAYMENT_METHODS.GET.SUCCESS:
            return action.payload;
        case PAYMENT_METHODS.POST.SUCCESS:
            // set isDefault to false for all existing methods and then add the new paymentMethod
            return [...paymentMethodState.reduce((a, i)=>([...a, {...i, isDefault: false}]), []), action.payload.paymentMethod];
        case PAYMENT_METHODS.DELETE.SUCCESS:
            return removeItemFromArray(paymentMethodState, action.payload.paymentMethodId);
        case SIGN_OUT:
            return [];
        default: return paymentMethodState;
    }
};

const accountReducer = {id, items, activeMembers, activeCodes, paymentMethods, coupon, until};

//this export for testing
export const {
    id: idReducer,
    items: itemsReducer,
    activeMembers: activeMembersReducer,
    activeCodes: activeCodesReducer,
    paymentMethods: paymentMethodsReducer
} = accountReducer;


export default combineReducers(accountReducer);