import moment from "moment";

const phoneParser = (number) => number ? number.replace(/-/g, '') : '';

const zipParser = (number) => number ? number.replace(/-/g, '') : '';

const dateParser = (value) => {
    if(!value) return null;

    return moment(value).local().format();
};

export {phoneParser, zipParser, dateParser};



