import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import c from './components';
import {initializeHandler} from "../common/actions";
import QRCoded from '../qr_coded';

const UnauthorizedLayout = ({match, history}) => {

    initializeHandler(history);
    return (
        <div className="unauthorized-layout">
            <Switch>
                <Route path={`${match.path}/confirm/:confirm`} component={c.Confirm} />
                <Route path={`${match.path}/signin`} component={c.SignIn} />
                <Route path={`${match.path}/signup`} component={c.SignUp} />
                <Route path={`${match.path}/forgotPassword`} component={c.ForgotPassword} />
                <Route path={`${match.path}/reset/:confirm`} component={c.ResetPassword} />
                <Route path={`${match.path}/verifierConfirmation/:confirm`} component={c.VerifierConfirmation} />
                <Route path={`/:id`} component={QRCoded} />
                <Redirect to="/auth/signin" />
            </Switch>
        </div>
    );
}

export default UnauthorizedLayout