import {CREDENTIALS} from "./actionTypes";
import {states} from "../common/validation-styles";
import db from "../utils/dbAccess";
import {handleError} from "../common/actions";
import {reset} from "redux-form";
import {ADD_CREDENTIAL_FORM, UPDATE_CREDENTIAL_FORM, CREDENTIAL_IMAGE_FORM} from "./constants";
import {uploadPhoto, APP_FOLDER} from "../utils/cloudinary";
import {loadProviders} from "../providers/actions";
import {getAddrs} from "../common/helpers";

const {error, success} = states;

const addCredentialSuccess = (addrs) => ({
    type: CREDENTIALS.POST.SUCCESS,
    payload: {msgState: success, message: 'Credential successfully added.', credential: addrs}
});

const addCredentialFailure = (message) => ({
    type: CREDENTIALS.POST.FAILURE,
    payload: {msgState: error, message}
});

const addCredentialRequest = () => ({
    type: CREDENTIALS.POST.REQUEST
});

export const addCredential = ({credentialImage: image, ...opts}) => (dispatch) => {
    dispatch(addCredentialRequest());

    const {CREDENTIAL: folder} = APP_FOLDER;

    return uploadPhoto({image, folder}, showProgress, dispatch)
        .then((fileName) => db.addCredential({...getAddrs(opts), credentialImage: fileName}))
        .then(({data:{payload}}) => dispatch(addCredentialSuccess(payload)))
        .then(() => dispatch(reset(ADD_CREDENTIAL_FORM)))
        .catch((e) => dispatch(handleError(e, addCredentialFailure)))
};

const showProgress = (percentCompleted) => ({
    type: CREDENTIALS.SHOW_PROGRESS,
    percentCompleted
});

const loadCredentialsRequest = () => ({
    type: CREDENTIALS.GET.REQUEST
});

const loadCredentialsFailure = (message) => ({
    type: CREDENTIALS.GET.FAILURE,
    payload: {msgState: states.error, message}
});

const loadCredentialsSuccess = (payload) => ({
    type: CREDENTIALS.GET.SUCCESS,
    payload
});

export const loadCredentials = ({token, ...rest}) => (dispatch) => {
    dispatch(loadCredentialsRequest());

    return dispatch(loadProviders({token}))
        .then(() => db.getCredentials({token, ...rest}))
        .then(({data:{payload}}) => {
            return dispatch(loadCredentialsSuccess(payload))
        })
        .catch((e) => dispatch(handleError(e, loadCredentialsFailure)))
};

export const selectCredential = (event, payload) => ({
    type: CREDENTIALS.SELECT,
    payload
});

const deleteCredentialSuccess = (addrs) => ({
    type: CREDENTIALS.DELETE.SUCCESS,
    payload: {msgState: success, message: 'Credential successfully deleted.', ...addrs}
});

const deleteCredentialFailure = (message) => ({
    type: CREDENTIALS.DELETE.FAILURE,
    payload: {msgState: error, message}
});

const deleteCredentialRequest = () => ({
    type: CREDENTIALS.DELETE.REQUEST
});

export const deleteCredential = (opts) => (dispatch) => {
    dispatch(deleteCredentialRequest());

    return db.deleteCredential(opts)
        .then(({data: {payload}}) => dispatch(deleteCredentialSuccess(payload)))
        .catch((e) => dispatch(handleError(e, deleteCredentialFailure)))
};

const updateCredentialSuccess = (credential) => ({
    type: CREDENTIALS.UPDATE.SUCCESS,
    payload: {msgState: success, message: 'Credential successfully updated.', credential}
});

const updateCredentialFailure = (message) => ({
    type: CREDENTIALS.UPDATE.FAILURE,
    payload: {msgState: error, message}
});

const updateCredentialRequest = () => ({
    type: CREDENTIALS.UPDATE.REQUEST
});

export const updateCredential = (opts) => (dispatch) => {
    dispatch(updateCredentialRequest());



    return db.updateCredential(getAddrs(opts))
        .then(({data: {payload}}) => dispatch(updateCredentialSuccess(payload)))
        .then(() => dispatch(reset(UPDATE_CREDENTIAL_FORM)))
        .catch((e) => dispatch(handleError(e, updateCredentialFailure)))
};

const updateCredentialImageSuccess = (addrs) => ({
    type: CREDENTIALS.UPDATE_IMAGE.SUCCESS,
    payload: {msgState: success, message: 'Credential Image successfully updated.', ...addrs}
});

const updateCredentialImageFailure = (message) => ({
    type: CREDENTIALS.UPDATE_IMAGE.FAILURE,
    payload: {msgState: error, message}
});

const updateCredentialImageRequest = () => ({
    type: CREDENTIALS.UPDATE_IMAGE.REQUEST
});

export const updateCredentialImage = ({credentialImage: image, credentialId, token}) => (dispatch) => {
    dispatch(updateCredentialImageRequest());

    const {CREDENTIAL: folder} = APP_FOLDER;

    return uploadPhoto({image, folder}, showProgress, dispatch)
        .then((fileName) => db.updateCredentialImage({token, credentialId, credentialImage: fileName}))
        .then((value) => {
            const {data: {payload}} = value;
            return dispatch(updateCredentialImageSuccess(payload));
        })
        .then(() => dispatch(reset(CREDENTIAL_IMAGE_FORM)))
        .catch((e) => dispatch(handleError(e, updateCredentialImageFailure)))
};

