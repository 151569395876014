import React from 'react';
import PropTypes from 'prop-types/prop-types';
import {reduxForm, Field, Form} from '../../utils/redux-form';
import * as formValidators from '../../utils/formValidation';
import Grid from "@material-ui/core/Grid";
import {ADD_CODE_FORM} from '../constants';
import Input from '../../components/Input';
import Button from '../../components/StandardButton';
import {Link, Redirect} from 'react-router-dom'
import TypeSelect from "./TypeSelect";
import Static from "./StaticControl";
import Select from "../../components/SelectControl";
import MultiSelect from "./MultiSelect";

export class AddCodeForm extends React.Component {
    state = {
        imageFile: [],
        useProfile: false
    };

    handleOnDrop = ([newImageFile], onChange) => {
        const imageFile = {
            file: newImageFile,
            name: newImageFile.name,
            preview: URL.createObjectURL(newImageFile),
            size: newImageFile.size
        };

        this.setState({imageFile: [imageFile]}, () => onChange(imageFile));
    };

    useProfileChecked = () => {
        this.setState({useProfile: !this.state.useProfile});

        if (!this.state.useProfile) {
            this.props.onUseProfileChecked();
        }
    };

    render() {
        const {handleSubmit, pristine, invalid, submitting, membersList, type, exists} = this.props;

        if (!exists || submitting) {
            return <Redirect to={'/app/qrCodes'}/>
        }

        return (
            <Form onSubmit={handleSubmit}>
                <Grid item>
                    <Grid container
                          direction="column"
                          justify="flex-start"
                          alignItems="stretch"
                    >
                        <Grid item>
                            <Field
                                name="token"
                                component="input"
                                type="hidden"
                            />
                            <Field
                                name="nickname"
                                component={Input}
                                type="text"
                                label="Nick Name"
                                placeholder={'Nick Name'}
                                preventDefault
                            />
                        </Grid>
                        <Grid item>
                            <Field
                                name={'type'}
                                label={'QRedentialed As'}
                                component={TypeSelect}
                                onChange={this.handleTypeChange}
                            />
                        </Grid>
                        {
                            type === 'organization' &&
                            <Grid item>
                                <Field
                                    name="organizationName"
                                    component={Static}
                                    label="Organization Name"
                                />
                            </Grid>
                        }
                        {
                            type === 'member' &&
                            <Grid item>
                                <Field
                                    name="member"
                                    component={Select}
                                    type="text"
                                    label="Member"
                                    options={membersList}
                                    disabled={!(type === 'member')}
                                />
                            </Grid>
                        }
                        {
                            type === 'team' &&
                            <Grid item>
                                <Field
                                    name="team"
                                    component={MultiSelect}
                                    label="Team"
                                    options={membersList}
                                    disabled={!(type === 'team')}
                                />
                            </Grid>
                        }
                        <Grid item>
                            <Button
                                to={'../qrCodes'}
                                component={Link}
                                variant={'outlined'}
                                color={'default'}
                                type="button">
                                Cancel
                            </Button>
                            <Button
                                disabled={pristine || invalid}
                                type="submit">
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        )
    }
}

AddCodeForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired
};

export default reduxForm({
    form: ADD_CODE_FORM,
    enableReinitialize: true,
    validate: formValidators.createValidator({
        team: formValidators.team,
        member: formValidators.member
    },)
})(AddCodeForm);