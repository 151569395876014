import {CONTENT_CODES} from "./actionTypes";
import {states} from "../common/validation-styles";
import db from "../utils/dbAccess";
import {handleError} from "../common/actions";
import {reset, clearFields} from "redux-form";
import {UPDATE_CODE_FORM} from "./constants";

const {error, success} = states;

export const selectContentCode = (event, payload) => ({
    type: CONTENT_CODES.SELECT,
    payload
});

export const updateCode = (addrs) => (dispatch) => {

    const updateCodeSuccess = ({contentCode, assignments}) => ({
        type: CONTENT_CODES.PUT.SUCCESS,
        payload: {msgState: success, message: 'Code successfully updated.', contentCode, assignments}
    });

    const updateCodeFailure = (message) => ({
        type: CONTENT_CODES.PUT.FAILURE,
        payload: {msgState: error, message}
    });

    const updateCodeRequest = () => ({
        type: CONTENT_CODES.PUT.REQUEST
    });
    
    dispatch(updateCodeRequest());

    return db.updateContentCode(addrs)
        .then((value) => {
            const {data: {payload}} = value;
            return dispatch(updateCodeSuccess(payload));
        })
        .then(() => dispatch(reset(UPDATE_CODE_FORM)))
        .catch((e) => dispatch(handleError(e, updateCodeFailure)))
};

export const changeTab = () => clearFields(UPDATE_CODE_FORM, false, false, 'team', 'member');

export const addCode = (payload) => (dispatch) => {
    const addCodeSuccess = (addrs) => ({
        type: CONTENT_CODES.POST.SUCCESS,
        payload: {msgState: success, message: 'QR-Code successfully added.', ...addrs}
    });

    const addCodeFailure = (message) => ({
        type: CONTENT_CODES.POST.FAILURE,
        payload: {msgState: error, message}
    });

    const addCodeRequest = () => ({
        type: CONTENT_CODES.POST.REQUEST
    });

    dispatch(addCodeRequest());

    return db.addContentCode(payload)
        .then(({data:{payload}}) => dispatch(addCodeSuccess(payload)))
        .catch((e) => dispatch(handleError(e, addCodeFailure)))
};

export const deleteCode = (payload) => (dispatch) => {
    const deleteCodeSuccess = (addrs) => ({
        type: CONTENT_CODES.DELETE.SUCCESS,
        payload: {msgState: success, message: 'QR-Code successfully deleted.', ...addrs}
    });

    const deleteCodeFailure = (message) => ({
        type: CONTENT_CODES.DELETE.FAILURE,
        payload: {msgState: error, message}
    });

    const deleteCodeRequest = () => ({
        type: CONTENT_CODES.DELETE.REQUEST
    });

    dispatch(deleteCodeRequest());

    return db.deleteContentCode(payload)
        .then(({data:{payload}}) => dispatch(deleteCodeSuccess(payload)))
        .catch((e) => dispatch(handleError(e, deleteCodeFailure)))
};