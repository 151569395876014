import {MEMBERS} from "./actionTypes";
import {states} from "../common/validation-styles";
import db from "../utils/dbAccess";
import {handleError} from "../common/actions";
import {reset} from "redux-form";
import {ADD_MEMBER_FORM, UPDATE_MEMBER_FORM} from "./constants";
import {uploadPhoto, APP_FOLDER} from "../utils/cloudinary";
import {change} from "redux-form";

const {error, success} = states;

const addMemberSuccess = (addrs) => ({
    type: MEMBERS.POST.SUCCESS,
    payload: {msgState: success, message: 'Member successfully added.', member: addrs}
});

const addMemberFailure = (message) => ({
    type: MEMBERS.POST.FAILURE,
    payload: {msgState: error, message}
});

const addMemberRequest = () => ({
    type: MEMBERS.POST.REQUEST
});

export const addMember = ({profileImage: image, ...opts}) => (dispatch) => {
    dispatch(addMemberRequest());

    const {PROFILE: folder} = APP_FOLDER;

    return uploadPhoto({image, folder}, showProgress, dispatch)
        .then((fileName) => db.addMember({...opts, profileImage: fileName}))
        .then(({data:{payload}}) => dispatch(addMemberSuccess(payload)))
        .then(() => dispatch(reset(ADD_MEMBER_FORM)))
        .catch((e) => dispatch(handleError(e, addMemberFailure)))
};

const showProgress = (percentCompleted) => ({
    type: MEMBERS.SHOW_PROGRESS,
    percentCompleted
});

const deleteMemberSuccess = (addrs) => ({
    type: MEMBERS.DELETE.SUCCESS,
    payload: {msgState: success, message: 'Member successfully deleted.', ...addrs}
});

const deleteMemberFailure = (message) => ({
    type: MEMBERS.DELETE.FAILURE,
    payload: {msgState: error, message}
});

const deleteMemberRequest = () => ({
    type: MEMBERS.DELETE.REQUEST
});

export const deleteMember = (opts) => (dispatch) => {
    dispatch(deleteMemberRequest());

    return db.deleteMember(opts)
        .then(({data: {payload}}) => dispatch(deleteMemberSuccess(payload)))
        .catch((e) => dispatch(handleError(e, deleteMemberFailure)))
};

export const selectMember = (event, payload) => ({
    type: MEMBERS.SELECT,
    payload
});

const updateMemberSuccess = ({contentCodes, ...member}) => ({
    type: MEMBERS.UPDATE.SUCCESS,
    payload: {msgState: success, message: 'Member successfully updated.', member, contentCodes}
});

const updateMemberFailure = (message) => ({
    type: MEMBERS.UPDATE.FAILURE,
    payload: {msgState: error, message}
});

const updateMemberRequest = () => ({
    type: MEMBERS.UPDATE.REQUEST
});

export const updateMember = (opts) => (dispatch) => {
    dispatch(updateMemberRequest());

    return db.updateMember(opts)
        .then((value) => {
            const {data: {payload}} = value;
            return dispatch(updateMemberSuccess(payload));
        })
        .then(() => dispatch(reset(UPDATE_MEMBER_FORM)))
        .catch((e) => dispatch(handleError(e, updateMemberFailure)))
};

const updateMemberImageSuccess = (addrs) => ({
    type: MEMBERS.UPDATE_IMAGE.SUCCESS,
    payload: {msgState: success, message: 'Member Image successfully updated.', ...addrs}
});

const updateMemberImageFailure = (message) => ({
    type: MEMBERS.UPDATE_IMAGE.FAILURE,
    payload: {msgState: error, message}
});

const updateMemberImageRequest = () => ({
    type: MEMBERS.UPDATE_IMAGE.REQUEST
});

export const updateMemberImage = ({profileImage: image, memberId, token}) => (dispatch) => {
    dispatch(updateMemberImageRequest());

    const {PROFILE: folder} = APP_FOLDER;

    return uploadPhoto({image, folder}, showProgress, dispatch)
        .then((fileName) => db.updateMemberImage({token, memberId, profileImage: fileName}))
        .then((value) => {
            const {data: {payload}} = value;
            return dispatch(updateMemberImageSuccess(payload));
        })
        .then(() => dispatch(reset(UPDATE_MEMBER_FORM)))
        .catch((e) => dispatch(handleError(e, updateMemberImageFailure)))
};

export const useProfile = () => (dispatch, getState) => {

    const {firstName, lastName, email, mobilePhone} = getState().profile;
    return Promise.all([
        dispatch(change(ADD_MEMBER_FORM, 'firstName', firstName)),
        dispatch(change(ADD_MEMBER_FORM, 'lastName', lastName)),
        dispatch(change(ADD_MEMBER_FORM, 'email', email)),
        dispatch(change(ADD_MEMBER_FORM, 'mobilePhone', mobilePhone)),
        dispatch(change(ADD_MEMBER_FORM, 'profile', 'owner'))
    ]);
};

export const disassociateMember = (memberId) => ({
    type: MEMBERS.DISASSOCIATE,
    payload: {memberId}
});

const unHideSuccess = (payload) => ({
    type: MEMBERS.UNHIDE_CREDENTIAL.SUCCESS,
    payload
});

const unHideFailure = (message) => ({
    type: MEMBERS.UNHIDE_CREDENTIAL.FAILURE,
    payload: {msgState: error, message}
});

const unHideRequest = () => ({
    type: MEMBERS.UNHIDE_CREDENTIAL.REQUEST
});

export const unHide = (opts) => dispatch => {
    dispatch(unHideRequest());

    return db.unHideCredential(opts)
        .then(({data: {payload}}) => dispatch(unHideSuccess(payload)))
        .catch((e) => dispatch(handleError(e, unHideFailure)))
};

const hideSuccess = (payload) => ({
    type: MEMBERS.HIDE_CREDENTIAL.SUCCESS,
    payload
});

const hideFailure = (message) => ({
    type: MEMBERS.HIDE_CREDENTIAL.FAILURE,
    payload: {msgState: error, message}
});

const hideRequest = () => ({
    type: MEMBERS.HIDE_CREDENTIAL.REQUEST
});

export const hideCredential = (opts) => dispatch => {
    dispatch(hideRequest());

    return db.hideCredential(opts)
        .then(({data: {payload}}) => dispatch(hideSuccess(payload)))
        .catch((e) => dispatch(handleError(e, hideFailure)))
};