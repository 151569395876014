import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core";

const styles = () => ({
    img: {
        display: 'block',
        margin: '0 auto',
        objectFit: 'cover',
        maxWidth: 400
    },
    typography: {
        textAlign: 'center'
    }
});

const ImagePreview = ({classes, imagefile}) =>
    imagefile.map(({name, preview, size}) => (
        <div key={name}>
            <img src={preview} alt={name} className={classes.img}/>
            <Typography variant={"body1"} gutterBottom className={classes.typography}>
                {name} - {(size / 1024000).toFixed(2)}MB
            </Typography>
        </div>
    ));

ImagePreview.propTypes = {
    imagefile: PropTypes.arrayOf(
        PropTypes.shape({
            file: PropTypes.file,
            name: PropTypes.string,
            preview: PropTypes.string,
            size: PropTypes.number
        })
    )
};

export default withStyles(styles)(ImagePreview);
