import React from 'react';
import {CLOUDINARY} from "./config";
import {CloudinaryContext} from 'cloudinary-react';

const {CLOUD_NAME, UPLOAD_PRESET} = CLOUDINARY;

export default ({children}) => (
    <CloudinaryContext
        cloudName={CLOUD_NAME}
        uploadPreset={UPLOAD_PRESET}
    >
        {children}
    </CloudinaryContext>
);