import {CODED_CONTENT} from "./actionTypes";
import db from "../utils/dbAccess";
import {handleError} from "../common/actions";
import {states} from "../common/validation-styles";

const {error, success} = states;

const loadContentRequest = () => ({
    type: CODED_CONTENT.GET.REQUEST
});

const loadContentFailure = (message) => ({
    type: CODED_CONTENT.GET.FAILURE,
    payload: {msgState: error, message}
});

const loadContentSuccess = (payload) => ({
    type: CODED_CONTENT.GET.SUCCESS,
    payload
});

export const loadContent = ({token, code}) => (dispatch) => {
    dispatch(loadContentRequest());

    return db.getCodedContent({token, code})
        .then(({data:{payload}}) => dispatch(loadContentSuccess(payload)))
        .catch((e) => dispatch(handleError(e, loadContentFailure)))
};


export const loadCredential = (opts) => (dispatch) => {
    const loadCredentialRequest = () => ({
        type: CODED_CONTENT.GET_CREDENTIAL.REQUEST
    });

    const loadCredentialFailure = (message) => ({
        type: CODED_CONTENT.GET_CREDENTIAL.FAILURE,
        payload: {msgState: error, message}
    });

    const loadCredentialSuccess = (payload) => ({
        type: CODED_CONTENT.GET_CREDENTIAL.SUCCESS,
        payload
    });

    dispatch(loadCredentialRequest());

    return db.getSingleCredential(opts)
        .then(({data:{payload}}) => dispatch(loadCredentialSuccess(payload)))
        .catch((e) => dispatch(handleError(e, loadCredentialFailure)))
};

export const statifyCredential = (opts) => (dispatch) => {
    const statifyCredentialSuccess = (credential) => {
        return ({
            type: CODED_CONTENT.PUT_CREDENTIAL.SUCCESS,
            payload: {msgState: success, message: 'Credential successfully updated.', credential}
        });
    }
    const statifyCredentialFailure = (message) => ({
        type: CODED_CONTENT.PUT_CREDENTIAL.FAILURE,
        payload: {msgState: error, message}
    });

    const statifyCredentialRequest = () => ({
        type: CODED_CONTENT.PUT_CREDENTIAL.REQUEST
    });
    
    dispatch(statifyCredentialRequest());

    return db.putCredential(opts)
        .then(({data: {payload}}) => dispatch(statifyCredentialSuccess(payload)))
        .catch((e) => dispatch(handleError(e, statifyCredentialFailure)))
};