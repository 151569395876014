import React from 'react';
import PropTypes from 'prop-types/prop-types';
import {reduxForm, Field, Form, FieldArray} from '../../utils/redux-form';
import Grid from "@material-ui/core/Grid";
import {UPDATE_ACCOUNT_FORM} from '../constants';
import Button from "@material-ui/core/Button";
import HeaderGridItem from "./HeaderGridItem";
import Typography from "@material-ui/core/Typography";
import {CodeListIcon, MemberListIcon, OrganizationIcon} from "../../common/icons";
import StatusIcon from "../../components/StatusIcon";
import {quantityNormalizer} from "../fieldNormalizer";
import Hidden from '@material-ui/core/Hidden';
import StyledInputGridItem from "./StyledInputGridItem";
import getCouponText from '../../common/couponText';

const ICONS = {
    monthlyOrganization: OrganizationIcon,
    monthlyMember: MemberListIcon,
    monthlyQRCode: CodeListIcon
};

const BodyGridItem = ({input, ...rest}) => (
    <Grid item {...rest}>
        <Typography component={'span'} variant={'body2'} color={"textSecondary"}>
            {input.value}
        </Typography>
    </Grid>
);

const AccountItems = (props) => {
    const {fields} = props;

    return (
        <React.Fragment>
            {
                fields.map((accountItem, i) => {
                    const field = fields.get(i);
                    const {amount: cents, quantity, nickname, name, activeCodes, activeMembers} = field;

                    const isOrg = nickname === 'monthlyOrganization';
                    const isMember = nickname === 'monthlyMember';

                    const ACTIVE = {
                        monthlyOrganization: 1,
                        monthlyMember: activeMembers,
                        monthlyQRCode: activeCodes
                    };

                    const activated = ACTIVE[nickname];

                    const amount = (cents / 100);

                    const total = amount * quantity;

                    return (
                        <React.Fragment key={i}>
                            <Grid item container direction="row" alignItems="center">
                                <Field name={`${accountItem}.id`} component="input" type="hidden"/>
                                <Hidden smDown>
                                    <Grid item md={1}>
                                        <Typography component={'span'} variant={'body2'} color={"textSecondary"}>
                                            <StatusIcon icon={ICONS[nickname]} fontSize="small"/>
                                        </Typography>
                                    </Grid>
                                </Hidden>
                                <BodyGridItem xs={4} md={3} input={{value: name}}/>
                                <Hidden smDown>
                                    <BodyGridItem md={2} input={{value: nickname}}/>
                                </Hidden>
                                <BodyGridItem xs={2} md={1} align={'center'} input={{value: activated}}/>
                                {
                                    isOrg ? <BodyGridItem xs={2} md={1} align={'center'} input={{value: quantity}}/> :
                                        <React.Fragment>
                                            <Field
                                                name={`${accountItem}.quantity`}
                                                component={StyledInputGridItem}
                                                xs={2} md={1}
                                                align={'center'}
                                                normalize={quantityNormalizer(isMember ? 'activeMembers' : 'activeCodes')}
                                            />
                                        </React.Fragment>
                                }
                                <Hidden smDown>
                                    <BodyGridItem md={2} align={'right'} input={{value: amount.toFixed(2)}}/>
                                </Hidden>
                                <BodyGridItem xs={4} md={2} align={'right'} input={{value: total.toFixed(2)}}/>
                            </Grid>
                        </React.Fragment>
                    )
                })
            }
        </React.Fragment>
    );
};


const UpdateAccountForm = (props) => {
    const {pristine, invalid, handleSubmit, coupon, until} = props;

    const couponText = getCouponText({coupon, until});

    const {total} = props;

    return (
        <Form onSubmit={handleSubmit}>
            <Field
                name="token"
                component="input"
                type="hidden"
            />
            <Field
                name="id"
                component="input"
                type="hidden"
            />
            <Grid container direction="column"
            >
                <Grid item container direction="row">
                    <Hidden smDown>
                        <HeaderGridItem md={1}/>
                    </Hidden>
                    <HeaderGridItem xs={4} md={3}>Product</HeaderGridItem>
                    <Hidden smDown>
                        <HeaderGridItem md={2}>Plan</HeaderGridItem>
                    </Hidden>
                    <HeaderGridItem xs={2} md={1} align={'center'}>Active</HeaderGridItem>
                    <HeaderGridItem xs={2} md={1} align={'center'}>Avail</HeaderGridItem>
                    <Hidden smDown>
                        <HeaderGridItem md={2} align={'right'}>Amount</HeaderGridItem>
                    </Hidden>
                    <HeaderGridItem xs={4} md={2} align={'right'}>Total</HeaderGridItem>
                </Grid>
                <FieldArray name={'items'} component={AccountItems}/>
                <Grid item container direction="row">
                    <BodyGridItem xs={10} input={{value: ''}}/>
                    <BodyGridItem xs={2} align={'right'} input={{value: `$${(total).toFixed(2)}`}}/>
                </Grid>
                {
                    !!coupon && <Grid item container direction="row">
                        <BodyGridItem xs={12} align={'right'} input={{value: couponText}}/>
                    </Grid>
                }

                <Grid item container direction="row">
                    <Grid item xs={12}>
                        <Button
                            disabled={pristine || invalid}
                            variant="contained" color="primary"
                            type="submit"
                        >
                            Update
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Form>
    )

};

UpdateAccountForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired
};

export default reduxForm({
    form: UPDATE_ACCOUNT_FORM,
    enableReinitialize: true
})(UpdateAccountForm);