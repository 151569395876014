import Navigation from "../../components/ConnectedNavigation";
import Typography from "@material-ui/core/Typography";
import AddCodeFormContainer from "../containers/AddCodeFormContainer";
import React from "react";

export default () => {
    return (
        <Navigation title={'Members'}>
            <Typography variant={"h4"} gutterBottom>
                Add QR-Code
            </Typography>
            <AddCodeFormContainer/>
        </Navigation>
    )
};
