import {ACCOUNT, PAYMENT_METHODS} from "./actionTypes";
import {states} from "../common/validation-styles";
import db from "../utils/dbAccess";
import {handleError, signOut} from "../common/actions";

const {error, success} = states;

export const deleteAccount = (opts) => (dispatch) => {

    const deleteAccountSuccess = () => ({
        type: ACCOUNT.DELETE.SUCCESS,
        payload: {msgState: success, message: 'Account successfully deleted.'}
    });

    const deleteAccountFailure = (message) => ({
        type: ACCOUNT.DELETE.FAILURE,
        payload: {msgState: error, message}
    });


    const deleteAccountRequest = () => ({
        type: ACCOUNT.DELETE.REQUEST
    });
    
    dispatch(deleteAccountRequest());

    return db.deleteAccount(opts)
        .then(() => dispatch(deleteAccountSuccess()))
        .then(() => dispatch(signOut()))
        .catch((e) => dispatch(handleError(e, deleteAccountFailure)))
};

export const loadAccount = (opts) => (dispatch) => {

    const loadAccountSuccess = (payload) => ({
        type: ACCOUNT.GET.SUCCESS,
        payload

    });

    const loadAccountFailure = (message) => ({
        type: ACCOUNT.GET.FAILURE,
        payload: {msgState: error, message}
    });


    const loadAccountRequest = () => ({
        type: ACCOUNT.GET.REQUEST
    });

    dispatch(loadAccountRequest());

    return db.loadAccount(opts)
        .then(({data:{payload}}) => dispatch(loadAccountSuccess({...payload})))
        .catch((e) => dispatch(handleError(e, loadAccountFailure)))
};

export const updateAccount = (opts) => (dispatch) => {

    const updateAccountSuccess = (addrs) => ({
        type: ACCOUNT.POST.SUCCESS,
        payload: {msgState: success, message: 'Account successfully updated.', ...addrs}
    });

    const updateAccountFailure = (message) => ({
        type: ACCOUNT.POST.FAILURE,
        payload: {msgState: error, message}
    });


    const updateAccountRequest = () => ({
        type: ACCOUNT.POST.REQUEST
    });

    dispatch(updateAccountRequest());

    return db.updateAccount(opts)
        .then(({data: {payload}}) => dispatch(updateAccountSuccess(payload)))
        .catch((e) => dispatch(handleError(e, updateAccountFailure)))
};

export const loadPaymentMethods = (opts) => (dispatch) => {

    const loadPaymentMethodsSuccess = (payload) => ({
        type: PAYMENT_METHODS.GET.SUCCESS,
        payload

    });

    const loadPaymentMethodsFailure = (message) => ({
        type: PAYMENT_METHODS.GET.FAILURE,
        payload: {msgState: error, message}
    });


    const loadPaymentMethodsRequest = () => ({
        type: PAYMENT_METHODS.GET.REQUEST
    });

    dispatch(loadPaymentMethodsRequest());

    return db.getPaymentMethods(opts)
        .then(({data:{payload}}) => dispatch(loadPaymentMethodsSuccess([...payload])))
        .catch((e) => dispatch(handleError(e, loadPaymentMethodsFailure)))
};

export const addPaymentMethod = (payload) => (dispatch) => {
    const addPaymentMethodSuccess = (paymentMethod) => ({
        type: PAYMENT_METHODS.POST.SUCCESS,
        payload: {msgState: success, message: 'Payment Method successfully added.', paymentMethod}
    });

    const addPaymentMethodFailure = (message) => ({
        type: PAYMENT_METHODS.POST.FAILURE,
        payload: {msgState: error, message}
    });

    const addPaymentMethodRequest = () => ({
        type: PAYMENT_METHODS.POST.REQUEST
    });

    dispatch(addPaymentMethodRequest());

    return db.addPaymentMethod(payload)
        .then(({data:{payload}}) => dispatch(addPaymentMethodSuccess(payload)))
        .catch((e) => dispatch(handleError(e, addPaymentMethodFailure)))
};

export const deletePaymentMethod = (opts) => (dispatch) => {
    const deletePaymentMethodSuccess = (addrs) => ({
        type: PAYMENT_METHODS.DELETE.SUCCESS,
        payload: {msgState: success, message: 'Payment Method successfully deleted.', ...addrs}
    });

    const deletePaymentMethodFailure = (message) => ({
        type: PAYMENT_METHODS.DELETE.FAILURE,
        payload: {msgState: error, message}
    });

    const deletePaymentMethodRequest = () => ({
        type: PAYMENT_METHODS.DELETE.REQUEST
    });

    dispatch(deletePaymentMethodRequest());

    return db.deletePaymentMethod(opts)
        .then(({data:{payload}}) => dispatch(deletePaymentMethodSuccess(payload)))
        .catch((e) => dispatch(handleError(e, deletePaymentMethodFailure)))
};