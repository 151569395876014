import {combineReducers} from "redux";
import {CODED_CONTENT, SIGN_OUT} from "./actionTypes";

const type = (typeState = '', action) => {
    switch (action.type) {
        case CODED_CONTENT.GET.SUCCESS:
            return action.payload.type;
        case SIGN_OUT:
            return '';
        default: return typeState;
    }
};

const nickname = (nicknameState = '', action) => {
    switch (action.type) {
        case CODED_CONTENT.GET.SUCCESS:
            return action.payload.nickname;
        case SIGN_OUT:
            return '';
        default: return nicknameState;
    }
};

const code = (codeState = '', action) => {
    switch (action.type) {
        case CODED_CONTENT.GET.SUCCESS:
            return action.payload.code;
        case SIGN_OUT:
            return '';
        default: return codeState;
    }
};

const organization = (organizationState = null, action) => {
    switch (action.type) {
        case CODED_CONTENT.GET.SUCCESS:
            return action.payload.organization || null;
        case SIGN_OUT:
            return null;
        default: return organizationState;
    }
};

const member = (memberState = null, action) => {
    switch (action.type) {
        case CODED_CONTENT.GET.SUCCESS:
            return action.payload.member || null;
        case SIGN_OUT:
            return null;
        default: return memberState;
    }
};

const team = (teamState = null, action) => {
    switch (action.type) {
        case CODED_CONTENT.GET.SUCCESS:
            return action.payload.team || null;
        case SIGN_OUT:
            return null;
        default: return teamState;
    }
};

const credential = (state = {}, action) => {
    switch (action.type) {
        case CODED_CONTENT.GET_CREDENTIAL.SUCCESS:
            return action.payload;
        case CODED_CONTENT.PUT_CREDENTIAL.SUCCESS:
            return action.payload.credential;
        default: return {};// any other action clears this out.
    }
}

const codedContentReducer = {type, nickname, code, organization, member, team, credential};

//this export for testing
export const {
    type: typeReducer,
    nickname: nicknameReducer,
    code: codeReducer,
    organization: organizationReducer,
    member: memberReducer,
    team: teamReducer
} = codedContentReducer;


export default combineReducers(codedContentReducer);