import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field, Form, FieldArray} from '../../utils/redux-form';
import * as formValidators from '../../utils/formValidation';
import Grid from "@material-ui/core/Grid";
import {withStyles} from '@material-ui/core/styles';
import {UPDATE_ORG_FORM} from '../constants';
import Input from '../../components/Input';
import Button from '../../components/StandardButton';
import {phoneParser} from '../../utils/fieldParser';
import {phoneFormatter} from '../../utils/fieldFormatter';
import MetaField from "./MetaField";
import AddressField from "./AddressField";
import Tooltip from "@material-ui/core/Tooltip";
import {Link} from "react-router-dom";
import {CodeListIcon, ContentPreviewIcon} from "../../common/icons";
import IconButton from "@material-ui/core/IconButton";

const styles = (theme) => ({
    hasMargin: {
        margin: theme.spacing(1)
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    }
});

export const UpdateOrganizationForm = (props) => {
    const {classes, handleSubmit, pristine, invalid, isAssigned, handlePreview, exists} = props;

    return (
        <Form onSubmit={handleSubmit}>
            <Grid item container
                  direction='row'
                  justify="space-between"
                  alignItems="center"
            >
                {exists && <React.Fragment>
                    <Grid item>
                        <Tooltip title={'View QR Code List'}>
                            <Button
                                variant="outlined" size="small"
                                aria-label="Assign"
                                to={'/app/qrCodes'}
                                component={Link}
                                disabled={!(pristine || invalid)}
                                color={isAssigned ? 'secondary' : 'primary'}>
                                <CodeListIcon className={classes.extendedIcon}/>
                                {isAssigned ? 'Assigned' : 'Assign'}
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Preview Organization Content">
                            <IconButton
                                aria-label="Preview Organization Content"
                                onClick={handlePreview}
                                disabled={!(pristine || invalid)}
                            >
                                <ContentPreviewIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </React.Fragment>}
            </Grid>
            <Grid item className={classes.hasMargin}>
                <Field
                    name="token"
                    component="input"
                    type="hidden"
                />
                <Field
                    name="name"
                    component={Input}
                    type="text"
                    label="Organization Name"
                    placeholder={'Organization Name'}
                />
                <FieldArray
                    name={'addresses'}
                    component={AddressField}
                />
                <Field
                    name="phone"
                    component={Input}
                    type="text"
                    label="Phone Number"
                    placeholder={'Phone Number'}
                    format={phoneFormatter}
                    parse={phoneParser}
                />
                <Field
                    name="website"
                    component={Input}
                    type="text"
                    label="Website"
                    placeholder={'Website'}
                />
                <FieldArray
                    name={'meta'}
                    component={MetaField}
                />
            </Grid>
            <Grid item>
                <Button
                    disabled={pristine || invalid}
                    type="submit">
                    Update
                </Button>
            </Grid>
        </Form>
    )
};

UpdateOrganizationForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    pristine: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    loadDashboard: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    exists: PropTypes.bool.isRequired

};

export const StyledUpdateOrganizationForm = withStyles(styles)(UpdateOrganizationForm);

export default reduxForm({
    form: UPDATE_ORG_FORM,
    enableReinitialize: true,
    validate: formValidators.createValidator({
        name: formValidators.required,
        phone: formValidators.required,
        website: formValidators.required,
        meta: formValidators.metaValidate,
        addresses: formValidators.addressValidate
    })
})(StyledUpdateOrganizationForm);