import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = () => ({
    progress: {
        flexGrow: 1,
    },
});

const StyledLoader = (props) => {
    const {isFetching, classes} = props;
    return isFetching ? <div className={classes.progress}><LinearProgress color="primary" /></div> : null
};

export default withStyles(styles)(StyledLoader);
