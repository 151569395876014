import {compose} from 'ramda';
import {createSelector} from 'reselect/lib';
import {NAME} from './constants';

const getState = state => state[NAME];

export const getMessage = compose((state) => {
    const {message, msgState} = state;
    return ({message, msgState})
}, getState);

export const mapSnackbarToProps = createSelector(
    getMessage,
    ({message, msgState}) => ({ message, msgState })
);

