import {createSelector} from "reselect";
import {isEmpty, juxt, length, not, filter, prop} from "ramda";
import {NAME} from "./constants";
import {NAME as CREDENTIALS} from '../credentials/constants'
import {compose} from "ramda";
import {getToken} from "../authorize";
import {getAvailable} from "../organization/selectors";

const getState = state => state[NAME];

const getMembers = compose(({list}) => (list), getState);

const getSelectedMemberId = compose(prop('selectedMember') , getState);

const getMemberById = (state, memberId) => ([...getMembers(state)
    .filter((item) => item._id === memberId)]);

export const getMemberAndCredentials = (memberId, state) => {

    const [member] = getMemberById(state, memberId);

    const credentials = getMemberCredentials(state, memberId);

    return {member, credentials}
};

export const getExists = compose(not, isEmpty, getMembers);

export const getActivatedMembers = compose(length, getMembers);

export const getMemberListItems = compose((list) => (list.map(({_id, firstName, lastName}) => ({key: _id, value: `${firstName} ${lastName}`}))) , getMembers);

const getProgress = compose(({progress}) =>({progress}), getState);

const getSelectedMember = (state) => {
    const selectedId = getSelectedMemberId(state);
    const [selectedMember] = getMemberById(state, selectedId);

    return {...selectedMember}
};

const getCredentialsList = compose(({list}) => (list), state => state[CREDENTIALS]);

const getCredentialsExist = compose((c) => c.length > 0, getCredentialsList);

const getMemberCredentials = (state, memberId) => compose(filter((c)=> c.member === memberId),getCredentialsList)(state);

const getSelectedMemberWithCredentials = (state) => {
    const selectedId = getSelectedMemberId(state);

    const {credentials, member} = getMemberAndCredentials(selectedId, state);

    return {...member, credentials}
};

const getIsAssigned = compose(({isAssigned}) => (isAssigned) , getSelectedMember);

const getSelectedMemberFields = compose(({profileImage, _id: memberId, ...selectedMember}) => ({...selectedMember,  ...(!!memberId ? {memberId} : {})}), getSelectedMember);

export const getdMemberImageByMemberId = (state, memberId) =>{


    const [member] = getMemberById(state, memberId);

    const getprofileImage = ({profileImage, _id: memberId}) => ({
        memberId,
        profileImage: (!!profileImage ? profileImage : '')
    });

    return getprofileImage({...member})
};

const getSelectedMemberImage = compose(({profileImage, _id: memberId}) => ({memberId, profileImage: (!!profileImage ? profileImage : '')}), getSelectedMember);

const getIsAssociated = (state) => {
    const selected = getSelectedMember(state);
    const profileId = selected.profileId;

    return !!profileId;
};

const getHasAssociated = (state) => {

    const hasProfileId = (list) => {
        return list.filter(({profileId}) => {
            return !!profileId
        });
    };

    const hasAssociated = ([profile]) => {
        return !!profile;
    };

    return compose(hasAssociated , hasProfileId, getMembers)(state)

};

const getAvailableMembers = compose(({members: available}) => (available), getAvailable);

const getActOfAvailable = compose(([available, activated]) =>  (`(${activated} of ${available})`), juxt([getAvailableMembers, getActivatedMembers]));

export const mapMembersToProps = createSelector(
    getToken,
    getMembers,
    getAvailableMembers,
    getProgress,
    getActOfAvailable,
    getActivatedMembers,
    getExists,
    (token, members, available, {progress}, actOfAvail, activated, exists) => ({
        token,
        members,
        actOfAvail,
        progress,
        available,
        exists,
        activated
    })
);

export const mapAddMemberToProps = createSelector(
    getToken,
    getHasAssociated,
    (token, hasAssociated) => ({
        initialValues: {token},
        hasAssociated
    })
);

export  const mapMemberContentToProps = createSelector(
    getToken,
    (token) => ({
        token
    })
);

export const mapUpdateMemberFormToProps = createSelector(
    getToken,
    getSelectedMemberFields,
    getIsAssociated,
    (token, selectedMember, isAssociated) => ({
        isEmpty: isEmpty(selectedMember),
        isAssociated,
        initialValues: {token, ...selectedMember}
    })

);

export const mapUpdateMemberContentToProps = createSelector(
    getIsAssigned,
    (isAssigned) => ({
        isAssigned

    })
);

export const mapMemberImageToProps = createSelector(
    getToken,
    getSelectedMemberImage,
    getProgress,
    (token, {profileImage, memberId}, {progress}) => ({
        progress,
        initialValues: {
            token,
            profileImage,
            memberId
        }
    })
);

export const mapPreviewToProps = createSelector(
    getSelectedMemberWithCredentials,
    getSelectedMemberImage,
    getCredentialsExist,
    getToken,
    (selectedMember, {profileImage}, credentialsExist, token) => ({
        member: {
            ...selectedMember,
            profileImage
        },
        credentialsExist,
        token
    })
);

export const mapHideUnhideToProps = createSelector(
    getToken,
    (token) => ({token})
);