import React, {useEffect} from 'react';
import Typography from "@material-ui/core/Typography";
import Navigation from '../../components/ConnectedNavigation';
import UserCouponFormContainer from "../containers/UserCouponFormContainer";

const UserContent = (props) => {

    const {firstName, lastName, loadCoupons, token} = props;

    useEffect(() => {
        const loadNow = () => loadCoupons({token});
        loadNow();
    }, [token, loadCoupons]);

    return (
        <Navigation title={'User Administration'}>
            <Typography variant={"h4"} gutterBottom>
                Update User Coupon
            </Typography>
            <Typography variant="body2" gutterBottom component="p">
                <Typography variant="body1" display={'inline'} component={'span'}>
                    Name
                </Typography> {`${firstName} ${lastName}`}
            </Typography>
            <UserCouponFormContainer/>
        </Navigation>
    )
};

export default UserContent;