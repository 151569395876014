import TableCell from "@material-ui/core/TableCell";
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import React from "react";
import Typography from "@material-ui/core/Typography";

export default (props) => {
    const {classes} = props;
    return (
        <TableFooter>
            <TableRow>
                <TableCell colSpan={6} >
                    <Typography variant={'body2'}  >
                        if expired or within 30 days, indicate <Typography component={'span'} display={'inline'} className={classes[30]} >BOLD RED</Typography>
                    </Typography>
                    <Typography variant={'body2'}  >
                        if within 31-60 days indicate <Typography component={'span'} display={'inline'} className={classes[60]} >BOLD GOLDENROD</Typography>
                    </Typography>
                    <Typography variant={'body2'}  >
                        if 61 to 90 days indicate <Typography component={'span'} display={'inline'} className={classes[90]} >BOLD BLUE</Typography>
                    </Typography>
                </TableCell>
            </TableRow>
        </TableFooter>
    )
}