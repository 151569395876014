import * as n from './actionTypes';
import {errorHelper} from "./helpers";

export const signOut = () => ({
    type: n.SIGN_OUT
});

let handleError;

const initializeHandler = (history) => {
    handleError = (e, failureAction) => (dispatch) => {
        return Promise.resolve(dispatch(failureAction(errorHelper(e))))
            .then(() => {
                if(!!e.response) {
                    const {status} = e.response;
                    switch (status) {
                        case 500:
                            return ;//dispatch(signOut());
                        case 401:
                            return dispatch(signOut());
                        case 403:
                            return history.push('/app/forbidden');
                        default:
                            return;
                    }
                }
            })

    };
};

export {handleError, initializeHandler};

