import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {UsersIcon, CouponIcon} from "../../common/icons";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import getCouponText from '../../common/couponText';

const getNextInvoice = (currentPeriodEnd) => !!currentPeriodEnd ? `, Next Invoice ${moment.unix(currentPeriodEnd).format('L')}` : '';
// const getCouponText = (coupon, until) => `, ${coupon} until ${moment.unix(until).format('L')}`;
const getTrialEnding = (trialEnding) => `, Trial Ending ${moment.unix(trialEnding).format('L')}`;
const getTrialEndingOrNextInvoice = (trialEnd, currentPeriodEnd) => !!trialEnd && trialEnd >= currentPeriodEnd ? getTrialEnding(trialEnd) : getNextInvoice(currentPeriodEnd);

const getSecondaryText = ({firstName, lastName, current_period_end, coupon, until, trial_end}) => `${firstName} ${lastName} 
${getTrialEndingOrNextInvoice(trial_end, current_period_end)} 
${!!coupon ? `, ${getCouponText({coupon, until})}` : ''}`;

export default (props) => {
    const {_id, email, firstName, lastName, isMe, roles, handleIconClick, handleSelected, coupon, current_period_end, until, trial_end, customerId} = props;

    const isSysadmin = roles.includes('sysadmin');
    const isAdmin = roles.includes('admin');

    const isCustomer = !!customerId;

    return (
        <ListItem
            // button
            disabled={isMe}
            // onClick={(e) => handleSelected(e, _id)}
        >
            <ListItemIcon>
                <Tooltip title={`${isSysadmin ? 'Remove sysadmin access' : 'Add sysadmin access'}`}>
                    <IconButton aria-label="Toggle Sysadmin"
                                onClick={isMe ? undefined : (e) => handleIconClick(e, {_id, firstName, lastName, roles})}>
                        <UsersIcon color={isSysadmin ? 'secondary' : 'inherit'}/>
                    </IconButton>
                </Tooltip>
            </ListItemIcon>
            <ListItemText
                primary={email}
                secondary={!!firstName && !!lastName && getSecondaryText({firstName, lastName, current_period_end, coupon, until, trial_end})}
            />
            {isAdmin && <ListItemSecondaryAction >
                <Tooltip title={isCustomer ? "Set Coupon": 'Not an administrator'} aria-label="Set Coupon"><span>
                    <IconButton edge="end" onClick={(e) => handleSelected(e, _id)} disabled={!isCustomer} >
                        <CouponIcon/>
                    </IconButton></span>
                </Tooltip>
            </ListItemSecondaryAction>}
        </ListItem>
    )
}