import {combineReducers} from 'redux'
import {SIGN_OUT, MEMBERS} from './actionTypes';
import {CONTENT_CODES, CREDENTIALS, ORGANIZATION} from "../common/actionTypes";
import * as common from "../common/reducer";

const list = (listState = [], action) => {
    switch (action.type) {
        case MEMBERS.POST.SUCCESS:
            return common.addItemToArray(listState, action.payload.member);
        case ORGANIZATION.DASHBOARD.SUCCESS:
            return action.payload.members;
        case MEMBERS.DELETE.SUCCESS:
            return common.removeItemFromArray(listState, action.payload.memberId);
        case MEMBERS.UPDATE.SUCCESS:
            const {memberId, ...rest} = action.payload.member;
            return common.updateItemInArray(listState, memberId, (member) => ({...member, ...rest}));
        case MEMBERS.UPDATE_IMAGE.SUCCESS:
            return common.updateItemInArray(listState, action.payload.memberId, (member) => ({...member, profileImage: action.payload.profileImage}));
        case MEMBERS.DISASSOCIATE:
            return common.updateItemInArray(listState, action.payload.memberId, (({profileId, ...rest}) => ({...rest})));
        case CONTENT_CODES.PUT.SUCCESS:
            const {assignments} = action.payload;
            return listState.map((m)=> ({...m, isAssigned: assignments.includes(m._id), contentCodes: []}));
        case CREDENTIALS.POST.SUCCESS:
            return common.updateItemInArray(listState, action.payload.credential.member, ({showElements: {items, credentials}, ...member}) => ({...member, showElements: {items, credentials: [...credentials, action.payload.credential._id]}}));
        case MEMBERS.UNHIDE_CREDENTIAL.SUCCESS:
            const {payload} = action;
            return common.updateItemInArray(listState, payload.memberId, ({showElements: {items, credentials}, ...member}) => ({...member, showElements: {items, credentials: [...credentials, payload.credentialId]}}));
        case MEMBERS.HIDE_CREDENTIAL.SUCCESS:
            return common.updateItemInArray(listState, action.payload.memberId, ({showElements: {items, credentials}, ...member}) => ({...member, showElements: {items, credentials: credentials.filter((c => c !== action.payload.credentialId))}}));
        case SIGN_OUT:
            return [];
        default:
            return listState;
    }
};
const progress = (progressState = null, action) => {
    switch (action.type) {
        case MEMBERS.POST.REQUEST:
            return 0;
        case MEMBERS.SHOW_PROGRESS:
            return action.percentCompleted;
        case MEMBERS.POST.SUCCESS:
        case MEMBERS.POST.FAILURE:
        case MEMBERS.UPDATE_IMAGE.SUCCESS:
        case MEMBERS.UPDATE_IMAGE.FAILURE:
        case SIGN_OUT:
            return null;
        default: return progressState;
    }
};

const selectedMember = (selectedMemberState = '', action) => {
    switch (action.type) {
        case MEMBERS.SELECT:
            return action.payload._id;
        case "@@redux-form/DESTROY": // currently don't think we need to be specific as to which form
        case SIGN_OUT:
            return '';
        default: return selectedMemberState;
    }
};

const memberReducers = {list, progress, selectedMember};

//this export for testing
export const {
    list: listReducer,
    progress: progressReducer,
    selectedMember: selectedMemberReducer
} = memberReducers;


export default combineReducers(memberReducers);