import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field, Form} from '../../utils/redux-form';
import * as formValidators from '../../utils/formValidation';
import Grid from "@material-ui/core/Grid";
import {UPDATE_PROFILE_FORM} from '../constants';
import Input from '../../components/Input';
import Button from '../../components/StandardButton';
import {phoneParser} from '../../utils/fieldParser';
import {phoneFormatter} from '../../utils/fieldFormatter';

export const UpdateProfileForm = (props) => {
    const {handleSubmit, pristine, invalid, isAdmin} = props;

    return (
        <Form onSubmit={handleSubmit}>
            <Grid item>
                <Field
                    name="token"
                    component="input"
                    type="hidden"
                />
                <Field
                    name="firstName"
                    component={Input}
                    type="text"
                    label="First Name"
                    placeholder={'First Name'}
                    autoComplete="firstname"
                />
            </Grid>
            <Grid item>
                <Field
                    name="lastName"
                    component={Input}
                    type="text"
                    label="Last Name"
                    placeholder={'Last Name'}
                    autoComplete="lastname"
                />
            </Grid>
            {
                isAdmin && <React.Fragment>
                    <Grid item>
                        <Field
                            name="jobTitle"
                            component={Input}
                            type="text"
                            label="Job Title"
                            placeholder={'Job Title'}
                            autoComplete="jobTitle"
                        />
                    </Grid>
                    <Grid item>
                        <Field
                            name="mobilePhone"
                            component={Input}
                            type="text"
                            label="Mobile Phone"
                            placeholder={'Mobile Phone'}
                            autoComplete="mobilePhone"
                            format={phoneFormatter}
                            parse={phoneParser}
                        />
                    </Grid>
                </React.Fragment>
            }
            <Grid item>
                <Button
                    disabled={pristine || invalid}
                    type="submit"
                >
                    {!isAdmin ? 'Start Free Trial' : 'Update'}
                </Button>
            </Grid>
        </Form>
    )
};

UpdateProfileForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
    form: UPDATE_PROFILE_FORM,
    enableReinitialize: true,
    validate: formValidators.createValidator({
        firstName: formValidators.required,
        lastName: formValidators.required,
        jobTitle: formValidators.required,
        mobilePhone: formValidators.required
    })
})(UpdateProfileForm);