import {AUTH} from "./actionTypes";
import {STATUS} from "./constants";

export function startListener(store) {

    window.addEventListener('storage', (event) => {
        if(event.key === 'state'){

            const {authorize: {status}} = JSON.parse(event.newValue);

            if(status === STATUS.CONFIRMED){
                store.dispatch({type: AUTH.CONFIRM_EMAIL.SUCCESS})
            }

        }
    })
}