import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = () => ({
    textField: {}
});

const StyledInput = (props) => {
    const {classes, input, label, placeholder, type, meta: {touched, error}, autoComplete, isDisabled} = props;
    return (
        <div>
            <TextField
                {...input}
                label={label}
                type={type}
                placeholder={placeholder}
                className={classes.textField}
                error={touched && Boolean(error)}
                helperText={touched && error}
                autoComplete={autoComplete}
                disabled={isDisabled}
                margin={'dense'}
                fullWidth={true}
            >
                {props.children}
            </TextField>
        </div>
    )
};

export default withStyles(styles)(StyledInput);