import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from '@material-ui/core/TableRow';
import React from "react";
import {withStyles} from "@material-ui/core";

const styles = (theme) => ({
    head: {
        color: theme.palette.text.primary
    }
});

export default withStyles(styles) ((props) => {
    const {classes} = props;
    return (
        <TableHead >
            <TableRow>
                <TableCell className={classes.head}>
                    Expires
                </TableCell>
                <TableCell className={classes.head}>
                    First Name
                </TableCell>
                <TableCell className={classes.head}>
                    Last Name
                </TableCell>
                <TableCell className={classes.head}>
                    Credential Title
                </TableCell>
                <TableCell className={classes.head}>
                    Provider
                </TableCell>
                <TableCell className={classes.head}>
                    Provider Status
                </TableCell>
            </TableRow>
        </TableHead>
    )
})