import {PROVIDERS, SIGN_OUT} from "./actionTypes";
import {combineReducers} from "redux";
import * as common from "../common/reducer";

const list = (listState = [], action) => {
    switch (action.type) {
        case PROVIDERS.GET.SUCCESS:
            return action.payload;
        case PROVIDERS.POST.SUCCESS:
            return common.addItemToArray(listState, {...action.payload.provider});
        case PROVIDERS.DELETE.SUCCESS:
            return common.removeItemFromArray(listState, action.payload.providerId);
        case PROVIDERS.PATCH.SUCCESS:
            const {_id, ...rest} = action.payload.provider;
            return common.updateItemInArray(listState, _id, (p) => ({...p, ...rest}));
        case PROVIDERS.DE_DUPLICATE.SUCCESS:
            return common.removeItemFromArray(listState, action.payload.from);
        case SIGN_OUT:
            return [];
        default:
            return listState;
    }
};

const selected = (state = '', action) => {
    switch (action.type) {
        case PROVIDERS.SELECT:
            return action.payload._id;
        case PROVIDERS.PATCH.SUCCESS:
        case "@@redux-form/DESTROY": // currently don't think we need to be specific as to which form
        case SIGN_OUT:
            return '';
        default: return state;
    }
};

const providerReducer = {list, selected};

//this export for testing
export const {
    list: listReducer
} = providerReducer;


export default combineReducers(providerReducer);