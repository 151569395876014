import validator from 'validator';
import zxcvbn from'zxcvbn';
import {isNil, isEmpty as isEmptyFn} from 'ramda';
// import {compose, prop, split, contains, not, trim, map, type, filter, propEq, propOr, head , reverse} from 'ramda';
// This file is initially from https://github.com/erikras/react-redux-universal-hot-example/blob/master/src/utils/validation.js,
// which is linked from http://erikras.github.io/redux-form/#/examples/synchronous-validation?_k=dd5lso

const isEmpty = value => value === undefined || value === null || value === '';
const join = (rules) => (value, data, props) => rules.map(rule => rule(value, data, props)).filter(error => !!error)[0 /* first error */ ];

export function email(value) {
    // Let's not start a debate on email regex. This is just for an example app!
    if (!isEmpty(value) && !validator.isEmail(value)) {
        return 'Invalid email address';
    }
}

export function required(value) {
    if (isEmpty(value)) {
        return 'Required';
    }
}

export function minLength(min) {
    return value => {
        if (!isEmpty(value) && value.length < min) {
            return `Requires ${min} characters (words are best)`;
        }
    };
}

export function minLengthAndStrength(min, strength) {
    const minLengthCheck = minLength(min);
    return value => {
        const lenCheck = minLengthCheck(value);
        if(!isEmpty(value) && typeof lenCheck === 'undefined') {
            const result = zxcvbn(value);
            if (result && result.score < strength) {
                return 'Try adding more words and spaces';
            }
        }
        return lenCheck;
    }
}

export const imageIsRequired = value => (!value ? "Required" : undefined);

export const imageIsRequiredAndNotTilde = value => (!!value && value !== '~' ? undefined : "Required" );

export function metaValidate(values) {

    if (!values) {
        return;
    }

    const errors = [];

    values.forEach((field, fieldIndex) => {
        if (!field || !field.value) {
            errors[fieldIndex] = {value: 'Required'}
        }
    });

    return errors;
}

export function addressValidate(addresses) {

    if (!addresses) {
        return;
    }

    const errors = [];

    addresses.forEach((address, fieldIndex) => {
        errors[fieldIndex] = {
            ...(isEmpty(address.street) ? {street: 'Required'} : {}),
            ...(isEmpty(address.city) ? {city: 'Required'} : {}),
            ...(isEmpty(address.state) ? {state: 'Required'} : {}),
            ...(isEmpty(address.zipCode) ? {zipCode: 'Required'} : {})
        }
    });

    return errors;
}

export function team(team, {type}) {

    if(type !== 'team') {return;}

    if(isNil(team) ||isEmptyFn(team)) { return 'Need a team';}
}

export function member(member, {type}) {

    if(type !== 'member') {return;}

    if(isEmpty(member)) {return 'Need a member'; }

}

export function createValidator(rules) {
    return (data = {}, props) => {
        const errors = {};
        Object.keys(rules).forEach((key) => {
            const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
            const error = rule(data[key], data, props);
            if (error) {
                errors[key] = error;
            }
        });
        return errors;
    };
}
