import {createSelector} from "reselect";
import {getIsExpired, getToken} from "../authorize";
import {NAME, UPDATE_ACCOUNT_FORM} from "./constants";
import {formValueSelector} from "../utils/redux-form";
import {compose} from 'ramda';

const formSelector = formValueSelector(UPDATE_ACCOUNT_FORM);

const getState = state => state[NAME];

const getAccountExists = compose(({id}) => !!id, getState);

const getTotal = (state) => {

    const items = formSelector(state, 'items');

    return !!items ? items.reduce((a, {amount, quantity}) => a + (amount / 100) * quantity, 0): 0;
};

const getActivatedMembers = compose(({activeMembers}) => activeMembers, getState);

const getActivatedContentCodes = compose(({activeCodes}) => activeCodes, getState);

const getPaymentMethods = compose(({paymentMethods}) => paymentMethods, getState);

const hasPaymentMethods = compose(methods => methods.length > 0 , getPaymentMethods);

export const mapAccountContentToProps = createSelector(
    getToken,
    getPaymentMethods,
    hasPaymentMethods,
    getIsExpired,
    getAccountExists,
    (token, paymentMethods, hasMethods, isExpired, accountExists) => ({
        token,
        paymentMethods,
        hasMethods,
        isExpired,
        accountExists
    })
);

export const mapUpdateAccountToProps = createSelector(
    getToken,
    getState,
    (token, account) => ({
        token,
        account
    })
);

export const mapUpdateAccountFormToProps = createSelector(
    getToken,
    getState,
    getTotal,
    getActivatedMembers,
    getActivatedContentCodes,
    (token, {items, coupon, until, ...account}, total, activeMembers, activeCodes) => ({
        initialValues: {
            token,
            ...account,
            items: items.map((i) => ({...i, activeCodes, activeMembers})),
        activeMembers, activeCodes
        },
        total,
        coupon,
        until
    })
);

export const mapAddPaymentFormToProps = createSelector(
    getToken,
    (token) => ({
        token
    })
);