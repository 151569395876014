import {Route, Switch} from 'react-router-dom';
import ReportViewerContainer from '../containers/ReportViewerContainer';
import React from "react";
import Content from '../containers/ReportsContentContainer';

export default () => {
    return (
        <Switch>
            <Route path={'/app/reports/:templateId'} component={ReportViewerContainer} />
            <Route path={'/'} component={Content} />
        </Switch>
    )
}