import React from 'react';
import { withStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import validationStyles, {variantIcon} from '../../../common/validation-styles';
import classNames from "classnames";
import PropTypes from "prop-types";

const styles = theme => {
    const vStyles = validationStyles(theme);
    return ({
        close: {
            padding: theme.spacing(0.5),
        },
        ...vStyles,
        message: {
            display: 'flex',
            alignItems: 'center',
        }
    })
};

export const ConnectedSnackbar = (props) => {

    const {classes, message, clearMessage, msgState, isOpen} = props;

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        clearMessage();
    }

    const Icon = variantIcon[msgState];
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={isOpen}
            autoHideDuration={3000}
            onClose={handleClose}
        >
            <SnackbarContent
                className={classNames(classes[msgState], classes.message)}
                aria-describedby="client-snackbar"
                message={<span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)}/> {message}</span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    )
};

ConnectedSnackbar.propTypes = {
    classes: PropTypes.object.isRequired,
    msgState: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    clearMessage: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,

};

export default withStyles(styles)(ConnectedSnackbar);