import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Navigation from '../../components/ConnectedNavigation';
import OrganizationViewer from "./OrganizationViewer";
import MemberViewer from "./MemberViewer";
import TeamViewer from "./TeamViewer";
import CredentialList from'./CredentialList'
import {withStyles} from '@material-ui/core';

const styles = () => {
    return ({
        root: {
            display: 'none',
            height: 0,
            width: 0,
            position: 'absolute'
        }
    })
};

class CodedContent extends React.Component {

    componentDidMount() {
        const {loadContent, token, match: {params: {id: code}}} = this.props;

        loadContent({token, code})
    }

    render() {
        const {classes, type, nickname, organization, member, team
            // , owner
        } = this.props;

        return (
            <Navigation title={'QR Content'} >
                <Typography variant="h5" gutterBottom component="h2">
                    {`${type.toUpperCase()}${!!nickname ? ' - ': ''}${nickname}`}
                </Typography>
                {
                    organization && <OrganizationViewer {...organization}/>
                }
                {
                    member && <MemberViewer {...member} component={CredentialList}/>
                }
                {
                    team && <TeamViewer team={team} component={CredentialList} />
                }
                <iframe title={'Print Content Frame'} id="ifmcontentstoprint" className={classes.root}/>
            </Navigation>
        )
    }
}

CodedContent.propTypes = {
    token: PropTypes.string,
    match: PropTypes.object.isRequired
};

export default withStyles(styles)(CodedContent);

