import {Route, Switch} from 'react-router-dom';
import AddContent from "./AddCredential";
import React from "react";
import UpdateCredentialContent from "./UpdateCredentialContent";
import CredentialContentContainer from "../containers/CredentialContentContainer";


export default () => {
    return (
        <Switch>
            <Route exact path={'/app/credentials/add'} component={AddContent} />
            <Route path={'/app/credentials/add/:memberId'} component={AddContent} />
            <Route path={'/app/credentials/:credentialId'} component={UpdateCredentialContent} />
            <Route path={'/'} component={CredentialContentContainer} />
        </Switch>
    )
}