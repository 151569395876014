import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {Image, Transformation} from "cloudinary-react";
import CloseIcon from "@material-ui/icons/Close";
import Fab from '@material-ui/core/Fab';

const styles = theme => {
    return ({
        card: {
            position: 'relative',
            maxWidth: 600,
            margin: 15
        },
        image: {
            width: '100%',
            height: 'auto'
        },
        opacitator: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '-webkit-fill-available',
            height: '-webkit-fill-available',
            zIndex: 1
        }
    })
};

class CredentialPreview extends React.Component {

    render() {
        const {classes, credentialImage, _id, handleHide} = this.props;

        return (
            <Card className={classes.card} raised>
                <div className={classes.opacitator}>
                    <Fab size="small" color="primary" aria-label="Hide" onClick={()=>handleHide(_id)} >
                        <CloseIcon/>
                    </Fab>
                </div>
                <Image publicId={credentialImage} fetch-format="auto" quality="auto"
                       className={classes.image}>
                    <Transformation crop={'fill'} width={600}/>
                </Image>
            </Card>
        );
    }
}

CredentialPreview.propTypes = {
    classes: PropTypes.object.isRequired,
    handleHide: PropTypes.func.isRequired
};

export default withStyles(styles)(CredentialPreview);
