import React from 'react';
import Link from "../../components/RouterLink";
import SignUpContainer from '../containers/SignUpFormContainer';
import Grid from "@material-ui/core/Grid";
import AuthPaper from './AuthPaper';

export const SignUp = () => (
    <div>
        <AuthPaper>
            <Grid item>
                <SignUpContainer/>
            </Grid>
            <Grid item>
                <p>I'm already registered! <Link to={`/`}>Go Home</Link></p>
            </Grid>
        </AuthPaper>
    </div>
);


export default SignUp;