import React from 'react';
import { reduxForm, Field, Form } from '../../utils/redux-form';
import Button from '../../components/Button';
import * as formValidators from '../../utils/formValidation';
import Input from '../../components/Input';
import { FORGOT_PASSWORD_FORM } from '../constants';
import PendingReset from './PendingReset';

export const ForgotPasswordForm = props => {
    const {handleSubmit, isDisabled, isFormVisible} = props;
    if (isFormVisible) {
        return (
            <Form onSubmit={handleSubmit}>
                <Field
                    name="email"
                    component={Input}
                    type="text"
                    label="Email Address"
                    placeholder={'Email Address'}
                />
                <Button
                    color='primary'
                    disabled={isDisabled}
                    type="submit">
                    Request Password Reset
                </Button>
            </Form>
        )
    }
    else {
        const {tryNewEmailAddress, email} = props;
        return (<PendingReset {...{tryNewEmailAddress, email}} />)
    }
};

export default reduxForm({
    form: FORGOT_PASSWORD_FORM ,
    validate: formValidators.createValidator({
        email: [formValidators.required, formValidators.email]
    })
})(ForgotPasswordForm);