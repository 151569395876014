import Navigation from "../../components/ConnectedNavigation";
import Typography from "@material-ui/core/Typography";
import UpdateMemberFormContainer from "../containers/UpdateMemberFormContainer";
import {withStyles} from "@material-ui/core";
import React from "react";
import Grid from "@material-ui/core/Grid";
import MemberImageFormContainer from "../containers/MemberImageFormContainer";
import AlertDialog from "../../components/AlertDialog";
import PropTypes from "prop-types/prop-types";
import PreviewDialogContainer from "../containers/PreviewDialogContainer";

const styles = () => ({
    hasMargin: {
        // margin: theme.spacing(1)
    },
});


export class UpdateMemberContent extends React.Component {
    state = {
        isReassignDialogOpen: false,
        selectedMember: {},
        open: false
    };

    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleCloseDialog = () => {
        this.setState({open: false});
    };

    onOpenReassignDialog = (event, memberInfo) => {
        this.setState({isReassignDialogOpen: !this.state.isReassignDialogOpen, selectedMember: memberInfo})
    };

    onHandleCloseReassignDialog = (event, _id) => {
        this.setState({isReassignDialogOpen: !this.state.isReassignDialogOpen, selectedMember: {}});
        if (!!_id) {
            return this.props.disassociateMember(_id);
        }
    };

    getDialogProps = (props) => ({
        title: `Reassign Member from profile for '${props.firstName} ${props.lastName}'`,
        contentText: `This member will no longer be associated with the profile for  
        '${props.firstName} ${props.lastName}' but will be set to a new member.`,
        _id: !!props.memberId ? props.memberId : ''
    });

    render() {

        const {isReassignDialogOpen, selectedMember} = this.state;

        const dialogProps = this.getDialogProps(selectedMember);

        const {classes, isAssigned} = this.props;

        const handleClickOpen = this.handleClickOpen;

        return (
            <Navigation title={'Members'}>
                <Typography variant={"h4"} gutterBottom>
                    Update Member
                </Typography>
                <Grid container
                      direction="column"
                      justify="flex-start"
                      alignItems="center"
                      spacing={4}
                >
                    <Grid item>
                        <Grid container
                              direction="column"
                              justify="flex-start"
                              alignItems="stretch"
                        >
                            <Grid item className={classes.hasMargin}>
                                <MemberImageFormContainer/>
                            </Grid>
                            <UpdateMemberFormContainer openDialog={this.onOpenReassignDialog} {...{isAssigned, handleClickOpen}}/>
                        </Grid>
                    </Grid>
                </Grid>
                <AlertDialog isOpen={isReassignDialogOpen} {...dialogProps}
                             handleClose={this.onHandleCloseReassignDialog}/>
                <PreviewDialogContainer open={this.state.open} handleClose={this.handleCloseDialog}/>
            </Navigation>

        )
    }
}

UpdateMemberContent.propTypes = {
    disassociateMember: PropTypes.func.isRequired
};

export default withStyles(styles)(UpdateMemberContent);