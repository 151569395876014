import {compose} from 'ramda';
import {createSelector} from 'reselect/lib';
import {NAME} from './constants';
import {getToken, getHome, getIsAdmin, getIsConfirmed, getIsExpired, getIsVerifier} from "../authorize";
import {getExists} from "../organization";

const getState = state => state[NAME];

const getEmail = compose(({email}) => email, getState);

const getProfile = compose(({firstName, lastName, jobTitle, mobilePhone}) => ({firstName, lastName, jobTitle, mobilePhone}), getState);

export const getIsNew = compose(({jobTitle, mobilePhone}) => !(!!jobTitle && !!mobilePhone), getState);

const getToOrganization = (state) => {
    /*
    We should go to the organization
    if the user is confirmed user
    and
    is not a new user
    and
    the organization doesn't exist
    However... the user is not a confirmed user if the user is expired.
    */
    const isNew = getIsNew(state);
    const isConfirmed = getIsConfirmed(state) && !getIsExpired(state);

    return isConfirmed ? isNew ? false : !getExists(state) : false;
};

const getAffectedProviders = compose(({affectedProviders}) => affectedProviders, getState)

export const mapStateToProps = createSelector(
    getToken,
    getEmail,
    getIsExpired,
    ( token, email, isExpired) => ({
        token,
        email,
        initialValues: {email, token},
        isExpired
    })
);

export const mapStateToChangePassword = createSelector(
    getToken,
    (token) => ({
        token,
        initialValues: {token}
    })
);

export const mapProfileContentToProps = createSelector(
    getToken,
    getIsConfirmed,
    getToOrganization,
    getIsExpired,
    getIsVerifier,
    (token, isConfirmed, goToOrganization, isExpired, isVerifier) => ({
        token,
        isConfirmed,
        goToOrganization,
        isExpired,
        isVerifier
    })
);

export const mapUpdateProfileToProps = createSelector(
    getToken,
    getProfile,
    getHome,
    getIsAdmin,
    (token, profile, home, isAdmin) => ({
        token,
        ...profile,
        initialValues: {token, ...profile},
        home,
        isAdmin
    })
);

export const mapVerifierFormToProps = createSelector(
    getToken,
    getEmail,
    getAffectedProviders,
    (token, email, affectedProviders) => ({
        token,
        verifier: email,
        affectedProviders,
        initialValues: {token}
    })
)