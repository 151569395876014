import React from 'react';
import PropTypes from 'prop-types/prop-types';
import {reduxForm, Field} from '../../utils/redux-form';
import * as formValidators from '../../utils/formValidation';
import {withStyles} from '@material-ui/core/styles';
import {UPDATE_CREDENTIAL_FORM} from '../constants';
import Input from './Input';
import Typography from "@material-ui/core/Typography";
import Button from '../../components/StandardButton';
import {Link, Redirect} from 'react-router-dom';
import DateField from "./DateField";
import Select from "../../components/SelectControl";
import {getTypeList} from "../../common/credential-styles";
import {dateFormatter} from "../../utils/fieldFormatter";
import Autocomplete from "../../components/Autocomplete";

const styles = () => ({
    button: {
        textAlign: 'right'
    }
});

const typesList = getTypeList();

export class UpdateCredentialForm extends React.Component {
    render() {
        const {classes, handleSubmit, pristine, invalid, submitting, isEmpty, providersList, membersList} = this.props;

        if (isEmpty || submitting) {
            return <Redirect to={'/app/credentials'}/>
        }

        return (
            <form onSubmit={handleSubmit}>
                        <Field
                            name="token"
                            component="input"
                            type="hidden"
                        />
                        <Field
                            name="credentialId"
                            component="input"
                            type="hidden"
                        />
                        <Field
                            name="provider"
                            component={Autocomplete}
                            type="text"
                            label="Provider"
                            options={providersList}
                        />
                        <Field
                            name="dateIssued"
                            component={DateField}
                            type="date"
                            label="Date Issued"
                            format={dateFormatter}
                        />
                        <Field
                            name="dateExpires"
                            component={DateField}
                            type="date"
                            label="Expiration Date"
                            format={dateFormatter}
                        />
                        <Field
                            name="type"
                            component={Select}
                            type="text"
                            label="Type"
                            options={typesList}
                        />
                        <Field
                            name="title"
                            component={Input}
                            type="text"
                            label="Credential Title"
                            placeholder={'Title'}
                        />
                        <Field
                            name="member"
                            component={Select}
                            type="text"
                            label="Member"
                            options={membersList}
                        />
                        <Typography className={classes.button}>
                            <Button
                                to={'../credentials'}
                                component={Link}
                                color={'inherit'}
                                type="button">
                                Cancel
                            </Button>
                            <Button
                                disabled={pristine || invalid}
                                type="submit">
                                Update Credential
                            </Button>
                        </Typography>
            </form>
        )
    }
}

UpdateCredentialForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    pristine: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    providersList: PropTypes.array.isRequired,
    membersList: PropTypes.array.isRequired,
    isEmpty: PropTypes.bool.isRequired
};

export const StyledAddCredentialForm = withStyles(styles)(UpdateCredentialForm);

export default reduxForm({
    form: UPDATE_CREDENTIAL_FORM,
    enableReinitialize: true,
    validate: formValidators.createValidator({
        member: formValidators.required,
        title: formValidators.required,
        provider: formValidators.required,
        type: formValidators.required,
        dateIssued: formValidators.required
    },)
})(StyledAddCredentialForm);