import {Route, Switch} from 'react-router-dom';
import AddMember from "./AddMember";
import React from "react";
import MemberContentContainer from '../containers/MemberContentContainer';
import UpdateMemberContentContainer from '../containers/UpdateMemberContentContainer';


export default () => {
    return (
        <Switch>
            <Route path={'/app/members/add'} component={AddMember} />
            <Route path={'/app/members/:memberId'} component={UpdateMemberContentContainer} />
            <Route path={'/'} component={MemberContentContainer} />
        </Switch>
    )
}