import React from 'react';
import { withStyles } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import Link from "../../components/Link";

const styles = () => ({
        root: {
            'text-align': 'center',
        }
    });

export const PendingReset = (props) => {
    const {email, tryNewEmailAddress, classes} = props;
    return (<React.Fragment>
        <Typography className={classes.root} variant="body2" gutterBottom component="p">
            If there is an account associated with {email} you will receive an email with a link to reset your password.
        </Typography>
        <Typography className={classes.root} variant="body1" >
            <Link color={"secondary"} onClick={tryNewEmailAddress}>Try Different Email</Link>
        </Typography>
    </React.Fragment>)
};

export default withStyles(styles)(PendingReset);