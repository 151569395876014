import actionTypeCreator, {ASYNC, SYNC} from './actionTypeCreator';

export const AUTH = actionTypeCreator('AUTH')({
    GET: ASYNC,
    POST: ASYNC,
    EMAIL: ASYNC,
    RESET: ASYNC,
    PWD: ASYNC,
    CLEAR: SYNC,
    NEW: SYNC,
    CONFIRM_EMAIL: ASYNC,
    VERIFIER: ASYNC
});

export const PROFILE = actionTypeCreator('PROFILE')({
    GET: ASYNC,
    POST: ASYNC,
    REQUEST_CONFIRM: ASYNC,
    UPDATE_EMAIL: ASYNC,
    CHANGE_PASSWORD: ASYNC,
    PUT: ASYNC,
    STOP_VERIFYING: ASYNC,
    GET_PROVIDERS: ASYNC
});

export const ORGANIZATION = actionTypeCreator('ORGANIZATION')({
    DASHBOARD: ASYNC,
    UPDATE_IMAGE: ASYNC,
    //todo move SHOW_PROGRESS to COMMON types
    SHOW_PROGRESS: SYNC,
    POST: ASYNC
});

export const MEMBERS = actionTypeCreator('MEMBERS')({
    // GET: ASYNC,
    POST: ASYNC,
    DELETE: ASYNC,
    UPDATE: ASYNC,
    //todo move SHOW_PROGRESS to COMMON types
    SHOW_PROGRESS: SYNC,
    SELECT: SYNC,
    UPDATE_IMAGE: ASYNC,
    DISASSOCIATE: SYNC,
    UNHIDE_CREDENTIAL: ASYNC,
    HIDE_CREDENTIAL: ASYNC
});

export const CREDENTIALS = actionTypeCreator('CREDENTIALS')({
    GET: ASYNC,
    POST: ASYNC,
    SELECT: SYNC,
    DELETE: ASYNC,
    UPDATE: ASYNC,
    UPDATE_IMAGE: ASYNC,
    //todo move SHOW_PROGRESS to COMMON types
    SHOW_PROGRESS: SYNC
});

export const PROVIDERS = actionTypeCreator('PROVIDERS')({
    GET: ASYNC,
    POST: ASYNC,
    DELETE: ASYNC,
    SELECT: SYNC,
    PATCH: ASYNC,
    DE_DUPLICATE: ASYNC,
    RESEND_INVITE: ASYNC
});

export const CONTENT_CODES = actionTypeCreator('CONTENT_CODES')({
    // GET: ASYNC,
    POST: ASYNC,
    SELECT: SYNC,
    PUT: ASYNC,
    DELETE: ASYNC
});

export const CODED_CONTENT = actionTypeCreator('CODED_CONTENT')({
    GET: ASYNC,
    GET_CREDENTIAL: ASYNC,
    PUT_CREDENTIAL: ASYNC
});

export const ACCOUNT = actionTypeCreator('ACCOUNT')({
    GET: ASYNC,
    DELETE: ASYNC,
    POST: ASYNC
});

export const PAYMENT_METHODS = actionTypeCreator('PAYMENT_METHODS')({
    GET: ASYNC,
    POST: ASYNC,
    DELETE: ASYNC
});

export const ADMIN = actionTypeCreator('ADMIN')({
    GET_USERS: ASYNC,
    PATCH_USER: ASYNC,
    SELECT_USER: SYNC,
    GET_COUPONS: ASYNC
});

export const REPORTS = actionTypeCreator('REPORTS')({
    GET: ASYNC,
    GET_ONE: ASYNC
});

const COMMON = actionTypeCreator('COMMON')({
    SIGN_OUT: SYNC
});

const {SIGN_OUT} = COMMON;

export {
    SIGN_OUT
}

