import React from 'react';
import Content from '../components/DashboardContent';
import {loadDashboard} from "../actions";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom'
import {mapDashboardToProps} from "../selectors";

class Wrapper extends React.Component {
    componentDidMount() {
        const {loadDashboard, token, exists} = this.props;
        if(!exists) {
            loadDashboard({token})
        }
    }

    render() {
        const {...rest} = this.props;

        return ( <Content { ...rest} /> )
    }
}

export const ConnectedWrappedContent = connect(mapDashboardToProps, {loadDashboard})(Wrapper);

export default withRouter(ConnectedWrappedContent);