import Content from './components/CredentialsRouter';
import reducer from './reducer';
import reducerRegistry from "../utils/reducerRegistry";
import {NAME} from "./constants";
import {getCredentialCounts} from "./selectors";

reducerRegistry.register(NAME, reducer);
// export default Content
export {
    Content as default,
    getCredentialCounts
}