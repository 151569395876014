import axios from 'axios';
import {CLOUDINARY} from "../config";

const {CLOUD_NAME, UPLOAD_PRESET, API_KEY, APP_FOLDER} = CLOUDINARY;

const cloudinaryApi = axios.create({
    baseURL: `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/upload/`,
    timeout: 10000,
});

const uploadPhoto = async ({image, folder}, showProgress, dispatch) => {

    const onUploadProgress= async (progressEvent) => await dispatch(showProgress(Math.round( (progressEvent.loaded * 100) / progressEvent.total )));

    const data = new FormData();
    data.append('file', image.file, image.name);
    data.append("api_key", API_KEY);
    data.append("upload_preset", UPLOAD_PRESET);
    data.append("folder" , folder);

    const config = {
        headers: {
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        },
        onUploadProgress,
        timeout: 30000,
    };
    return cloudinaryApi.post('/', data, config).then((response) => {
        const {data: {public_id}} = response;

        return public_id;
    });
};

export {uploadPhoto, APP_FOLDER};