import Content from '../components/AddProviderForm';
import {addProvider} from "../actions";
import {connect} from "react-redux";
import {mapAddProviderToProps} from "../selectors";

const mergeProps = function ({initialValues, ...rest} , {addProvider}, {search, handleClose, ...props}) {

    return {
        ...rest,
        ...props,
        handleClose,
        initialValues: {...initialValues, name: !!search ? search : ''},
        onSubmit: (values) => addProvider(values)
            .then(({payload:{provider}}) => handleClose(provider._id))//<<<<<<< todo this is working but fragile
    }
};

export default connect(mapAddProviderToProps, {addProvider}, mergeProps)(Content);