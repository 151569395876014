import {withStyles} from "@material-ui/core";
import GridList from "@material-ui/core/GridList";
import CredentialPreview from "../containers/CredentialPreviewContainer";
import HiddenPreview from '../containers/HiddenPreviewContainer';
import React from "react";

const styles = () => {
    return ({
        gridList: {
            maxWidth: 600,
        }
    })
};

const CredentialPreviewerList = (props) => {
    const {classes, credentials, showElements} = props;

    return(
        <div>
            <GridList className={classes.gridList}>
                {credentials.map((c) => showElements.credentials.includes(c._id) ? <CredentialPreview key={c._id} {...c} /> : <HiddenPreview key={c._id} {...c} /> )}
            </GridList>
        </div>
    )
};

export default withStyles (styles)(CredentialPreviewerList);