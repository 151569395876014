import React from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LogoSvg from "../../common/content/logo";
import {makeStyles} from "@material-ui/core";
import ConnectedSnackbar from '../../components/ConnectedSnackbar';
import ConnectedLoadingIndicator from "../../components/ConnectedLoadingIndicator";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: props => !!props.width ? props.width : 348,
        marginTop: 25
    },
    grid: {
        flexGrow: 1,
        justify: 'center',
        alignItems: 'center'
    },
    logo: {
        width: 316,
        height: 267,
    }
}));

export default ({width, children}) => {

    const classes = useStyles({width})

    return (
        <div>
            <Paper className={classes.paper}>
                <Grid container direction='column' className={classes.grid}>
                    <Grid item>
                        <LogoSvg className={classes.logo}/>
                        <ConnectedLoadingIndicator />
                    </Grid>
                    {children}
                </Grid>
            </Paper>
            <ConnectedSnackbar />
        </div>
    );
};