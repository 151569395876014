
const removeItemFromArray = (array, itemId) => {
    return array.filter(item => item._id !== itemId);
};

const addItemToArray = (array, item) => {
    return [...array, item];
};

const updateItemInArray = (array, itemId, updateItemCallback) => {
    return array.map(item => {

        if (item._id !== itemId) return item;

        // Use the provided callback to create an updated item
        return updateItemCallback(item);
    });
};

export {removeItemFromArray, addItemToArray, updateItemInArray}