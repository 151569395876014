import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types/prop-types";
import AddProviderFormContainer from "../containers/AddProviderFormContainer";

class AddProviderDialog extends React.Component {
    render() {

        const {isOpen, handleClose, search} = this.props;
        return (
            <div>
                <Dialog
                    open={isOpen}
                    onClose={handleClose}
                    aria-labelledby="add-provider-dialog-title"
                    aria-describedby="add-provider-dialog-description"
                >
                    <DialogTitle id="add-provider-dialog-title">Add Provider</DialogTitle>
                    <DialogContent>
                        <AddProviderFormContainer handleClose={handleClose} search={search}/>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

AddProviderDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default AddProviderDialog;
