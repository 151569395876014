import React from 'react';
import {Field} from '../../utils/redux-form';
import Input from "../../components/Input";
import Grid from "@material-ui/core/Grid";
import AddFieldDialog from "./AddFieldDialog";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {DeleteIcon} from '../../common/icons';
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types/prop-types";

class MetaField  extends React.Component {
    state = {
        open: false
    };

    handleClickOpen = () => {
        this.setState({open: true})
    };

    handleClose = (e, field) => {
        !! field && this.props.fields.push({name: field, value: ''});
        this.setState({open: false})
    };

    render() {
        const {fields} = this.props;
        const fieldArray = fields.getAll();
        const currentFields = !!fieldArray ? fieldArray.map((f)=>f.name) : [];
        return (
            <React.Fragment>
                <Grid item container direction='column' >
                    {fields.map((opt, index, fields) => {
                        return (
                        <Grid item container key={index} direction='row'
                              justify="flex-end"
                              alignItems="center"
                        >
                            <Grid item sm={11}>
                                <Field
                                    name={`${opt}.value`}
                                    component={Input}
                                    label={`${fields.get(index).name}`}
                                />
                            </Grid>
                            <Grid item sm={1}>
                                <Tooltip title="Delete">
                                    <IconButton aria-label="Delete" onClick={() => fields.remove(index)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>

                    )})}
                </Grid>
                <Grid item>
                    <Button
                        color={'default'}
                        onClick={this.handleClickOpen}
                    >
                        Add More Information
                    </Button>
                </Grid>
                <AddFieldDialog isOpen={this.state.open} onClose={this.handleClose} currentFields={currentFields} />
            </React.Fragment>
        )
    }
}

MetaField.propTypes = {
    fields: PropTypes.object.isRequired
};

export default MetaField;