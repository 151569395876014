import Navigation from "../../components/ConnectedNavigation";
import Typography from "@material-ui/core/Typography";
import AddCredentialFormContainer from "../containers/AddCredentialFormContainer";
import {withStyles} from "@material-ui/core";
import React from "react";

const styles = () => ({

});

export const AddCredential = (props) => {
    const params = props.match.params;
    return (
        <Navigation title={'Add Credential'}>
            <Typography variant={"h4"} gutterBottom>
                Add Credential
            </Typography>
            <AddCredentialFormContainer {...params}/>
        </Navigation>
    )
};

export default withStyles(styles)(AddCredential);