import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import {withStyles} from "@material-ui/core/styles";
import React from "react";

const styles = theme => {
    const color = theme.palette.getContrastText(theme.palette.action.active);
    return ({
        root: {
            width: 54,
            backgroundColor: theme.palette.action.active,
            marginTop: 6

        },
        input: {
            marginLeft: 12,
            textAlign: 'center',
            ...theme.typography.body2,
            color
        }
    })
};

const InputGridItem = ({classes, input, name, ...rest}) => (
    <Grid item {...rest} className={classes.container}>
        <Input step={'1'} type={'number'}
               disableUnderline
               classes={{
                   root: classes.root,
                   input: classes.input
               }}
               {...input}
        />
    </Grid>
);

export default withStyles(styles)(InputGridItem);