import {ADMIN} from "./actionTypes";
import db from "../utils/dbAccess";
import {handleError} from "../common/actions";
import {states} from "../common/validation-styles";

const {error} = states;

const loadUsersRequest = () => ({
    type: ADMIN.GET_USERS.REQUEST
});

const loadUsersFailure = (message) => ({
    type: ADMIN.GET_USERS.FAILURE,
    payload: {msgState: error, message}
});

const loadUsersSuccess = (payload) => ({
    type: ADMIN.GET_USERS.SUCCESS,
    payload
});

export const loadUsers = ({token}) => (dispatch) => {
    dispatch(loadUsersRequest());

    return db.getUsers({token})
        .then(({data: {payload}}) => dispatch(loadUsersSuccess(payload)))
        .catch((e) => dispatch(handleError(e, loadUsersFailure)))
};

export const patchUser = (addrs) => (dispatch) => {
    const patchUserRequest = () => ({
        type: ADMIN.PATCH_USER.REQUEST
    });

    const patchUserFailure = (message) => ({
        type: ADMIN.PATCH_USER.FAILURE,
        payload: {msgState: error, message}
    });

    const patchUserSuccess = (payload) => ({
        type: ADMIN.PATCH_USER.SUCCESS,
        payload
    });

    dispatch(patchUserRequest());

    return db.patchUser(addrs)
        .then(({data: {payload}}) => dispatch(patchUserSuccess(payload)))
        .catch((e) => dispatch(handleError(e, patchUserFailure)))
};

export const selectUser = (event, payload) => ({
    type: ADMIN.SELECT_USER,
    payload
});

export const loadCoupons = ({token}) => (dispatch) => {
    const loadCouponsRequest = () => ({
        type: ADMIN.GET_COUPONS.REQUEST
    });

    const loadCouponsFailure = (message) => ({
        type: ADMIN.GET_COUPONS.FAILURE,
        payload: {msgState: error, message}
    });

    const loadCouponsSuccess = (payload) => ({
        type: ADMIN.GET_COUPONS.SUCCESS,
        payload
    });
    
    dispatch(loadCouponsRequest());

    return db.getCoupons({token})
        .then(({data: {payload}}) => dispatch(loadCouponsSuccess(payload)))
        .catch((e) => dispatch(handleError(e, loadCouponsFailure)))
};