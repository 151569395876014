import React from "react";
import {InputLabel} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {SendEmailIcon} from "../../common/icons";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import STATUS_TEXT from './statusText'

const useStyles = makeStyles((theme) => ({
    viewer: {
        marginTop: theme.spacing(0.5),
        // marginBottom: theme.spacing(2)
    },
    flexed: {
        flex: 1
    }
})
);

export default (props) => {

    const classes = useStyles();

    const {verifier, resendInvite, token, name} = props;
    return (
        <div className={classes.viewer}>
            <Grid container direction={'column'} >
                <Grid item>
                    <InputLabel shrink >
                        {`Verifier Email`}
                    </InputLabel>
                </Grid>
                <Grid item container direction={'row'} className={classes.flexed} justify={'space-between'} alignItems={'baseline'}>
{/* we are disabling this for now... workaround it to change the status of the provider to something other than Verifier and update.*/}
                    {/*                    <Grid item>
                        <Tooltip title="Delete Verifier">
                            <IconButton
                                aria-label="Delete Verifier"
                                // onClick={handlePreview}
                                // disabled={!(pristine || invalid)}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>*/}
                    <Grid item container direction={'row'} className={classes.flexed} spacing={2} alignItems={'baseline'}>
                        <Grid item >
                            <Typography variant={"body1"} >
                                {verifier.email}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"body2"} color={"textSecondary"} >
                                {STATUS_TEXT[verifier.status]}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Resend Invitation">
                            <IconButton
                                aria-label="Resend Invitation"
                                onClick={() => resendInvite({verifierEmail: verifier.email, token, name})}
                                disabled={verifier.status === 'confirmed'}
                            >
                                <SendEmailIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}