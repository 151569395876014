import React from 'react';
import Link from "../../components/RouterLink";
import SignInFormContainer from '../containers/SignInFormContainer';
import AppBar from "../../components/NoAccountAppBar";
import Grid from '@material-ui/core/Grid';
import AuthPaper from './AuthPaper';

export const SignIn = () => {
    return (
        <div>
            <AppBar/>
            <AuthPaper>
                <Grid item>
                    <SignInFormContainer/>
                </Grid>
                <Grid item>
                    <Link to={`/auth/forgotPassword`}>Forgot Password</Link>
                </Grid>
            </AuthPaper>
        </div>
    );
};

export default SignIn;