import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import cx from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {Image, Transformation} from "cloudinary-react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import StatusIcon from "../../components/StatusIcon";
import Tooltip from "@material-ui/core/Tooltip";
import credentialStyles, {getStatusTitle, STATUS_COLOR, STATUS_ICON} from "../../common/credential-styles";
import ProviderText from "../../components/ProviderText";
import cloudinary from 'cloudinary-core';
import {CLOUDINARY} from "../../config";
import {expirationFormatter} from "../../utils/fieldFormatter";

const cloudinaryCore = new cloudinary.Cloudinary({cloud_name: CLOUDINARY.CLOUD_NAME});

const styles = theme => {
    const cStyles = credentialStyles(theme);
    return ({
        card: {
            maxWidth: 600,
            margin: 15
        },
        actions: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        expand: {
            transform: 'rotate(0deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        spaceIt: {
            marginLeft: 'auto',
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        backside: {
            margin: 15
        },
        image: {
            width: '100%',
            height: 'auto'
        },
        ...cStyles
    })
};

const download = ({credentialImage, title}) => {
    const downloadUrl = `${cloudinaryCore.url(credentialImage)}.jpg`;
    fetch(downloadUrl)
        .then((img) => img.blob())
        .then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.href = url;
            link.download = `${title}.jpg`;
            link.click();
        })
};

function imagetoPrint(source, title) {
    return `<html lang="en">
                <head>
                    <script>
                        function loadHandler() {window.print();window.close()}
                    </script><title>QRedify</title>
                </head>
                <body onload=loadHandler()>
                    <img src='${source}'  alt='${title}'/>
                </body>
            </html>`;
}

const print = (publicKey, title) => {

    const downloadUrl = `${cloudinaryCore.url(publicKey)}.jpg`;
    fetch(downloadUrl)
        .then((img) => img.blob())
        .then((blob) => {
            let url = window.URL.createObjectURL(blob);

            var pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(imagetoPrint(url, title));
            pri.document.close();
        });
};

const email = (credentialImage, params) => {
    const downloadUrl = `${cloudinaryCore.url(credentialImage)}.jpg`;

    const url = `mailto:?subject=image of credential&body= Here is the data: ${JSON.stringify(params, null, '\t')}\r\n Here is the image of the credential ${downloadUrl}`;

    let link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    // link.download = `${title}.jpg`;
    link.click();
};

class FlipCard extends React.Component {
    state = {
        anchorEl: null,
        expanded: false,
        componentRef: ''
    };

    handleExpandClick = () => {
        this.setState(state => ({expanded: !state.expanded}));
    };

    handleClick = (event) => {

        const target = event.currentTarget;
        return this.setState({anchorEl: target});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    render() {
        const {
            classes, credentialImage, title, type, status: orgStatus, providerStatus,
            providerName, dateExpires, rejectReason, dateStatused
        } = this.props;

        const expires = expirationFormatter(dateExpires);

        const status = getStatusTitle(orgStatus, rejectReason, dateStatused);

        const emailParams = {title, type, status, providerName, expires};

        return (
            <Card className={classes.card} raised>
                <CardActions className={classes.actions} disableSpacing>
                    <IconButton
                        className={cx(classes.expand, {
                            [classes.expandOpen]: this.state.expanded,
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="Show more"
                    >
                        <ExpandMoreIcon/>
                    </IconButton>
                    <span className={classes.spaceIt} style={{color: STATUS_COLOR[orgStatus]}}>
                    <Tooltip title={status}>
                        <span><StatusIcon icon={STATUS_ICON[orgStatus]} /></span>
                    </Tooltip>
                    </span>
                    <IconButton className={classes.spaceIt} onClick={this.handleClick}>
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu id={`-contentCodesMenu`}
                          anchorEl={this.state.anchorEl}
                          open={!!this.state.anchorEl}
                          onClose={this.handleClose}>
                        <MenuItem key={'print'} onClick={() => print(credentialImage, title)}>{`Print`}</MenuItem>
                        <MenuItem key={'email'} onClick={() => email(credentialImage, emailParams)}>{`Email`}</MenuItem>
                        <MenuItem key={'download'}
                                  onClick={() => download({credentialImage, title})}>{`Download`}</MenuItem>
                    </Menu>
                </CardActions>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent className={cx(classes.backside, classes[type.toLowerCase()])}>
                        <Typography variant="h6" display={'inline'}>
                            {'Title'}
                        </Typography>
                        <Typography variant="body1" gutterBottom component="h2">
                            {title}
                        </Typography>
                        <Typography variant="h6" display={'inline'}>
                            {'Type'}
                        </Typography>
                        <Typography variant="body1" gutterBottom component="h2">
                            {type}
                        </Typography>
                        <Typography variant="h6" display={'inline'}>
                            {'Credential status '}
                            <span className={classes.spaceIt} style={{color: STATUS_COLOR[orgStatus]}}>
                    <Tooltip title={status}>
                        <span>
                        <StatusIcon icon={STATUS_ICON[orgStatus]} />
                        </span>
                    </Tooltip>
                    </span>
                        </Typography>
                        <Typography variant="body1" gutterBottom component="h2">
                            {status}
                        </Typography>
                        <Typography variant="h6" display={'inline'}>
                            {'Provider'}
                        </Typography>
                        <ProviderText name={providerName} status={providerStatus}/>
                        <Typography variant="h6" display={'inline'}>
                            {'Expiration'}
                        </Typography>
                        <Typography variant="body1" gutterBottom component="h2">
                            {expires}
                        </Typography>
                    </CardContent>
                </Collapse>
                <Image publicId={credentialImage} fetch-format="auto" quality="auto"
                       className={classes.image}>
                    <Transformation crop={'fill'} width={600}/>
                </Image>
            </Card>
        );
    }
}

FlipCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FlipCard);
