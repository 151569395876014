import {combineReducers} from 'redux'
import {SIGN_OUT, PROFILE} from './actionTypes';

const email = (emailState = '', action) => {
    switch (action.type) {
        case PROFILE.UPDATE_EMAIL.SUCCESS:
        case PROFILE.POST.SUCCESS:
        case PROFILE.GET.SUCCESS:
            return action.payload.email;
        case SIGN_OUT:
            return '';
        default: return emailState;
    }
};

const firstName = (firstNameState = '', action) => {
    switch (action.type) {
        case PROFILE.GET.SUCCESS:
        case PROFILE.PUT.SUCCESS:
            return !!action.payload.firstName ? action.payload.firstName : '';
        case SIGN_OUT:
            return '';
        default: return firstNameState;
    }
};

const lastName = (lastNameState = '', action) => {
    switch (action.type) {
        case PROFILE.GET.SUCCESS:
        case PROFILE.PUT.SUCCESS:
            return !!action.payload.lastName ? action.payload.lastName : '';
        case SIGN_OUT:
            return '';
        default: return lastNameState;
    }
};

const mobilePhone = (mobilePhoneState = null, action) => {
    switch (action.type) {
        case PROFILE.GET.SUCCESS:
        case PROFILE.PUT.SUCCESS:
            return !!action.payload.mobilePhone ? action.payload.mobilePhone : null;
        case SIGN_OUT:
            return null;
        default: return mobilePhoneState;
    }
};

const jobTitle = (jobTitleState = null, action) => {
    switch (action.type) {
        case PROFILE.GET.SUCCESS:
        case PROFILE.PUT.SUCCESS:
            return !!action.payload.jobTitle ? action.payload.jobTitle : null;
        case SIGN_OUT:
            return null;
        default: return jobTitleState;
    }
};

const affectedProviders = (state = [], action) => {
    switch (action.type) {
        case PROFILE.GET_PROVIDERS.SUCCESS:
            return action.payload.map(({name}) => name)
        case "@@redux-form/DESTROY":
        case SIGN_OUT:
            return '';
        default: return state;
    }
}

const profileReducers = {email, firstName, lastName, mobilePhone, jobTitle, affectedProviders};

//this export for testing
export const {
    email : emailReducer,
    firstName : firstNameReducer,
    lastName : lastNameReducer,
    mobilePhone: mobilePhoneReducer,
    jobTitle: jobTitleReducer
} = profileReducers;


export default combineReducers(profileReducers);