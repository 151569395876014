import React from 'react';
import {Field} from '../../utils/redux-form';
import Input from "../../components/Input";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {DeleteIcon} from '../../common/icons';
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types/prop-types";
import {zipParser} from '../../utils/fieldParser';
import {zipFormatter} from '../../utils/fieldFormatter';
import Select from "../../components/SelectControl";
import {getStatesList} from '../../common/data/states';

const stateList = getStatesList();

class AddressField  extends React.Component {

    handleClickOpen = () => {
        this.props.fields.push({
            street: '',
            city: '',
            state: '',
            zipCode: ''
        })
    };

    render() {
        const {fields} = this.props;
        return (
            <React.Fragment>
                <Grid item container direction='column' >
                    {fields.map((address, index, fields) => {
                        return (
                        <Grid item container key={index} direction='row-reverse'
                              justify="flex-end"
                              alignItems="center"
                        >
                            <Grid item sm={11}>
                                <Field
                                    name={`${address}.street`}
                                    component={Input}
                                    label={`Street`}
                                />
                                <Field
                                    name={`${address}.city`}
                                    component={Input}
                                    label={`City`}
                                />
                                <Field
                                    name={`${address}.state`}
                                    component={Select}
                                    type="text"
                                    label="State"
                                    options={stateList}
                                />
                                <Field
                                    name={`${address}.zipCode`}
                                    component={Input}
                                    label={`Zip Code`}
                                    format={zipFormatter}
                                    parse={zipParser}
                                />
                            </Grid>
                            <Grid item sm={1}>
                                <Tooltip title="Delete this address">
                                    <IconButton aria-label="Delete this address" onClick={() => fields.remove(index)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>

                    )})}
                </Grid>
                <Grid item>
                    <Button
                        type="button"
                        color={'default'}
                        onClick={this.handleClickOpen}
                    >
                        Add an address
                    </Button>
                </Grid>
            </React.Fragment>
        )
    }
}

AddressField.propTypes = {
    fields: PropTypes.object.isRequired
};

export default AddressField;