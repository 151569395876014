import {createSelector} from "reselect";
import {getToken} from "../authorize";
import {compose, isEmpty, length, not, juxt, omit} from "ramda";
import {NAME, UPDATE_CODE_FORM, ADD_CODE_FORM} from "./constants";
import {getMemberListItems} from "../members";
import {getName, getAvailable} from "../organization";
import {formValueSelector} from '../utils/redux-form';
import {getMetaObject, getOrganization, getLogoImage} from "../organization";
import {getMemberAndCredentials, getdMemberImageByMemberId} from "../members";
import {NAME as CREDENTIALS} from "../credentials/constants";

const updateFormSelector = formValueSelector(UPDATE_CODE_FORM);
const addFormSelector = formValueSelector(ADD_CODE_FORM);

const getState = state => state[NAME];

const getContentCodes = compose(({list}) => (list), getState);

export const getExists = compose(not, isEmpty, getContentCodes);

export const getActivatedContentCodes = compose(length, getContentCodes);

const getSelectedCode = compose(
    ([filtered]) => ({...filtered}),
    ({list, selectedCode}) => list.filter((c)=> c.code === selectedCode),
    getState
);

const getUpdateFormValues = (state) => ({...updateFormSelector(state, 'type', 'code', 'nickname', 'team', 'member')});
const getAddFormValues = (state) => ({...addFormSelector(state, 'type', 'nickname', 'team', 'member')});

const getAvailableCodes = compose(({qrCodes: available}) => (available), getAvailable);

const getActOfAvailable = compose(([available, activated]) =>  (`(${activated} of ${available})`), juxt([getAvailableCodes, getActivatedContentCodes]));

const getCredentialsList = compose(({list}) => (list), state => state[CREDENTIALS]);
const getCredentialsExist = compose((c) => c.length > 0, getCredentialsList);

const getCodePreview = (state) => {
    const formValues = getUpdateFormValues(state);

    const {type, member, team, ...rest} = formValues;
    // const {codeType: type, codeValue: code} = values;

    const getOrg = () => {
        const organization = getOrganization(state);
        const logoImage = getLogoImage(state);
        const meta = getMetaObject(state);
        return ({
            organization: {
                ...organization,
                meta,
                logoImage
            }
        })
    };

    const getMem = (memberId) => {
        const {credentials, member} = getMemberAndCredentials(memberId, state);
        const credentialsExist = getCredentialsExist(state);
        const {profileImage} = getdMemberImageByMemberId(state, memberId);
        const token = getToken(state);
        return ({
            member: {
                ...omit(['_id', 'isAssigned', 'contentCodes', 'profileId'], member),
                credentials,
                profileImage
            },
            credentialsExist,
            token
        })
    };

    const getTeam = team => {
        return {team: !!team ? team.map((memberId) => {

            const {member} = getMem(memberId);

                    return {...member}
        })
         : []}
    };



    return {
        // ...(type === 'organization' ? getOrg(): {}),
        ...getOrg(),
        ...getMem(member),
        ...getTeam(team),
        type,
        ...rest
    }
};

export const mapContentCodesToProps = createSelector(
    getToken,
    getContentCodes,
    getAvailableCodes,
    getActOfAvailable,
    getActivatedContentCodes,
    getExists,
    (token, contentCodes, available, actOfAvail, activated, exists) => ({
        token,
        contentCodes,
        actOfAvail,
        available,
        exists,
        activated
    })
);

export const mapUpdateCodeToProps  = createSelector(
    getToken,
    getSelectedCode,
    getMemberListItems,
    getName,
    getUpdateFormValues,
    (token, selectedCode, membersList, name, {type, code}) => ({
        isEmpty: isEmpty(selectedCode),
        type,
        code,
        initialValues: {
            token,
            ...selectedCode,
            // team: [],
            organizationName: name
        },
        membersList
    })
);

export const mapPreviewToProps = createSelector(
    getCodePreview,
    (content) => ({
        ...content
    })
);

export const mapAddCodeToProps = createSelector(
    getToken,
    getMemberListItems,
    getAddFormValues,
    getName,
    getExists,
    (token, membersList, {type}, name, exists) => ({
        type,
        initialValues: {
            token,
            type: 'organization',
            organizationName: name
        },
        membersList,
        exists
    })
);

export const mapCodesToProps = createSelector(
    getToken,
    (token) => ({
        token
    })
);