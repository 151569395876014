import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field, Form} from '../../utils/redux-form';
import * as formValidators from '../../utils/formValidation';
import Grid from "@material-ui/core/Grid";
import {CHANGE_PASSWORD_FORM} from '../constants';
import Password from "../../authorize/components/ValidatorPassword";
import Button from '../../components/StandardButton';

export const ChangePasswordForm = (props) => {
    const {handleSubmit, submitting, pristine, invalid, onChangeClick, isChanging} = props;

    //using this handler so that we can reset the ProfileContent isChanging state after the form is submitted.
    const submitHandler = (toChange) => (form) => {
        handleSubmit(form)
            .then(() => {
                onChangeClick(toChange);
            })
    };

    return (
        <Form onSubmit={handleSubmit}>

            {isChanging &&
            <Grid item>
                <Field
                    name="token"
                    component="input"
                    type="hidden"
                />
                <Field
                    name="password"
                    component={Password}
                    label="New Password"
                />
            </Grid>
            }
            <Grid item>
                {isChanging &&
                <Button
                    disabled={pristine || submitting || invalid}
                    type="button"
                    onClick={submitHandler(isChanging)}
                >
                    Update
                </Button>
                }
                <Button
                    type="button"
                    variant={'outlined'}
                    color={'default'}
                    onClick={onChangeClick}
                >
                    {
                        isChanging ? 'Cancel' : 'Change Password'
                    }
                </Button>
            </Grid>
        </Form>
    )
};

ChangePasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
    form: CHANGE_PASSWORD_FORM,
    enableReinitialize: true,
    validate: formValidators.createValidator({
        password: [formValidators.required, formValidators.minLengthAndStrength(8, 3)]
    })
})(ChangePasswordForm);