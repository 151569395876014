import React from 'react';
import {connect} from 'react-redux';
import {clearAuthMessage} from '../actions';
import { mapSnackbarToProps } from '../selectors';
import ConnectedSnackbar from "../components/ConnectedSnackbar";

const ConnectedSnackbarContainer = (props) => {

    const {clearAuthMessage, ...rest} = props;
    return (
            <ConnectedSnackbar {...rest} isOpen={!!props.message} clearMessage={() => clearAuthMessage()} />
    )
};

export default connect(mapSnackbarToProps, {clearAuthMessage})(ConnectedSnackbarContainer);
