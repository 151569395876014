import React from 'react';
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/core";
import Navigation from '../../components/ConnectedNavigation';
import MemberListContainer from "../containers/MemberListContainer";
import AlertDialog from '../../components/AlertDialog';
import PropTypes from "prop-types/prop-types";

const styles = (theme) => ({
    grid: {
        flexGrow: 1,
        alignItems: 'center'
    },
    gridItem: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    nonConfirmed: {
        color: theme.palette.error.dark
    }
});

export class MembersContent extends React.Component {
    state = {
        isOpen: false,
        selectedMember: {}
    };

    onOpenDialog = (event, member) => {
        this.setState({isOpen: !this.state.isOpen, selectedMember: member})
    };

    onHandleClose = (event, memberId) => {
        this.setState({isOpen: !this.state.isOpen, selectedMember: {}});
        if(!!memberId) {
            const {token, deleteMember} = this.props;
            return deleteMember({token, memberId})
        }
    };

    getDialogProps = ({firstName, lastName, _id = ''}) => ({
        title: `Delete Member '${firstName} ${lastName}'`,
        contentText: `This means ${firstName}'s record including photo and all credentials will be deleted.`,
        _id
    });

    render() {

        const{isOpen, selectedMember} = this.state;

        const dialogProps = this.getDialogProps(selectedMember);

        return (
            <Navigation title={'Members'}>
                <Typography variant={"h4"} gutterBottom>
                    Members
                </Typography>
                <MemberListContainer openDialog={this.onOpenDialog}/>
                <AlertDialog isOpen={isOpen} {...dialogProps} handleClose={this.onHandleClose}/>
            </Navigation>
        )
    }
}

MembersContent.propTypes = {
    classes: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired,
    deleteMember: PropTypes.func.isRequired
};

export default withStyles(styles)(MembersContent);