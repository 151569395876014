import {ADMIN, SIGN_OUT} from "./actionTypes";
import {combineReducers} from "redux";
import * as common from "../common/reducer";

const usersList = (listState = [], action) => {
    switch (action.type) {
        case ADMIN.GET_USERS.SUCCESS:
            return action.payload;
        case ADMIN.PATCH_USER.SUCCESS:
            const {_id, ...rest} = action.payload;
            return common.updateItemInArray(listState, _id, (u) => ({...u, ...rest}));
        case SIGN_OUT:
            return [];
        default:
            return listState;
    }
};

const couponList = (listState = [], action) => {
    switch (action.type) {
        case ADMIN.GET_COUPONS.SUCCESS:
            return action.payload;
        case SIGN_OUT:
            return [];
        default:
            return listState;
    }
};

const selectedUser = (selectedUserState = '', action) => {
    switch (action.type) {
        case ADMIN.SELECT_USER:
            return action.payload._id;
        case "@@redux-form/DESTROY": // currently don't think we need to be specific as to which form
        case SIGN_OUT:
            return '';
        default: return selectedUserState;
    }
};

const adminReducer = {usersList, selectedUser, couponList};

//this export for testing
export const {
    usersList: userListReducer,
    selectedUser: selectedUserReducer
} = adminReducer;


export default combineReducers(adminReducer);