import React from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import RowItem from "./DataTableRow";
import DataTableHead from "./DataTableHead";
import DataTableFoot from "./DataTableFoot";
import {withStyles} from "@material-ui/core";
import NoDataRowItem from "./NoDataRowItem";

const styles = () => ({
    root: {
        // border: '1px solid'
    },
    60: {
        fontWeight: 600,
        color: '#f1c232'
    },
    30: {
        fontWeight: 600,
        color: '#ff0000'
    },
    90: {
        fontWeight: 600,
        color: '#0000ff'
    }
});

export default withStyles(styles) ((props) => {
    const {dataset, classes} = props;
    return (
        <Table className={classes.root}>
            <DataTableHead />
            <TableBody>
                {!!dataset && dataset.length > 0 ? dataset.map((d, i) => <RowItem key={i} {...d} {...{classes}}/>) : <NoDataRowItem/>}
            </TableBody>
            <DataTableFoot {...{classes}} />
        </Table>
    )
})
