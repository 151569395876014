import React from 'react';
import Link from "../../components/RouterLink";
import ForgotPasswordFormContainer from '../containers/ForgotPasswordFormContainer';
import Grid from "@material-ui/core/Grid";
import AuthPaper from './AuthPaper';

export const ForgotPassword = () => {

    return (
        <div>
            <AuthPaper>
                <Grid item>
                    <ForgotPasswordFormContainer/>
                </Grid>
                <Grid item>
                    <p><Link to={`/`}>Go Home</Link></p>
                </Grid>
            </AuthPaper>
        </div>
    );
};

export default ForgotPassword;