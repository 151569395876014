import React from 'react';
import { withRouter } from 'react-router-dom'
import {connect} from 'react-redux';
import Content from '../components/ConfirmEmailContent';
import {loadConfirmEmail} from '../actions';
import { mapConfirmEmailToProps } from '../selectors';

class Wrapper extends React.Component {
    componentDidMount() {
        const {loadConfirmEmail, match: {params: {confirm}}} = this.props;
        loadConfirmEmail({token: confirm, status: 'confirmed'})
    }

    render() {
        const {...rest} = this.props;
        return ( <Content { ...rest} /> )
    }
}

export const ConfirmEmailContainer = connect(mapConfirmEmailToProps, {loadConfirmEmail})(Wrapper);

export default withRouter(ConfirmEmailContainer);