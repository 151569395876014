import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Navigation from '../../components/ConnectedNavigation';
import {makeStyles} from '@material-ui/core';
import FlipCard from "./FlipCard";
import Grid from "@material-ui/core/Grid";
import Button from "../../components/StandardButton";
import {RejectedIcon, VerifiedIcon} from "../../common/icons";
import {getStatusText} from "../../common/credential-styles";
import {isEmpty} from 'ramda';
import Typography from "@material-ui/core/Typography";
import RejectDialog from "./RejectDialog";

const useStyles = makeStyles((theme) => {
    return ({
        root: {
            display: 'none',
            height: 0,
            width: 0,
            position: 'absolute'
        },
        reject: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.getContrastText(theme.palette.error.main)
        },
        verify: {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.getContrastText(theme.palette.success.main)
        }
    })
});

const VerifyContent = (props) => {


    const [isOpen, setIsOpen] = useState(false);

    const classes = useStyles();

    const {match: {params: {id: credentialId}}, loadCredential, credential, statifyCredential, token} = props;

    useEffect(() => {

        const loadNow = () => loadCredential({token, credentialId});

        if (!!credentialId) {
            loadNow()
        }

    }, [token, credentialId, loadCredential]);

    const handleClick = (status) => {
        return statifyCredential({token, credentialId, status})
    }

    const handleReject = () => {
        setIsOpen(true)
    }

    const getDialogProps = () => {
        const title = `Reject this credential`;
        const contentText = `What is the reason you are rejecting this credential?`;
        return {
            title,
            contentText
        }
    };

    const handleClose = (e, rejectReason) => {
        setIsOpen(false)
        if (!rejectReason) {
            return
        }

        return statifyCredential({token, credentialId, status: 'rejected', rejectReason});
    }

    const isVerified = credential.status !== 'provider_verifying';

    const dialogProps = getDialogProps();

    return (
        <Navigation title={`${getStatusText(credential.status) || 'Updated'} Credential`}>
            {!isEmpty(credential) ?
                <Grid container direction={'column'} alignItems={'center'}>
                    <Grid item>
                        <FlipCard {...credential}/>
                    </Grid>
                    {!!credential.verifiable && !isVerified &&
                    <Grid item container justify={'space-evenly'}>
                        <Button className={classes.reject}
                                onClick={handleReject}
                                type="button">
                            <RejectedIcon/>
                            Reject
                        </Button>
                        <Button className={classes.verify}
                                onClick={() => handleClick('verified')}
                                type="button">
                            <VerifiedIcon/>
                            Verify
                        </Button>
                    </Grid>
                    }
                </Grid>
                :
                <Typography variant="h6" display={'inline'}>
                    {'Thank you'}
                </Typography>
            }
            <iframe title={'Print Content Frame'} id="ifmcontentstoprint" className={classes.root}/>
            <RejectDialog {...{isOpen}} {...dialogProps} handleClose={handleClose}/>
        </Navigation>
    )
}

VerifyContent.propTypes = {
    token: PropTypes.string,
    match: PropTypes.object.isRequired
};

export default VerifyContent;

