import PropTypes from 'prop-types/prop-types';
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import DropZone from "react-dropzone";
import ImagePreview from "../../ImagePreview";
import Placeholder from "../../Placeholder";
import ShowError from "../../ShowError";
import LinearProgress from "@material-ui/core/LinearProgress";
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from "@material-ui/core/Tooltip";
import ViewImage from './View';
import {defaultImage} from "../constants";

const styles = (theme) => ({

    bottomRight: {
        margin: 0,
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
    wrapper: {
        position: 'relative',
        width: 250,
        [theme.breakpoints.up('sm')]: {
            width: 400,
        }
    },
    topRight: {
        margin: 0,
        position: 'absolute',
        top: 0,
        right: 0,
    },
    image: {
        display: 'block',
        margin: '0 auto',
        objectFit: 'cover',
        maxWidth: '100%',
        maxHeight: '100%'
    },
});

class ImageField extends React.Component {
    state = {
        imageFile: []
    };

    handleOnDrop = (newImageFile, onChange) => {
        const imageFile = {
            file: newImageFile[0],
            name: newImageFile[0].name,
            preview: URL.createObjectURL(newImageFile[0]),
            size: newImageFile[0].size
        };

        this.setState({imageFile: [imageFile]}, () => onChange(imageFile));
    };

    handleClick = async () => {
        await this.props.onClick();
        this.setState({imageFile: []});
    };

    handleCancel = async () => {
        await this.props.onCancel();
        this.setState({imageFile: []});
    };

    render() {
        const {input, meta: {error, touched, dirty}, progress, classes} = this.props;

        const {onChange, name, value} = input;

        const isUpdating = dirty || touched;
        const imagefile = this.state.imageFile;

        const {topRight, image} = classes;

        return (
            <React.Fragment>
                {
                    /* this is the view when we are not updating */
                    !isUpdating && <ViewImage {...{onChange, name, value}} classes={{topRight, image}} />

                }
                {
                    isUpdating &&
                    <React.Fragment>
                        <DropZone
                            accept="image/jpeg, image/png, image/gif, image/bmp"
                            className="upload-container"
                            onDrop={file => this.handleOnDrop(file, onChange)}
                            multiple={false}
                        >
                            {
                                (props) => {
                                    return imagefile && imagefile.length > 0 ? (
                                        /* this is the view when we are updating and have a file to display */
                                        <div className={classes.wrapper}>
                                            <ImagePreview {...{imagefile}}/>
                                            <Tooltip title={'Cancel'}>
                                                <IconButton className={classes.topRight}
                                                            type="button" size="small"
                                                            onClick={this.handleCancel}
                                                >
                                                    <ClearIcon fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={'Save New Image'}>
                                                <Fab className={classes.bottomRight} color={'primary'}
                                                     type="button" size="small"
                                                     onClick={this.handleClick}
                                                >
                                                    <DoneIcon/>
                                                </Fab>
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        /* this is the view when we are updating but don't have a file to display*/
                                        <div className={classes.wrapper}>
                                            <Placeholder {...props} error={error} touched={touched} defaultImage={defaultImage[name]}/>
                                            <Tooltip title={'Cancel'}>
                                                <IconButton className={classes.topRight}
                                                            type="button" size="small"
                                                            onClick={this.handleCancel}
                                                >
                                                    <ClearIcon fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    )
                                }
                            }
                        </DropZone>
                        {
                            !!progress && <LinearProgress variant="determinate" color={'secondary'} value={progress}/>
                        }
                        <ShowError error={error} touched={touched}/>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

ImageField.propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    progress: PropTypes.number,
    onClick: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default withStyles(styles)(ImageField);