import {REPORTS, SIGN_OUT} from "./actionTypes";
import {combineReducers} from "redux";

const list = (listState = [], action) => {
    switch (action.type) {
        case REPORTS.GET.SUCCESS:
            return action.payload;
        case SIGN_OUT:
            return [];
        default:
            return listState;
    }
};

/*const selected = (selectedState = '', action) => {
    switch (action.type) {
        case REPORTS.SELECT:
            return action.payload.reportId;
        case REPORTS.GET.REQUEST:
        case SIGN_OUT:
            return '';
        default:
            return selectedState;
    }
};*/

const selectedName = (selectedNameState = '', action) => {
    switch (action.type) {
        case REPORTS.GET_ONE.SUCCESS:
            return action.payload.name;
        case REPORTS.GET.REQUEST:
        case SIGN_OUT:
            return '';
        default:
            return selectedNameState;
    }
};

const selectedReportDate = (selectedReportDateState = '', action) => {
    switch (action.type) {
        case REPORTS.GET_ONE.SUCCESS:
            return action.payload.reportDate;
        case REPORTS.GET.REQUEST:
        case SIGN_OUT:
            return '';
        default:
            return selectedReportDateState;
    }
};

const selectedDataset = (selectedDatasetState = [], action) => {
    switch (action.type) {
        case REPORTS.GET_ONE.SUCCESS:
            return action.payload.dataset;
        case REPORTS.GET.REQUEST:
        case SIGN_OUT:
            return [];
        default:
            return selectedDatasetState;
    }
};

const accountReducer = {list, selectedName, selectedReportDate, selectedDataset};

//this export for testing
export const {
    list: listReducer,
} = accountReducer;


export default combineReducers(accountReducer);