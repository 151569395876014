import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types/prop-types";
import Input from "../../components/Input";

const RejectDialog = (props) => {

    const [value, setValue] = useState('');
    const [touched, setTouched] = useState(false);
    const [error, setError] = useState('')

    const {isOpen, handleClose, title, contentText} = props;

    const validate = (isValid) => setError(!isValid ? 'Required' : '')

    const handleBlur = () => {
        const hasValue = !!value;

        setTouched(true)
        validate(!hasValue)
        setError(!hasValue ? 'Required' : '')
    }

    const handleChange = (e) => {
        const hasValue = e.target.value.length > 0;

        setTouched(hasValue)
        setValue(e.target.value)
        validate(hasValue)
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={(e) => handleClose(e)}
                aria-labelledby="reject-dialog-title"
                aria-describedby="reject-dialog-description"
            >
                <DialogTitle id="reject-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="reject-dialog-description">
                        {contentText}
                    </DialogContentText>
                    <Input
                        label={'Reason'}
                        placeholder={'Reason for rejection, e.g. this credential is expired.'}
                        type={'text'}
                        meta={{touched, error}}
                        input={{
                            name: 'reason',
                            value,
                            onChange: handleChange,
                            onBlur: handleBlur
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" type={'button'}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!touched || !!error}
                        onClick={(e) => handleClose(e, value)}
                        color="secondary" autoFocus type={'button'}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}

RejectDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    contentText: PropTypes.string.isRequired,
};

export default RejectDialog;
