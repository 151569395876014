export const ASYNC = 'ASYNC';
export const SYNC = 'SYNC';

export default (type) => {
    const prefix = `@@${type}`;

    return (obj) => {

        const funcs = {
            ASYNC: (key) => ({[key]: {
                    REQUEST: `${prefix}/${key}/REQUEST`,
                    SUCCESS: `${prefix}/${key}/SUCCESS`,
                    FAILURE: `${prefix}/${key}/FAILURE`
                }}),
            SYNC: (key) => ({[key]: `${prefix}/${key}`})
        };

        return Object.keys(obj).reduce((a, k) => ({...a, ...funcs[obj[k]](k)}), {});
    }
};