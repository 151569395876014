import React from "react";
import PropTypes from 'prop-types/prop-types';
import {Field, Form, reduxForm} from "../../utils/redux-form";
import {CREDENTIAL_IMAGE_FORM} from "../constants";
import * as formValidators from "../../utils/formValidation";
import {Redirect} from 'react-router-dom';
import ImageField, {IMAGE_TYPE}  from "../../components/ImageField";

export class CredentialImageForm extends React.Component {

    render() {
        const {handleSubmit, progress, reset, submitSucceeded} = this.props;

        if (submitSucceeded) {
            return <Redirect to={'/app/credentials'}/>
        }

        return (
            <Form onSubmit={handleSubmit}>
                <Field
                    name="token"
                    component="input"
                    type="hidden"
                />
                <Field
                    name="credentialId"
                    component="input"
                    type="hidden"
                />
                <Field
                    name={IMAGE_TYPE.CREDENTIAL}
                    component={ImageField}
                    type="text"
                    progress={progress}
                    onClick={handleSubmit}
                    onCancel={reset}
                />
            </Form>
        )
    }
}

CredentialImageForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    progress: PropTypes.number,
    reset: PropTypes.func.isRequired,
    submitSucceeded: PropTypes.bool.isRequired
};

export default reduxForm({
    form: CREDENTIAL_IMAGE_FORM,
    enableReinitialize: true,
    validate: formValidators.createValidator({
        profileImage: formValidators.imageIsRequiredAndNotTilde
    },)
})(CredentialImageForm);