import {REPORTS} from "../common/actionTypes";
import db from "../utils/dbAccess";
import {handleError} from "../common/actions";
import {states} from "../common/validation-styles";

const {error} = states;

export const loadReports = (opts) => (dispatch) => {

    const loadReportsSuccess = (payload) => ({
        type: REPORTS.GET.SUCCESS,
        payload

    });

    const loadReportsFailure = (message) => ({
        type: REPORTS.GET.FAILURE,
        payload: {msgState: error, message}
    });


    const loadReportsRequest = () => ({
        type: REPORTS.GET.REQUEST
    });

    dispatch(loadReportsRequest());

    return db.getReports(opts)
        .then(({data:{payload}}) => dispatch(loadReportsSuccess(payload)))
        .catch((e) => dispatch(handleError(e, loadReportsFailure)))
};

export const loadReportById = (opts) => (dispatch) => {

    const loadReportByIdSuccess = (payload) => ({
        type: REPORTS.GET_ONE.SUCCESS,
        payload

    });

    const loadReportByIdFailure = (message) => ({
        type: REPORTS.GET_ONE.FAILURE,
        payload: {msgState: error, message}
    });


    const loadReportByIdRequest = () => ({
        type: REPORTS.GET_ONE.REQUEST
    });

    dispatch(loadReportByIdRequest());

    return db.getReportById(opts)
        .then(({data:{payload}}) => dispatch(loadReportByIdSuccess(payload)))
        .catch((e) => dispatch(handleError(e, loadReportByIdFailure)))
};

// export const selectReport = (reportId) => ({
//     type: REPORTS.SELECT,
//     payload: {reportId}
// });