import React from 'react';
import PropTypes from 'prop-types/prop-types';
import {reduxForm, Field} from '../../utils/redux-form';
import * as formValidators from '../../utils/formValidation';
import Grid from "@material-ui/core/Grid";
import {withStyles} from '@material-ui/core/styles';
import {ADD_CREDENTIAL_FORM} from '../constants';
import Input from './Input';
import Typography from "@material-ui/core/Typography";
import Button from '../../components/StandardButton';
import {Link, Redirect} from 'react-router-dom'
import DropZoneField from "../../components/DropZoneField";
import DateField from "./DateField";
import Select from "../../components/SelectControl";
import Autocomplete from "../../components/Autocomplete";
import {getTypeList} from "../../common/credential-styles";
import {dateFormatter} from "../../utils/fieldFormatter";


const styles = () => ({
    button: {
        textAlign: 'right'
    }
});

const typesList = getTypeList();

export class AddCredentialForm extends React.Component {
    state = {
        imageFile: []
    };

    handleOnDrop = ([newImageFile], onChange) => {
        const imageFile = {
            file: newImageFile,
            name: newImageFile.name,
            preview: URL.createObjectURL(newImageFile),
            size: newImageFile.size
        };

        this.setState({imageFile: [imageFile]}, () => onChange(imageFile));
    };

    render() {
        const {classes, handleSubmit, pristine, invalid, submitting, providersList, membersList, membersExist} = this.props;

        if (!membersExist || submitting) {
            return <Redirect to={'/app/credentials'}/>
        }

        return (
            <form
                onSubmit={handleSubmit}
            >
                <Grid container direction="column"
                      justify="center"
                      alignItems="center">
                    <Grid item>
                        <Field
                            name="token"
                            component="input"
                            type="hidden"
                        />
                        <Field
                            name="credentialImage"
                            component={DropZoneField}
                            type="file"
                            imageFile={this.state.imageFile}
                            handleOnDrop={this.handleOnDrop}
                        />
                        <Field
                            name="provider"
                            component={Autocomplete}
                            label="Provider"
                            options={providersList}
                        />
                        <Field
                            name="dateIssued"
                            component={DateField}
                            type="date"
                            label="Date Issued"
                            format={dateFormatter}
                        />
                        <Field
                            name="dateExpires"
                            component={DateField}
                            type="date"
                            label="Expiration Date"
                            format={dateFormatter}
                        />
                        <Field
                            name="type"
                            component={Select}
                            type="text"
                            label="Type"
                            options={typesList}
                        />
                        <Field
                            name="title"
                            component={Input}
                            type="text"
                            label="Credential Title"
                            placeholder={'Title'}
                        />
                        <Field
                            name="member"
                            component={Select}
                            type="text"
                            label="Member"
                            options={membersList}
                        />
                        <Typography className={classes.button}>
                            <Button
                                to={'../credentials'}
                                component={Link}
                                color={'inherit'}
                                type="button">
                                Cancel
                            </Button>
                            <Button
                                disabled={pristine || invalid}
                                type="submit">
                                Add Credential
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
        )
    }
}

AddCredentialForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    pristine: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    providersList: PropTypes.array.isRequired,
    membersList: PropTypes.array.isRequired
};

export const StyledAddCredentialForm = withStyles(styles)(AddCredentialForm);

export default reduxForm({
    form: ADD_CREDENTIAL_FORM,
    enableReinitialize: true,
    validate: formValidators.createValidator({
        member: formValidators.required,
        title: formValidators.required,
        provider: formValidators.required,
        type: formValidators.required,
        dateIssued: formValidators.required,
        credentialImage: formValidators.imageIsRequired
    },)
})(StyledAddCredentialForm);