import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from "../../components/RouterLink";
import {PUBLIC_URL} from '../../config';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import {DEFAULT_HOME} from "../../config";
import ConnectedSnackbar from "../../components/ConnectedSnackbar";

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24
    },
    title: {
        flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 400,
        marginTop: 125
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
});

export class ForbiddenContent extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <AppBar className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            className={classes.title}
                        >
                            Content Not Accessible
                        </Typography>

                    </Toolbar>
                </AppBar>

                <ConnectedSnackbar />
                <Card className={classes.content}>
                        <CardMedia
                            className={classes.media}
                            image={`${PUBLIC_URL}/images/jelleke-vanooteghem-765781-unsplash.jpg`}
                            title="Photo by Jelleke Vanooteghem on Unsplash"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Oops...
                            </Typography>
                            <Typography variant="body1" gutterBottom component="p">
                                You don't have permission to access this content contact your administrator.
                                If that doesn't work, <Link color={'inherit'} underline={'always'} to={DEFAULT_HOME}>click to return Home</Link>.
                            </Typography>
                        </CardContent>
                </Card>
            </div>
        );
    }
}

ForbiddenContent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ForbiddenContent);

