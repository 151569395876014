import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import React from "react";

const styles = (theme) => ({
    group: {
        margin: `${theme.spacing(1)}px 0`,
    },
});

export default withStyles(styles)((props) => {

    const {classes, label, name, input: {value, onChange}} = props;

    const types = {
        organization: 'Organization',
        member: 'Member',
        team: 'Team'
    };

    return (
        <FormControl component="fieldset" >
            <FormLabel component="legend">{props.label}</FormLabel>
            <RadioGroup
                aria-label={label}
                className={classes.group}
                {...{name, value, onChange}}
            >
                {Object.keys(types).map((k, i) => <FormControlLabel key={i} value={k} control={<Radio/>} label={types[k]}/>)}
            </RadioGroup>
        </FormControl>
    )
});