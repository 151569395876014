import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItemContainer from '../containers/ListIemsContainer';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {Link} from 'react-router-dom'
import Paper from "@material-ui/core/Paper";
import ConnectedSnackbar from '../../ConnectedSnackbar';
import ConnectedLoadingIndicator from "../../ConnectedLoadingIndicator";
import LogoSvg from "../../../common/content/logo";
import Button from '@material-ui/core/Button';
import Tooltip from "@material-ui/core/Tooltip";

const drawerWidth = 240;

const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
        height: '100vh',
        overflow: 'auto',
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2)
    },
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        '@media print' : {
            display: 'none'
        }
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    navigation:{
        '@media print' : {
            display: 'none'
        }
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    logo: {
        backgroundColor: 'white',
        width: 26,
        height: 22,
    }
});

class ContentWrapper extends React.Component {
    state = {
        open: false,
        auth: true,
        anchorEl: null,
    };

    handleDrawerOpen = () => {
        this.setState({open: true});
    };

    handleDrawerClose = () => {
        this.setState({open: false});
    };


    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = (e) => {
        this.setState({anchorEl: null});
        if (e.target.innerText === 'Sign Out') {
            this.props.handleSignOut();
        }
    };

    render() {
        const {classes, title, children, isAuthenticated} = this.props;

        const {auth, anchorEl, open} = this.state;
        const openIt = Boolean(anchorEl);

        return (
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar
                    position="absolute"
                    className={classNames(classes.appBar,
                        // open && classes.appBarShift
                    )}
                >
                    <Toolbar disableGutters={!open} className={classes.toolbar}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpen}
                            className={classNames(
                                classes.menuButton,
                                // open && classes.menuButtonHidden,
                            )}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Tooltip title="Visit www.qredify.com">
                            <Button component={'a'} color="inherit" href={'http://www.qredify.com/'} target={'_blank'}>
                                <LogoSvg className={classes.logo}/>
                            </Button>
                        </Tooltip>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            className={classes.title}
                        >
                            {title}
                        </Typography>
                        {auth && (
                            <div>
                                <IconButton
                                    aria-owns={openIt ? 'menu-appbar' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleMenu}
                                    color="inherit"
                                >
                                    <AccountCircle/>
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={openIt}
                                    onClose={this.handleClose}
                                >
                                    {isAuthenticated ? <div>
                                    <MenuItem component={Link} to={'/app/profile'}>Profile</MenuItem>
                                    <MenuItem component={Link} to={'/app/account'}>My account</MenuItem>
                                    <MenuItem onClick={this.handleClose}>Sign Out</MenuItem>
                                        </div>
                                        :
                                        <div>
                                            <MenuItem component={Link} to={'/'}>Sign In</MenuItem>
                                            <MenuItem component={Link} to={'/auth/signup'}>Sign Up</MenuItem>
                                        </div>}
                                </Menu>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>

                <div className={classes.navigation}>
                <Hidden mdUp>
                    <Drawer
                        variant="temporary"
                        classes={{paper: classes.drawerPaper}}
                        open={open}
                        onClose={this.handleDrawerClose}
                    >
                        <ListItemContainer />
                        {/*<ListItems {...{orgExists, isConfirmed}} />*/}
                    </Drawer>
                </Hidden>
                <Hidden smDown>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper}}
                        open={open}
                    >
                        <ListItemContainer>
                            <div className={classes.toolbarIcon}>
                                <IconButton onClick={this.handleDrawerClose}>
                                    <ChevronLeftIcon/>
                                </IconButton>
                            </div>
                        </ListItemContainer>
{/*                        <ListItems {...{orgExists, isConfirmed}} >
                            <div className={classes.toolbarIcon}>
                                <IconButton onClick={this.handleDrawerClose}>
                                    <ChevronLeftIcon/>
                                </IconButton>
                            </div>
                        </ListItems>*/}
                    </Drawer>
                </Hidden>
                </div>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer}/>
                    <Paper className={classes.paper}>
                        <ConnectedLoadingIndicator/>
                        {children}
                    </Paper>
                </main>
                <ConnectedSnackbar/>
            </div>
        );
    }
}

ContentWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContentWrapper);

