import {compose, prop, isEmpty} from "ramda";
import {createSelector} from "reselect";
import {NAME} from "./constants";
import {getToken} from "../authorize";


const getState = state => state[NAME];

const getUsersList = compose((addrs) => {
    const {usersList} = addrs;
    return usersList
} , getState);



const getSelectedUserId = compose(prop('selectedUser') , getState);

const getUserById = (state, userId) => ([...getUsersList(state)
    .filter((item) => item._id === userId)]);

const getSelectedUser = (state) => {

    const selectedId = getSelectedUserId(state);
    const [selectedUser] = getUserById(state, selectedId);

    return {...selectedUser}
};

const getCouponList = (state) => {

    const list = compose(prop('couponList'), getState)(state);

    return list.map((c)=>({key: c.id, value: c.name}));
};

export const mapUsersToProps = createSelector(
    getToken,
    getUsersList,
    (token, users) => ({
        token,
        users,
        exists: users.length > 0
    })
);

export const mapSelectedUserToProps = createSelector(
    getToken,
    getSelectedUser,
    (token, user) => ({
        ...user,
        token
    })
);

export const mapUserCouponsToProps = createSelector(
    getToken,
    getSelectedUser,
    getCouponList,
    (token, user, couponList) => ({
        initialValues: {
            token,
            _id: user._id,
            couponId: user.couponId
        },
        couponList,
        isEmpty: isEmpty(user)
    })
);