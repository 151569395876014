import Content from '../components/HiddenPreview';
import {unHide} from "../actions";
import {connect} from "react-redux";
import {mapHideUnhideToProps} from "../selectors";

const mergeProps = function({token}, {unHide}, props) {
    const {member} = props;
    return {
        ...props,
        handleUnHide: (credentialId) => {
            return unHide({token, member, credentialId})
        }
    }
};

export default connect(mapHideUnhideToProps, {unHide}, mergeProps)(Content);