import {CREDENTIALS, SIGN_OUT, ORGANIZATION} from "./actionTypes";
import {combineReducers} from "redux";
import * as common from "../common/reducer";
import {PROVIDERS} from "../common/actionTypes";

const progress = (progressState = null, action) => {
    switch (action.type) {
        case CREDENTIALS.POST.REQUEST:
            return 0;
        case CREDENTIALS.SHOW_PROGRESS:
            return action.percentCompleted;
        case CREDENTIALS.POST.SUCCESS:
        case CREDENTIALS.POST.FAILURE:
        case CREDENTIALS.UPDATE_IMAGE.SUCCESS:
        case CREDENTIALS.UPDATE_IMAGE.FAILURE:
        case SIGN_OUT:
            return null;
        default: return progressState;
    }
};

const list = (listState = [], action) => {
    switch (action.type) {
        case ORGANIZATION.DASHBOARD.SUCCESS:
            return action.payload.credentials;
        case CREDENTIALS.GET.SUCCESS:
            return action.payload;
        case CREDENTIALS.POST.SUCCESS:
            return common.addItemToArray(listState, action.payload.credential);
        case CREDENTIALS.DELETE.SUCCESS:
            return common.removeItemFromArray(listState, action.payload.credentialId);
        case CREDENTIALS.UPDATE.SUCCESS:
            const {_id, dateExpires, ...rest} = action.payload.credential;
            const expiresObj = !!dateExpires ? {dateExpires}: {};
            return common.updateItemInArray(listState, _id, ({credential}) => {
                return ({_id, ...credential, ...rest, ...expiresObj})
            });
        case CREDENTIALS.UPDATE_IMAGE.SUCCESS:
            return common.updateItemInArray(listState, action.payload.credentialId, (credential) => ({...credential, credentialImage: action.payload.credentialImage}));
        case PROVIDERS.DE_DUPLICATE.SUCCESS:
            return common.updateItemInArray(listState, action.payload.from, (c) => ({...c, provider: action.payload.to}));
        case SIGN_OUT:
            return [];
        default:
            return listState;
    }
};

const selected = (selectedCredentialState = '', action) => {
    switch (action.type) {
        case CREDENTIALS.SELECT:
            return action.payload._id;
        case CREDENTIALS.POST.SUCCESS:
        case "@@redux-form/DESTROY": // currently don't think we need to be specific as to which form
        case SIGN_OUT:
            return '';
        default: return selectedCredentialState;
    }
};

const credentialsReducer = {list, selected, progress};

//this export for testing
export const {
    list: listReducer,
    selected: selectedReducer,
    progress: progressReducer
} = credentialsReducer;


export default combineReducers(credentialsReducer);