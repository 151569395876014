import {combineReducers} from 'redux'
import {SIGN_OUT, AUTH, PROFILE} from './actionTypes';
import {STATUS} from "../common/constants";

const token = (tokenState = '', action) => {
    switch(action.type){
        case AUTH.POST.SUCCESS:
        case AUTH.RESET.SUCCESS:
            return action.payload.token;
        case SIGN_OUT: return '';
        default: return tokenState;
    }
};

const pending = (successState = false, action) => {
    switch (action.type) {
        case PROFILE.POST.SUCCESS:
        case AUTH.EMAIL.SUCCESS:
        case AUTH.RESET.FAILURE:
        case AUTH.PWD.SUCCESS:
        case AUTH.VERIFIER.SUCCESS:
            return true;
        case AUTH.VERIFIER.REQUEST:
        case AUTH.RESET.REQUEST:
        case AUTH.POST.FAILURE:
        case AUTH.POST.SUCCESS:
        case SIGN_OUT:
        case AUTH.NEW:
            return false;
        default:
            return successState;

    }
};

const status = (statusState = '', action) => {
    switch (action.type) {
        case AUTH.CONFIRM_EMAIL.SUCCESS:
            return STATUS.CONFIRMED;
        case AUTH.POST.SUCCESS:
            return action.payload.status;
        case PROFILE.UPDATE_EMAIL.SUCCESS:
            return  STATUS.ACTIVE;
        case PROFILE.POST.SUCCESS:
        case PROFILE.GET.SUCCESS:
        case AUTH.RESET.SUCCESS:
            return action.payload.status;
        case AUTH.CONFIRM_EMAIL.FAILURE:
        case SIGN_OUT:
            return '';
        default:
            return statusState;
    }
};

const roles = (roleState = [], action) => {
    switch (action.type) {
        case PROFILE.PUT.SUCCESS:
        case PROFILE.GET.SUCCESS:
        case AUTH.POST.SUCCESS:
        case PROFILE.STOP_VERIFYING.SUCCESS:
            return action.payload.roles;
        case SIGN_OUT:
            return [];
        default: return roleState;
    }
};

const email = (emailState = null, action) => {
    switch (action.type) {
        case AUTH.EMAIL.SUCCESS:
            return action.payload.email;
        case AUTH.RESET.REQUEST:
        case AUTH.POST.FAILURE:
        case AUTH.POST.SUCCESS:
        case SIGN_OUT:
        case AUTH.NEW:
            return null;
        default: return emailState;

    }
};

const authReducers = {token, pending, status, roles, email};

//this export for testing
export const {
    token : tokenReducer,
    pending : pendingReducer,
    status : statusReducer,
    roles: roleReducer,
    email: emailReducer
} = authReducers;

export default combineReducers(authReducers)