import React from 'react';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import {phoneFormatter} from "../../utils/fieldFormatter";
import {IMAGE_TYPE} from "../../components/ImageField";
import {UnstyledViewImage} from "../../components/ImageField";
import {withStyles} from "@material-ui/core/styles";

const LABELS = {
    'name': 'Organization Name',
    'phone': 'Phone Number',
    'website': 'Web Site'
};

const formatter = {
    'phone': phoneFormatter
};

const styles = () => ({
    wrapper: {
        position: 'relative',
        width: 250
    },
    image: {
        display: 'block',
        margin: '0 auto',
        objectFit: 'cover',
        maxWidth: '100%',
        maxHeight: '100%'
    },
});

const OrganizationViewer = (props) => {
    const {classes, logoImage, meta, ...rest} = props;

    return (
        <Card raised>
            <CardContent>
                <Grid container
                      direction="column"
                      justify="flex-start"
                      alignItems="center"
                >
                    <Grid item>
                        <Grid container
                              direction="column"
                              justify="flex-start"
                              alignItems="stretch"
                              spacing={4}
                        >
                            <Grid item>
                                <UnstyledViewImage value={logoImage} name={IMAGE_TYPE.LOGO} {...{classes}}/>
                            </Grid>
                            <Grid item>
                                {rest && Object.keys(rest).map((k, i) => {
                                    return (
                                        <Typography key={i} variant="body2" gutterBottom component="p">
                                            <Typography variant="body1" display={'inline'} component={'span'}>
                                                {LABELS[k]}
                                            </Typography> {!!formatter[k] ? formatter[k](rest[k]) : rest[k]}
                                        </Typography>
                                    )
                                })
                                }
                            </Grid>
                            <Grid item>
                                {meta && Object.keys(meta).map((k, i) => {
                                    return (
                                        <Typography key={i} variant="body2" gutterBottom component="p">
                                            <Typography variant="h6" component={'span'}>
                                                {k}
                                            </Typography> {meta[k]}
                                        </Typography>
                                    )
                                })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};

export default withStyles(styles)(OrganizationViewer);