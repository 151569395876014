import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import QRCode from "qrcode.react";
import FormControl from "@material-ui/core/FormControl";

const styles = theme => ({
    formControl: {
        width: 250,
        [theme.breakpoints.up('sm')]: {
            width: 400,
        },
    },

    code: {
        height: 235,
        width: 235,
        margin: '0 auto',
        display: 'block',
        [theme.breakpoints.up('md')]: {
            width: 300,
            height: 300
        }
    }
});


export const QRViewer = (props) => {

    const {classes, input} = props;

    return (
        <FormControl className={classes.formControl}>
            <QRCode name={input.name}
                    className={classes.code}
                    value={`${window.location.protocol}//${window.location.host}/${input.value}`}
                    renderAs={'svg'}
            />
        </FormControl>
    )
};

QRViewer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QRViewer);
