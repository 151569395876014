import dotenv from 'dotenv';
dotenv.config();

const appFolder = 'qredify/v1';

const REACT_APP_SERVER_URI = process.env.REACT_APP_SERVER_URI || '/';

const STAGE = process.env.REACT_APP_STAGE || '/local';
const PUBLIC_URL = process.env.PUBLIC_URL || '';
const DEFAULT_HOME = '/app/profile';
const CLOUDINARY = process.env.CLOUDINARY || {
    CLOUD_NAME: 'credulio',
    UPLOAD_PRESET: 'aoixi7jm',
    API_KEY: '918561151116728',
    APP_FOLDER: {
        PROFILE: `${appFolder}${STAGE}/profiles`,
        LOGO: `${appFolder}${STAGE}/logos`,
        CREDENTIAL: `${appFolder}${STAGE}/credentials`
    }
};
const REACT_APP_STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_test_MFOmr4XiOnaVkJnokRcFo9Wb00lQpetx98';

export { REACT_APP_SERVER_URI, PUBLIC_URL, DEFAULT_HOME, CLOUDINARY, REACT_APP_STRIPE_PUBLIC_KEY };