import React from "react";
import Link from "./Link";
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';

const PRIVACY_POLICY_URL = `https://www.qredify.com/privacy-policy.html`;
const TERMS_OF_SERVICE_URL = `https://www.qredify.com/terms-of-service.html`;

const styles = () => ({
    root: {
        marginTop: 0
    }
});

export const ServiceTerms = ({classes}) => {
    return (
        <Typography variant="caption" className={classes.root} >
            Clicking here means you agree to our&nbsp;
            <Link color={"secondary"} href={TERMS_OF_SERVICE_URL}>Terms of Service</Link>
            &nbsp;and&nbsp;
            <Link color={"secondary"} href={PRIVACY_POLICY_URL}>Privacy Policy</Link>.
        </Typography>
    )
};

export default withStyles(styles)(ServiceTerms)
