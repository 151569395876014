import {CONTENT_CODES, SIGN_OUT} from "./actionTypes";
import {MEMBERS, ORGANIZATION} from "../common/actionTypes";
import {combineReducers} from "redux";
import {addItemToArray} from "../common/reducer";

const removeItemFromArray = (array, itemId) => {
    return array.filter(item => item.code !== itemId);
};

const updateItemInArray = (array, itemId, updateItemCallback) => {
    return array.map(item => {

        if (item.code !== itemId) return item;

        // Use the provided callback to create an updated item
        return updateItemCallback(item);
    });
};

const updateCode = (listState, {contentCode}) => {
    const {code, nickname, name, type, ...rest} = contentCode;
    return updateItemInArray(listState, code, () => ({code, ...rest, nickname, name, type}));
};

const list = (listState = [], action) => {
    switch (action.type) {
        case ORGANIZATION.DASHBOARD.SUCCESS:
            return action.payload.contentCodes;
        case CONTENT_CODES.PUT.SUCCESS:
            return updateCode(listState, action.payload);
        case MEMBERS.UPDATE.SUCCESS:
        case MEMBERS.DELETE.SUCCESS:
        case ORGANIZATION.POST.SUCCESS:
            const {contentCodes} = action.payload;
            const ckeys = contentCodes.map(({code})=>(code));
            return listState.map((i)=>{
                const code = contentCodes[ckeys.indexOf(i.code)];
                return(!!code ? code : i)
            });
        case CONTENT_CODES.POST.SUCCESS:
            return addItemToArray(listState, action.payload.contentCode);
        case CONTENT_CODES.DELETE.SUCCESS:
            return removeItemFromArray(listState, action.payload.contentCode.code);
        case SIGN_OUT:
            return [];
        default:
            return listState;
    }
};

const selectedCode = (selectedCodeState = '', action) => {
    switch (action.type) {
        case CONTENT_CODES.SELECT:
            return action.payload.code;
        case CONTENT_CODES.PUT.SUCCESS:
        case "@@redux-form/DESTROY": // currently don't think we need to be specific as to which form
        case SIGN_OUT:
            return '';
        default: return selectedCodeState;
    }
};

const contentCodesReducer = {list, selectedCode};

//this export for testing
export const {
    list: listReducer,
    selectedCode: selectedCodeReducer
} = contentCodesReducer;


export default combineReducers(contentCodesReducer);