import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const palette = {
    primary: { main: '#EED202', contrastText: '#353535' },
    secondary: { main: '#BF0028', contrastText: '#dbdbdb' }
};
const themeName = 'Gold Monza Silkworm';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    palette, themeName });

export default ({children}) => (
    <MuiThemeProvider theme={theme}>
        {children}
    </MuiThemeProvider>
);