import React from 'react';
import VerifierConfirmationFormContainer from '../containers/VerifierConfirmationFormContainer';
import Grid from "@material-ui/core/Grid";
import AuthPaper from './AuthPaper';

export default () => {
    return (
        <AuthPaper width={600}>
            <Grid item>
                <VerifierConfirmationFormContainer/>
            </Grid>
        </AuthPaper>
    );
};