import {createSelector} from "reselect";
import {getToken} from "../authorize";
import {compose, isEmpty, map, sortBy} from "ramda";
import {NAME, UPDATE_PROVIDER_FORM} from "./constants";
import {formValueSelector} from '../utils/redux-form';

const formSelector = formValueSelector(UPDATE_PROVIDER_FORM)

const getState = state => state[NAME];

const getProviders = compose(({list}) => list, getState);

const getSelected = compose(({selected})=> selected, getState);

export const getProviderListValues = compose(sortBy(({label}) => label.toLowerCase()), map(({_id, name, status}) => ({value: _id, label: name, status})), getProviders);

const getIsVerifierStatus = (state) => {
    const selectedStatus = formSelector(state, 'status')

    return selectedStatus === 'Verifier';
}

const getSelectedProvider = (state) => {
    const providers = getProviders(state);
    const selected = getSelected(state);

    const [selectedProvider] = providers.filter((p) => p._id === selected);
    return !!selectedProvider ? selectedProvider : {};
}

const getFormProps = (state) => {
    const token = getToken(state);

    const result = getSelectedProvider(state);
    const isVeriferStatus = getIsVerifierStatus(state);

    let verifier
    let initialValues = {};

    const getVerifierFields = (verifier) => {

        return !!verifier ? {verifierEmail: verifier.email, verifier: verifier._id} : {};
        // return !!verifier ? {} : {};
    }

    if(!!result) {

        //filter out verifier, dateStatused, credentials, __v,
        const {verifier: fromPro, dateStatused, credentials, __v, ...rest} = result

        verifier = fromPro

        initialValues = {...rest, token, ...getVerifierFields(verifier)}
        // initialValues = {...rest, token, verifier}
    }

    const verifierEmail = formSelector(state, 'verifierEmail')

    const name = result.name;


    return {verifier, verifierEmail, name, isVeriferStatus, initialValues};
};

const getProviderNameAndId = (state) => {
    const {name, _id} = getSelectedProvider(state)
    return {name, _id};
}

export const mapAddProviderToProps = createSelector(
    getToken,
    (token) => ({
        initialValues: {token}
    })
);

export const mapProvidersToProps = createSelector(
    getToken,
    getProviders,
    getSelected,
    (token, providers, selectedId) => ({
        token,
        providers,
        selectedId
    })
);

export const mapContentToProps = createSelector(
    getProviders,
    getToken,
    getProviderNameAndId,
    ( providers, token, nameAndId) => ({
        providers,
        token,
        ...nameAndId
    })
);

export const mapUpdateProviderToProps = createSelector(
    getSelected,
    getFormProps,
    (selected, formProps) => ({
        isEmpty: isEmpty(selected),
        // show,
        ...formProps
    })

)

export const mapVerifierToProps = createSelector(
    getToken,
    getProviderNameAndId,
    (token, {name}) => ({token, name})
)