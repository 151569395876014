import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
    error: {
        color: theme.palette.error.dark
    },
    confirmed: {
        color: theme.palette.text.disabled
    }
});

export const ConfirmEmailContent = (props) => {

    const {status, classes} = props;

    const isConfirmed = status === 'confirmed';

    const message = isConfirmed ? 'Thank you, your email is confirmed.' : 'Email Confirmation Failed';
    const stateClass = isConfirmed ? classes.confirmed : classes.error;

    return (
        <Typography variant={"body1"} className={stateClass} gutterBottom>
            {message}
        </Typography>
    )
};

export default withStyles(styles)(ConfirmEmailContent);