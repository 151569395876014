import {createSelector} from "reselect";
import {getToken} from "../authorize";
import {compose, isEmpty, length, not, reduce} from "ramda";
import {NAME} from "./constants";
import {getProviderListValues} from "../providers";
import {getMemberListItems} from '../members';
import {getExists as membersExist} from "../members";
import moment from "moment";

const getState = state => state[NAME];

const getProgress = compose(({progress}) =>({progress}), getState);

const getCredentials = compose(({list}) => (list), getState);

const getExists = compose(not, isEmpty, getCredentials);

const getActiveCount = compose(length, getCredentials);

const getFields = ({credentialImage, provider, dateIssued, dateExpires, type, title, member, _id: credentialId}) => ({
    credentialImage,
    provider,
    dateIssued,
    dateExpires,
    type,
    title,
    member,
    credentialId
});

const getSelectedCredential = compose((credentials) => {
    const selectedId = credentials.selected;
    const [selectedCredential] = credentials.list.filter((item) => item._id === selectedId);

    return !!selectedCredential ? getFields(selectedCredential) : {};

}, getState);

const getSelectedCredentialImage = compose(({credentialImage, credentialId}) => ({credentialId, credentialImage: (!!credentialImage ? credentialImage : '')}), getSelectedCredential);

export const getCredentialCounts = compose(reduce((a, c) => ({added: a.added + 1, expired: moment(c.dateExpires).isBefore(moment()) ? a.expired + 1 : a.expired}), {added: 0, expired: 0}), getCredentials);

export const mapCredentialsToProps = createSelector(
    getToken,
    getCredentials,
    getExists,
    getActiveCount,
    getProgress,
    membersExist,
    (token, credentials, exists, active, {progress}, membersExist) => ({
        token,
        credentials,
        active,//the number of uploaded credentials
        exists,
        progress,
        membersExist
    })
);

export const mapAddCredentialToProps = createSelector(
    getToken,
    getProviderListValues,
    getMemberListItems,
    membersExist,
    (token, providersList, membersList, membersExist) => ({
        initialValues: {token},
        providersList,
        membersList,
        membersExist
    })
);

export  const mapCredentialsContentToProps = createSelector(
    getToken,
    (token) => ({
        token
    })
);

export const mapUpdateCredentialFormToProps = createSelector(
    getToken,
    getSelectedCredential,
    getProviderListValues,
    getMemberListItems,
    (token, selectedCredential, providersList, membersList) => ({
        isEmpty: isEmpty(selectedCredential),
        initialValues: {token, ...selectedCredential},
        providersList,
        membersList
    })
);

export const mapCredentialImageToProps = createSelector(
    getToken,
    getSelectedCredentialImage,
    getProgress,
    (token, {credentialImage, credentialId}, {progress}) => ({
        progress,
        initialValues: {
            token,
            credentialImage,
            credentialId
        }
    })
);
