import React from "react";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export default (props) => {
    const {classes, dateExpires, title, firstName, lastName, providerName, providerStatus, group} = props;

    const getDate = (dateString) => new Date(dateString).toLocaleDateString();

    return (
        <TableRow >
            <TableCell className={classes[group]} >
                {getDate(dateExpires)}
            </TableCell>
            <TableCell >
                {firstName}
            </TableCell>
            <TableCell >
                {lastName}
            </TableCell>
            <TableCell >
                {title}
            </TableCell>
            <TableCell >
                {providerName}
            </TableCell>
            <TableCell >
                {providerStatus}
            </TableCell>
        </TableRow>
    )
}