import Content from '../components/AddCredentialForm';
import {addCredential} from "../actions";
import {connect} from "react-redux";
import {mapAddCredentialToProps} from "../selectors";

const mergeProps = function({initialValues, ...state}, actions, {memberId}) {
    return {
        ...state,
        ...actions,
        initialValues: {...initialValues, member: memberId}
    }
};

export default connect(mapAddCredentialToProps, {onSubmit: addCredential}, mergeProps)(Content);