import Grid from "@material-ui/core/Grid";
import BodyGridItem from "./BodyGridItem";
import React from "react";
import Chip from '@material-ui/core/Chip';
import PaymentMethodIcon from "../../common/icons/PaymentMethodIcon";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {DeleteIcon} from "../../common/icons";

export default (props) => {
    const {card, id, onDeleteClick} = props;

    const {last4, brand} = card;

    const name = brand.toUpperCase();

    const expire = `${card.exp_month} / ${card.exp_year}`;

    return (
        <Grid item container direction="row"
              justify="flex-start"
              alignItems="center"
        >
            <BodyGridItem xs={1}><PaymentMethodIcon/></BodyGridItem>
            <BodyGridItem xs={3}>{name}</BodyGridItem>
            <BodyGridItem xs={2}>{`...${last4}`}</BodyGridItem>
            <BodyGridItem xs={3}>{expire}</BodyGridItem>
            <BodyGridItem xs={2}>{props.isDefault ? (<Chip label={'Default'} color={'default'}/>) : ''}</BodyGridItem>
            <BodyGridItem xs={1}> <Tooltip
                title={props.isDefault ? 'Cannot delete the default payment method' : "Delete this payment method"}
                aria-label="Delete payment method">
                <div>
                    <IconButton edge="end" onClick={(e) => onDeleteClick(e, {id, name, last4})} disabled={props.isDefault}>
                        <DeleteIcon/>
                    </IconButton>
                </div>
            </Tooltip>
            </BodyGridItem>
        </Grid>)
};