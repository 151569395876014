import React from 'react';
import Typography from "@material-ui/core/Typography";
import Navigation from '../../components/ConnectedNavigation';
import ConfirmedUserListContainer from "../containers/ConfirmedUserListContainer";

class UserAdminContent extends React.Component {

    render() {

        return (
            <Navigation title={'User Administration'}>
                <Typography variant={"h4"} gutterBottom>
                    User Administration
                </Typography>
                <ConfirmedUserListContainer />
            </Navigation>
        )
    }
}

export default UserAdminContent;