import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field, Form} from '../../utils/redux-form';
import * as formValidators from '../../utils/formValidation';
import Grid from "@material-ui/core/Grid";
import {UPDATE_EMAIL_FORM} from '../constants';
import {checkEmail} from "../../authorize/actions";
import Email from "../../authorize/components/Email";
import lowercaseParser from "../../authorize/lowercaseParser";
import Button from '../../components/StandardButton';
import Typography from "@material-ui/core/Typography";
import {colors} from "../../common/validation-styles";
import {withStyles} from "@material-ui/core";

const styles = () => {
    return ({
        contained: {
            position: 'relative'
        },
        status: {
            position: 'absolute',
            top: 2,
            right: 0,
            fontStyle: 'italic',
            color: colors.success
        }
    })
};

export const UpdateEmailForm = (props) => {
    const {classes, handleSubmit, submitting, pristine, invalid, reset, onChangeClick, isChanging, isConfirmed, isExpired} = props;

    const handleClick = toChange => () => {
        onChangeClick(toChange);
        reset();
    };

    //using this handler so that we can reset the ProfileContent isChanging state after the form is submitted.
    const submitHandler = (toChange) => (form) => {
        handleSubmit(form)
            .then(() => {
                onChangeClick(toChange);
                reset();
            })
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Grid item>
                <Field
                    name="token"
                    component="input"
                    type="hidden"
                />
                <div className={classes.contained}>
                    <Field
                        name="email"
                        component={Email}
                        parse={lowercaseParser}
                        type="text"
                        label="Email Address"
                        placeholder={'Email Address'}
                        autoComplete="username"
                        isDisabled={!isChanging}
                    />
                    {
                        isConfirmed &&
                        <Typography variant={"body1"} display={'inline'} className={classes.status}>confirmed</Typography>
                    }
                </div>
            </Grid>
            <Grid item>
                {
                    isChanging && <Button
                        disabled={pristine || submitting || invalid}
                        type="button"
                        onClick={submitHandler(isChanging)}
                    >
                        Update
                    </Button>
                }
                {
                    !isExpired && <Button
                        onClick={handleClick(isChanging)}
                        variant={'outlined'}
                        color={'default'}
                        type="button">
                        {isChanging ? 'Cancel' : 'Change Email'}
                    </Button>
                }


            </Grid>
        </Form>
    )
};

UpdateEmailForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired
};

const StyledUpdateEmailForm = withStyles(styles)(UpdateEmailForm);

export default reduxForm({
    form: UPDATE_EMAIL_FORM,
    enableReinitialize: true,
    validate: formValidators.createValidator({
        email: [formValidators.required, formValidators.email]
    }),
    asyncValidate: checkEmail,
    asyncChangeFields: ['email']
})(StyledUpdateEmailForm);