import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field, Form} from '../../utils/redux-form';
import Grid from "@material-ui/core/Grid";
import {VERIFIER_FORM} from '../constants';
import AlertDialog from "../../components/AlertDialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export const VerifierForm = (props) => {

    const [verify, setVerify] = useState(true)
    const [isOpen, setIsOpen] = useState(false)

    const {handleSubmit, loadProviders, token, verifier, affectedProviders} = props;

    useEffect(() => {
        const loadNow = () => loadProviders({token, verifier});
        loadNow();
    }, [token, loadProviders, verifier]);

    const handleChange = () => setIsOpen(!isOpen)

    const onHandleClose = (e, id) => {
        if(id) {
            const {handleSubmit} = props;
            handleSubmit()
                .then(() => setVerify(!verify));
        }
        setIsOpen(!isOpen)
    }

    const getDialogProps = ({affectedProviders}) => {

        const providerString = !!affectedProviders ? affectedProviders.reduce((a, p) => `${a}${!!a ? ', ' : ''}${p}`, '') : ''

        const provider = affectedProviders.length > 1 ? `the following providers: ${providerString}` : providerString;

        return {
            title: `Cancel Verifier Permission`,
                contentText: `Cancelling Verifier permission will revert ${provider} to a non-verifier. 
                The status of all credentials associated with ${provider} will revert to: ‘Provider Unable to Verify’`,
                _id: 'stop'
        }
    };

    const dialogProps = getDialogProps({affectedProviders});

    return (
        <div>

            <Form onSubmit={handleSubmit}>
                <Grid item>
                    <Field
                        name="token"
                        component="input"
                        type="hidden"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={verify}
                                onChange={handleChange}
                                name="checkedB"
                                color="secondary"
                            />
                        }
                        label="Confirmed Verifier"
                    />
                </Grid>
            </Form>
            <AlertDialog isOpen={isOpen} {...dialogProps} handleClose={onHandleClose}/>
        </div>
    )
};

VerifierForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
    form: VERIFIER_FORM
})(VerifierForm);