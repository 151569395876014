import Content from '../components/CredentialsList';
import {loadCredentials, selectCredential} from "../actions";
import {loadDashboard} from "../../dashboard/actions";
import {connect} from "react-redux";
import {mapCredentialsToProps} from "../selectors";
import { withRouter } from 'react-router-dom'

export const Container = connect(mapCredentialsToProps, {
    loadCredentials, selectCredential, loadDashboard
})(Content);

export default withRouter(Container);