import React from "react";
import PropTypes from "prop-types";
import InfoOutlined from '@material-ui/icons/InfoOutlined';

const ShowError = ({ error, touched }) =>
    touched && error ? (
        <div className="error">
            <InfoOutlined />
            {error}
        </div>
    ) : null;

ShowError.propTypes = {
    error: PropTypes.string,
    touched: PropTypes.bool
};

export default ShowError;
