import moment from "moment";

export function phoneFormatter(number) {
    if (!number) return '';
    // NNN-NNN-NNNN

    let onlyNums = number.replace(/[^\d]/g, '');
    onlyNums = onlyNums.substring(0, 10);
    const triads = onlyNums.substring(0, 7).match(/.{1,3}/g);
    return `${!!triads ? triads.join('-')  : ''}${onlyNums.substring(7)}`;
}

export function zipFormatter(number) {
    if (!number) return '';
    // NNNNN-NNNN
    let onlyNums = number.replace(/[^\d]/g, '');
    onlyNums = onlyNums.substring(0, 9);
    return `${onlyNums.substring(0, 5)}${onlyNums.length > 5 ? '-' : ''}${onlyNums.substring(5)}`;
}

const dateFormatter = (value) => {
    if(!value || value === '') return '';

    return moment(value).local().format('YYYY-MM-DD');
};

const expirationFormatter = (dateExpires) => {

    if(!dateExpires){
        return 'NA'
    }

    const expireMoment = moment(dateExpires);

    const expireDiff = moment().diff(expireMoment);

    return expireDiff < 0 ? expireMoment.format('l') : 'Expired'
};

export {dateFormatter, expirationFormatter};
