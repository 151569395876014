import React from 'react';
import PropTypes from 'prop-types/prop-types';
import {reduxForm, Field} from '../../utils/redux-form';
import * as formValidators from '../../utils/formValidation';
import {USER_COUPON_FORM} from '../constants';
import Button from '../../components/StandardButton';
import {Link, Redirect} from 'react-router-dom';
import Select from "../../components/SelectControl";

export class UserCouponForm extends React.Component {
    render() {
        const {handleSubmit, pristine, invalid, submitting, isEmpty, couponList} = this.props;

        if (isEmpty || submitting) {
            return <Redirect to={'/app/userAdmin'}/>
        }

        return (
            <form onSubmit={handleSubmit}>
                <Field
                    name="token"
                    component="input"
                    type="hidden"
                />
                <Field
                    name="_id"
                    component="input"
                    type="hidden"
                />
                <Field
                    name="couponId"
                    component={Select}
                    type="text"
                    label="Coupon"
                    options={couponList}
                />
                <Button
                    to={'../userAdmin'}
                    component={Link}
                    variant={'outlined'}
                    color={'default'}
                    type="button">
                    Cancel
                </Button>
                <Button
                    disabled={pristine || invalid}
                    type="submit">
                    Update Coupon
                </Button>
            </form>
        )
    }
}

UserCouponForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    couponList: PropTypes.array.isRequired,
    isEmpty: PropTypes.bool.isRequired
};


export default reduxForm({
    form: USER_COUPON_FORM,
    enableReinitialize: true,
    validate: formValidators.createValidator({
        couponId: formValidators.required,
    },)
})(UserCouponForm);