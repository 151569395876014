import React from 'react';
import {Redirect} from 'react-router-dom'
import { reduxForm, Field, Form } from '../../utils/redux-form';
import Button from '../../components/Button';
import * as formValidators from '../../utils/formValidation';
import ValidatorPassword from './ValidatorPassword';
import { SIGN_UP_FORM } from '../constants';
import ServiceTerms from "../../components/ServiceTerms";
import Email from "./Email";
import {checkEmail} from '../actions';
import lowercaseParser from '../lowercaseParser';
import {DEFAULT_HOME} from "../../config";

export const SignUpForm = props => {
    const {handleSubmit, isDisabled, isFormVisible, auth, clearEmail} = props;

    if(auth) {
        const { from } = { from: { pathname: DEFAULT_HOME } };
        return ( <Redirect to={from} />)
    }

    if (isFormVisible) {
        return (
            <Form onSubmit={handleSubmit}>
                <Field
                    name="email"
                    component={Email}
                    type="text"
                    label="Email Address"
                    placeholder={'Email Address'}
                    parse={lowercaseParser}
                    onClearClick={clearEmail}
                />
                <Field
                    name="password"
                    component={ValidatorPassword}
                    label="New Password"
                />
                <Button
                    color='primary'
                    disabled={isDisabled}
                    type="submit">
                    Sign Up
                </Button>
                <ServiceTerms />
            </Form>
        )
    }
    else {
        return (<div>is done... check your email</div>)
    }
};

export default reduxForm({
    form: SIGN_UP_FORM ,
    validate: formValidators.createValidator({
        password: [ formValidators.required, formValidators.minLengthAndStrength(8, 3)],
        email: [formValidators.required, formValidators.email]
    }),
    asyncValidate: checkEmail,
    asyncChangeFields: ['email']
})(SignUpForm);