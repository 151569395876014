import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import UnauthorizedLayout from './authorize/UnauthorizedLayout';
import {DEFAULT_HOME} from "./config";
import Profile from './profile';
import Members from './members';
import QRCodes from './qrCodes';
import Account from './account';
import Organization from './organization';
import Credentials from './credentials';
import Dashboard from './dashboard';
import FourOhFour from './four_oh_four';
import {Verify} from './qr_coded';
import AuthorizedRoute from './authorize/AuthorizedRoute';
import Forbidden from './forbidden';
import UserAdmin from './userAdmin';
import ProviderAdmin from './providers';
import Reports from './reports';


export default () => {
    return (
        <Router>
            <div>
                <Switch>
                    <Route path={'/app/forbidden'} component={Forbidden} />>
                    <AuthorizedRoute path={'/app/reports'} component={Reports}/>
                    <AuthorizedRoute path={'/app/members'} component={Members}/>
                    <AuthorizedRoute path={'/app/qrCodes'} component={QRCodes}/>
                    <AuthorizedRoute path={'/app/account'} component={Account}/>
                    <AuthorizedRoute path={'/app/dashboard'} component={Dashboard}/>
                    <AuthorizedRoute path={'/app/organization'} component={Organization}/>
                    <AuthorizedRoute path={'/app/credentials'} component={Credentials}/>
                    <AuthorizedRoute path={'/app/profile'} component={Profile}/>
                    <AuthorizedRoute path={'/app/userAdmin'} component={UserAdmin}/>
                    <AuthorizedRoute path={'/app/providers'} component={ProviderAdmin}/>
                    <AuthorizedRoute path={`/verify/:id`} component={Verify}/>
                    <AuthorizedRoute path={'/app'} component={FourOhFour}/>
                    <Route path="/auth" component={UnauthorizedLayout}/>
                    <Route path='/:id' component={UnauthorizedLayout}/>
                    <Redirect to={DEFAULT_HOME} /> {/*catchall for / (root)*/}
                </Switch>
            </div>
        </Router>
    )
}