import {createSelector} from 'reselect';
import {NAME} from './constants';

export const getFetchingState = state => state[NAME];

export default createSelector(
    getFetchingState,
    (isFetching) => ({
        isFetching
    })
);