import {Route, Switch} from 'react-router-dom';
import React from "react";
import UserContentContainer from '../containers/UserContentContainer';
import UserAdminContent from '../components/UserAdminContent';


export default () => {
    return (
        <Switch>
            <Route path={'/app/userAdmin/:userId'} component={UserContentContainer} />
            <Route path={'/'} component={UserAdminContent} />
        </Switch>
    )
}