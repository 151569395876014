import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import StatusIcon from "../../components/StatusIcon";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import {PROVIDER_STATUS_TEXT, getColor, STATUS_ICON} from "../../common/provider-styles";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import {DeleteIcon} from "../../common/icons";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import STATUS_TEXT from './statusText'

export default (props) => {
    const {_id, name, dateStatused, status, credentials, deleteProvider, verifier, handleSelect} = props;

    const statusDate = new Date(dateStatused);

    const isDeletable = credentials === 0;

    const assignedCredentialsText = credentials > 0 ? `${credentials} assigned credential${credentials > 1 ? 's' : ''}` : '';

    // const STATUS_TEXT = {
    //     sent: 'Invitation sent',
    //     confirmed: 'Verifier confirmed',
    //     canceled: 'Canceled'
    // }

    return (
        <ListItem button
                  // component={Link}
                  // to={`/app/providers/${_id}`}
            onClick={(e) => handleSelect(e, {_id})}
        >
            <ListItemIcon>
                <span style={{color: getColor(status)}}>
                    <Tooltip title={PROVIDER_STATUS_TEXT[status]}>
                        <span>
                            <StatusIcon icon={STATUS_ICON[status]}/>
                        </span>
                    </Tooltip>
                </span>
            </ListItemIcon>
            <ListItemText disableTypography
                          primary={
                              <Grid container spacing={2}>
                                  <Grid item>
                                      <Typography variant={"body1"}>
                                          {name}
                                      </Typography>
                                  </Grid>
                                  <Grid item>
                                      <Typography variant={"body2"} color={"textSecondary"}>
                                          {`Last Updated: ${statusDate.toDateString()}`}
                                      </Typography>
                                  </Grid>
                                  {!!assignedCredentialsText && <Grid item>
                                      <Typography variant={"body2"} color={"textSecondary"}>
                                          {assignedCredentialsText}
                                      </Typography>
                                  </Grid>}
                                  {!!verifier && <Grid item>
                                      <Typography variant={"body2"} color={"textSecondary"}>
                                          {`Verifier: ${verifier.email} - ${STATUS_TEXT[verifier.status]}`}
                                      </Typography>
                                  </Grid>}
                              </Grid>
                          }
            />
            {isDeletable && <ListItemSecondaryAction>
                <Tooltip title="Delete this provider" aria-label="Delete provider">
                    <IconButton edge="end" onClick={(e) => deleteProvider(e, {_id, name})}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>}
        </ListItem>
    )
}