import {isNil} from 'ramda'
export const loadState = () => {
    try{
        const serializedState = localStorage.getItem('state');
        if(isNil(serializedState)) return {};

        return JSON.parse(serializedState)
    } catch (err) {
        //ignore the error.
        return {};
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (err) {
        //ignore the error
    }
};