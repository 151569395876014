import { withRouter } from 'react-router-dom'
import {connect} from 'react-redux';
import {postAuthenticate} from '../actions';
import SignInForm from '../components/SignInForm';
import { mapSignInToProps } from '../selectors';
// import {DEFAULT_HOME} from "../../config";

// const mergeProps = function(state, {postAuthenticate}, {history, location}) {
//     // const { from } = location.state || { from: { pathname: DEFAULT_HOME } };
//     return {
//         ...state,
//         onSubmit: (f) => postAuthenticate(f)
//             // .then(() => {
//             //     return history.push(from)
//             // })
//     }
// };

// export const SignInFormContainer = connect(mapSignInToProps, { postAuthenticate }, mergeProps)(SignInForm);
export const SignInFormContainer = connect(mapSignInToProps, { onSubmit: postAuthenticate })(SignInForm);

export default withRouter(SignInFormContainer);