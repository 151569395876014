import {PROVIDERS} from "./actionTypes";
import {states} from "../common/validation-styles";
import db from "../utils/dbAccess";
import {handleError} from "../common/actions";

const {error, success} = states;

export const addProvider = (payload) => (dispatch) => {
    const addProviderSuccess = (addrs) => ({
        type: PROVIDERS.POST.SUCCESS,
        payload: {msgState: success, message: 'Provider successfully added.', provider: addrs}
    });

    const addProviderFailure = (message) => ({
        type: PROVIDERS.POST.FAILURE,
        payload: {msgState: error, message}
    });

    const addProviderRequest = () => ({
        type: PROVIDERS.POST.REQUEST
    });

    dispatch(addProviderRequest());

    return db.addProvider(payload)
        .then(({data:{payload}}) => dispatch(addProviderSuccess(payload)))
        .catch((e) => dispatch(handleError(e, addProviderFailure)))
};

export const loadProviders = ({token}) => (dispatch) => {

    const loadProvidersRequest = () => ({
        type: PROVIDERS.GET.REQUEST
    });

    const loadProvidersFailure = (message) => ({
        type: PROVIDERS.GET.FAILURE,
        payload: {msgState: states.error, message}
    });

    const loadProvidersSuccess = (payload) => ({
        type: PROVIDERS.GET.SUCCESS,
        payload
    });

    dispatch(loadProvidersRequest());

    return db.getProviders({token})
        .then(({data:{payload}}) => dispatch(loadProvidersSuccess(payload)))
        .catch((e) => dispatch(handleError(e, loadProvidersFailure)))
};

export const deleteProvider = (payload) => (dispatch) => {
    const deleteProviderSuccess = (addrs) => ({
        type: PROVIDERS.DELETE.SUCCESS,
        payload: {msgState: success, message: 'Provider successfully deleted.', ...addrs}
    });

    const deleteProviderFailure = (message) => ({
        type: PROVIDERS.DELETE.FAILURE,
        payload: {msgState: error, message}
    });

    const deleteProviderRequest = () => ({
        type: PROVIDERS.DELETE.REQUEST
    });
    
    dispatch(deleteProviderRequest());

    return db.deleteProvider(payload)
        .then(({data:{payload}}) => dispatch(deleteProviderSuccess(payload)))
        .catch((e) => dispatch(handleError(e, deleteProviderFailure)))
};

export const selectProvider = (payload) => ({
    type: PROVIDERS.SELECT,
    payload
});

export const updateProvider = (addrs) => (dispatch) => {
    const updateProviderSuccess = (provider) => ({
        type: PROVIDERS.PATCH.SUCCESS,
        payload: {msgState: success, message: 'Provider successfully updated.', provider}
    });

    const updateProviderFailure = (message) => ({
        type: PROVIDERS.PATCH.FAILURE,
        payload: {msgState: error, message}
    });

    const updateProviderRequest = () => ({
        type: PROVIDERS.PATCH.REQUEST
    });

    dispatch(updateProviderRequest());

    return db.updateProvider(addrs)
        .then(({data:{payload}}) => dispatch(updateProviderSuccess(payload)))
        .catch((e) => dispatch(handleError(e, updateProviderFailure)))
};

export const deDupeProvider = (addrs) => (dispatch) => {
    const deDupeProviderSuccess = (addrs) => ({
        type: PROVIDERS.DE_DUPLICATE.SUCCESS,
        payload: {msgState: success, message: 'Provider successfully de-duplicated.', ...addrs}
    });

    const deDupeProviderFailure = (message) => ({
        type: PROVIDERS.DE_DUPLICATE.FAILURE,
        payload: {msgState: error, message}
    });

    const deDupeProviderRequest = () => ({
        type: PROVIDERS.DE_DUPLICATE.REQUEST
    });

    dispatch(deDupeProviderRequest());

    return db.deDupeProvider(addrs)
        .then(({data:{payload}}) => dispatch(deDupeProviderSuccess(payload)))
        .catch((e) => dispatch(handleError(e, deDupeProviderFailure)))
};

export const resendInvite = (opts) => (dispatch) => {
    const resendInviteSuccess = () => ({
        type: PROVIDERS.RESEND_INVITE.SUCCESS,
        payload: {msgState: success, message: 'Verifier invitation successfully resent.'}
    });

    const resendInviteFailure = (message) => ({
        type: PROVIDERS.RESEND_INVITE.FAILURE,
        payload: {msgState: error, message}
    });

    const resendInviteRequest = () => ({
        type: PROVIDERS.RESEND_INVITE.REQUEST
    });

    dispatch(resendInviteRequest());

    return db.resendVerifierInvite(opts)
        .then(() => dispatch(resendInviteSuccess()))
        .catch((e) => dispatch(handleError(e, resendInviteFailure)))
}

